import { AxiosResponse } from 'axios';
import produce from 'immer';
import { get } from 'lodash';
import { normalizeErrorMessage } from '@utils/error';
import { HTTPStatusCode, MessageCodes, StatusCodes } from '@constants/enum/common';
import {
  DATE_DRIVEN_METAFIELD_MISSING_MESSAGE_TITLE,
  OPENAI_KEY_MISSING_MESSAGE_TITLE,
  PipelineDesiredStatusCodes,
  PipelineOutputType,
  PipelineRuntimeIssues,
  PIPELINES_LIST_DEFAULT_SORTING_KEY_ALL_TAB,
  PipelineStatusCodes,
  PipelineValidationYamlErrors,
  RUNTIME_ISSUES_DETECTION,
  VALIDATION_ERRORS,
} from '@constants/pipelines';
import { EMPTY_YAML_TEMPLATE } from '@constants/pipelineTemplates';
import { IPrototypeData } from '@constants/prototype';
import {
  ACTIVATE_PIPELINE,
  CHECK_PIPELINE_LOGS_HEALTH,
  CREATE_PIPELINE,
  DeepsetCloudVersion,
  DELETE_MULTIPLE_PIPELINES,
  DELETE_PIPELINE,
  DELETE_PROTOTYPE_LINK,
  DEPLOY_PIPELINE,
  DUPLICATE_PIPELINE_V1,
  DUPLICATE_PIPELINE_V2,
  EditorYamlTabsKeys,
  EXPORT_PIPELINE_LOGS_CSV,
  FETCH_PIPELINE,
  FETCH_PIPELINE_INDEXING,
  FETCH_PIPELINE_YAML,
  FETCH_PIPELINES,
  FULFILLED,
  GENERATE_PROTOTYPE_LINK,
  GET_PIPELINE_LOGS,
  GET_PIPELINE_PROTOTYPE,
  IAPIPaginationData,
  IMessage,
  IPipeline,
  IPipelineLog,
  IPipelineStatistics,
  IPipelineStatisticsAnswers,
  IYamlPipelineJsonFormat,
  PENDING,
  PipelineLatestQueries,
  PipelineServiceLevel,
  REJECTED,
  RESET_ACTIVE_TAB_PIPELINE_DETAILS_PAGE,
  RESET_ACTIVE_TAB_YAML_EDITOR,
  RESET_EDITED_CODE,
  RESET_FETCH_PIPELINE_YAML_STATUS,
  RESET_NEW_PIPELINE_NAME,
  RESET_PIPELINE,
  RESET_PIPELINE_DETAILS_STATE,
  RESET_PIPELINE_ERRORS,
  RESET_PIPELINE_LATEST_QUERIES,
  RESET_PIPELINE_MESSAGE,
  RESET_PIPELINE_YAML,
  RESET_PROTOTYPE_LINK,
  RESET_RENAME_PIPELINE_NAME_STATUS,
  RESET_VALIDATE_NEW_PIPELINE_NAME,
  RUNTIME_ISSUE_DETECTION,
  SELECT_PIPELINES_LIST_SORT_VALUE,
  SET_ACTIVE_TAB_PIPELINE_DETAILS_PAGE,
  SET_ACTIVE_TAB_PIPELINES_LANDING_PAGE,
  SET_ACTIVE_TAB_YAML_EDITOR,
  SET_DEEPSET_CLOUD_VERSION,
  SET_INDEXING_CODE,
  SET_INDEXING_PIPELINE_EDITED_STATE,
  SET_INDEXING_YAML_EDITOR_DIFF,
  SET_NEW_PIPELINE_NAME,
  SET_PIPELINE_EDITED_STATE,
  SET_PIPELINE_INDEXING_YAML,
  SET_PIPELINE_LATEST_QUERIES,
  SET_PIPELINE_QUERY_YAML,
  SET_PIPELINE_STATISTICS,
  SET_PIPELINE_YAML_V1,
  SET_PIPELINE_YAML_V2,
  SET_QUERY_CODE,
  SET_QUERY_PIPELINE_EDITED_STATE,
  SET_QUERY_YAML_EDITOR_DIFF,
  UNDEPLOY_PIPELINE,
  UPDATE_PIPELINE,
  UPDATE_PIPELINE_YAML,
  VALIDATE_PIPELINE_YAML,
} from '@redux/types/types';

interface IInitialStateProps {
  pipelines: IAPIPaginationData<IPipeline[]>;
  deployedPipelines: IAPIPaginationData<IPipeline[]>;
  draftPipelines: IAPIPaginationData<IPipeline[]>;
  status: StatusCodes;
  actionStatus: StatusCodes;
  createUpdatePipelineStatus: StatusCodes;
  loadingSharedPrototypeStatus: StatusCodes;
  generateSharedPrototypeStatus: StatusCodes;
  deleteSharedPrototypeStatus: StatusCodes;
  renamePipelineNameStatus: StatusCodes;
  loadingLatestQueriesStatus: StatusCodes;
  fetchPipelineYamlStatus: StatusCodes;
  fetchPipelinesListStatus: StatusCodes;
  // TODO: Stanadrize into Status Codes
  fetchDeployedInProgress: boolean;
  fetchDraftInProgress: boolean;
  pipeline: IPipeline;
  newPipelineName: string;
  message: IMessage;
  pipelineStatistics: IPipelineStatistics;
  pipelineLatestQueries: PipelineLatestQueries;
  pipelineLogs: IAPIPaginationData<IPipelineLog[]>;
  pipelineStatisticsAnswers: IPipelineStatisticsAnswers;
  pipelineJson: IYamlPipelineJsonFormat;
  currentSharedPrototype: IPrototypeData | null;
  pipelineLogsStatus: StatusCodes;
  pipelineLogsUnhealthy: Record<string, boolean>;
  activeTabPipelineDetailsPage: string;
  activeTabPipelinesLandingPage: PipelineServiceLevel | '';
  pipelineErrors: IMessage[];
  sortValuePipelinesList: string;
  activeTabYamlEditor: EditorYamlTabsKeys;
  indexingCode: string;
  queryCode: string;
  indexingYamlEditorDiff: boolean;
  queryYamlEditorDiff: boolean;
}

export const initialState: IInitialStateProps = {
  pipelines: {
    data: [],
    has_more: false,
    total: 0,
  },
  deployedPipelines: {
    data: [],
    has_more: false,
    total: 0,
  },
  draftPipelines: {
    data: [],
    has_more: false,
    total: 0,
  },
  status: StatusCodes.IDLE,
  actionStatus: StatusCodes.IDLE,
  createUpdatePipelineStatus: StatusCodes.IDLE,
  loadingSharedPrototypeStatus: StatusCodes.IDLE,
  generateSharedPrototypeStatus: StatusCodes.IDLE,
  deleteSharedPrototypeStatus: StatusCodes.IDLE,
  renamePipelineNameStatus: StatusCodes.IDLE,
  loadingLatestQueriesStatus: StatusCodes.IDLE,
  fetchPipelineYamlStatus: StatusCodes.IDLE,
  // TODO: Stanadrize into Status Codes
  fetchPipelinesListStatus: StatusCodes.IDLE,
  fetchDeployedInProgress: false,
  fetchDraftInProgress: false,
  pipeline: {
    pipeline_id: '',
    created_at: '',
    created_by: {
      family_name: '',
      given_name: '',
      user_id: '',
    },
    name: '',
    edited: false,
    editedIndexingYaml: false,
    editedQueryYaml: false,
    status: PipelineStatusCodes.UNDEPLOYED,
    desired_status: PipelineDesiredStatusCodes.UNDEPLOYED,
    idle_timeout_in_seconds: 12,
    supports_prompt: false,
    output_type: PipelineOutputType.EXTRACTIVE,
    service_level: PipelineServiceLevel.DRAFT,
    deepset_cloud_version: DeepsetCloudVersion.V2,
    last_edited_by: {},
    yaml: EMPTY_YAML_TEMPLATE,
    indexing_yaml: '',
    query_yaml: '',
    indexing: {
      pending_file_count: 0,
      failed_file_count: 0,
    },
    indexing_details: {
      failed_file_count: 0,
      indexed_file_count: 0,
      indexed_no_documents_file_count: 0,
      pending_file_count: 0,
      status: '',
      total_file_count: 0,
    },
  },
  newPipelineName: '',
  message: {
    type: MessageCodes.INFO,
    content: '',
  },
  pipelineStatistics: {
    total_queries: 0,
    manual_feedback_input: 0,
    experiments_created: 0,
    users_provided_feedback: 0,
    avg_feedback_per_query: 0,
    indexed_files: 0,
    total_documents: 0,
  },
  pipelineLatestQueries: {
    data: [],
    has_more: false,
    total: 0,
  },
  pipelineLogs: {
    data: [],
    has_more: false,
    total: 0,
  },
  pipelineStatisticsAnswers: {
    correct_answers: 0,
    wrong_answers: 0,
    wrong_answers_with_correct_document: 0,
    accuracy_of_answers: 0,
    latency: 0,
  },
  pipelineJson: {
    version: '',
    name: '',
  },
  currentSharedPrototype: null,
  pipelineLogsStatus: StatusCodes.IDLE,
  pipelineLogsUnhealthy: {},
  activeTabPipelineDetailsPage: 'overview',
  activeTabPipelinesLandingPage: '',
  pipelineErrors: [],
  sortValuePipelinesList: PIPELINES_LIST_DEFAULT_SORTING_KEY_ALL_TAB,
  activeTabYamlEditor: EditorYamlTabsKeys.INDEXING_YAML,
  indexingCode: '',
  queryCode: '',
  indexingYamlEditorDiff: false,
  queryYamlEditorDiff: false,
};

// TODO: Add separation for 'messages' or check using notification center - https://github.com/deepset-ai/haystack-hub-ui/issues/2442
const pipelineReducer = (state = initialState, action: any) => {
  return produce(state, (draft) => {
    const localDraft = draft;

    // ERROR HANDLING
    // Instead of handling this way we could use extraReducer if we had slices
    // Details: https://redux-toolkit.js.org/api/createSlice#the-extrareducers-builder-callback-notation
    if (action.type.includes(REJECTED)) {
      localDraft.status = StatusCodes.ERROR;
      localDraft.actionStatus = StatusCodes.ERROR;
      localDraft.message = {
        type: MessageCodes.ERROR,
        content: normalizeErrorMessage(action.payload),
      };
    }

    switch (action.type) {
      case RESET_PIPELINE:
        localDraft.pipeline = initialState.pipeline;
        localDraft.createUpdatePipelineStatus = initialState.createUpdatePipelineStatus;
        break;
      case RESET_PIPELINE_YAML:
        localDraft.pipeline.yaml = initialState.pipeline.yaml;
        localDraft.pipeline.indexing_yaml = initialState.pipeline.indexing_yaml;
        localDraft.pipeline.query_yaml = initialState.pipeline.query_yaml;
        localDraft.message = initialState.message;
        break;
      case SET_PIPELINE_YAML_V1:
        localDraft.pipeline.yaml = action.payload;
        localDraft.message = initialState.message;
        break;
      case SET_PIPELINE_YAML_V2:
        localDraft.pipeline.indexing_yaml = action.payload.indexingYaml;
        localDraft.pipeline.query_yaml = action.payload.queryYaml;
        localDraft.message = initialState.message;
        break;
      case SET_PIPELINE_INDEXING_YAML:
        localDraft.pipeline.indexing_yaml = action.payload;
        localDraft.message = initialState.message;
        break;
      case SET_PIPELINE_QUERY_YAML:
        localDraft.pipeline.query_yaml = action.payload;
        localDraft.message = initialState.message;
        break;
      case SET_NEW_PIPELINE_NAME:
        localDraft.pipeline.name = action.payload;
        break;
      case SET_PIPELINE_EDITED_STATE:
        localDraft.pipeline.edited = action.payload;
        break;
      case SET_INDEXING_PIPELINE_EDITED_STATE:
        localDraft.pipeline.editedIndexingYaml = action.payload;
        break;
      case SET_QUERY_PIPELINE_EDITED_STATE:
        localDraft.pipeline.editedQueryYaml = action.payload;
        break;
      case RESET_PIPELINE_MESSAGE:
        localDraft.status = initialState.status;
        localDraft.actionStatus = initialState.actionStatus;
        localDraft.createUpdatePipelineStatus = initialState.createUpdatePipelineStatus;
        localDraft.message = initialState.message;
        localDraft.fetchDraftInProgress = initialState.fetchDraftInProgress;
        localDraft.fetchDeployedInProgress = initialState.fetchDeployedInProgress;
        localDraft.fetchPipelinesListStatus = initialState.fetchPipelinesListStatus;
        break;
      case RESET_NEW_PIPELINE_NAME:
        localDraft.newPipelineName = initialState.newPipelineName;
        break;
      case RESET_PIPELINE_DETAILS_STATE:
        localDraft.pipelineStatistics = initialState.pipelineStatistics;
        localDraft.pipelineStatisticsAnswers = initialState.pipelineStatisticsAnswers;
        localDraft.pipelineLogsStatus = initialState.pipelineLogsStatus;
        localDraft.pipeline = {
          ...initialState.pipeline,
          deepset_cloud_version: localDraft.pipeline.deepset_cloud_version,
          yaml: localDraft.pipeline.yaml,
          indexing_yaml: localDraft.pipeline.indexing_yaml,
          query_yaml: localDraft.pipeline.query_yaml,
        };
        break;
      case RESET_PIPELINE_LATEST_QUERIES:
        localDraft.pipelineLatestQueries = initialState.pipelineLatestQueries;
        break;
      case RESET_PROTOTYPE_LINK:
        localDraft.currentSharedPrototype = initialState.currentSharedPrototype;
        break;
      case SET_ACTIVE_TAB_PIPELINE_DETAILS_PAGE:
        localDraft.activeTabPipelineDetailsPage = action.payload;
        break;
      case RESET_ACTIVE_TAB_PIPELINE_DETAILS_PAGE:
        localDraft.activeTabPipelineDetailsPage = initialState.activeTabPipelineDetailsPage;
        break;
      case SET_ACTIVE_TAB_PIPELINES_LANDING_PAGE:
        localDraft.activeTabPipelinesLandingPage = action.payload;
        break;
      case SET_ACTIVE_TAB_YAML_EDITOR:
        localDraft.activeTabYamlEditor = action.payload;
        break;
      case RESET_ACTIVE_TAB_YAML_EDITOR:
        localDraft.activeTabYamlEditor = initialState.activeTabYamlEditor;
        break;
      case SET_INDEXING_CODE:
        localDraft.indexingCode = action.payload;
        break;
      case SET_QUERY_CODE:
        localDraft.queryCode = action.payload;
        break;
      case SET_DEEPSET_CLOUD_VERSION:
        localDraft.pipeline.deepset_cloud_version = action.payload;
        break;
      case RESET_EDITED_CODE:
        localDraft.indexingCode = initialState.indexingCode;
        localDraft.queryCode = initialState.queryCode;
        localDraft.indexingYamlEditorDiff = initialState.indexingYamlEditorDiff;
        localDraft.queryYamlEditorDiff = initialState.queryYamlEditorDiff;
        break;
      case SET_INDEXING_YAML_EDITOR_DIFF:
        localDraft.indexingYamlEditorDiff = action.payload;
        break;
      case SET_QUERY_YAML_EDITOR_DIFF:
        localDraft.queryYamlEditorDiff = action.payload;
        break;
      case RESET_PIPELINE_ERRORS:
        localDraft.pipelineErrors = initialState.pipelineErrors;
        break;
      case RESET_FETCH_PIPELINE_YAML_STATUS:
        localDraft.fetchPipelineYamlStatus = StatusCodes.IDLE;
        break;
      case SELECT_PIPELINES_LIST_SORT_VALUE:
        localDraft.sortValuePipelinesList = action.payload;
        break;
      // ASYNC ACTIONS
      case `${FETCH_PIPELINE}/${FULFILLED}`:
        localDraft.pipeline = {
          ...action.payload,
          yaml: localDraft.pipeline.yaml,
          indexing_yaml: localDraft.pipeline.indexing_yaml,
          query_yaml: localDraft.pipeline.query_yaml,
          edited: localDraft.pipeline.edited,
          editedIndexingYaml: localDraft.pipeline.editedIndexingYaml,
          editedQueryYaml: localDraft.pipeline.editedQueryYaml,
          indexing_details: localDraft.pipeline.indexing_details,
        };
        localDraft.message = initialState.message;
        break;
      case `${FETCH_PIPELINE_YAML}/${FULFILLED}`:
        localDraft.pipeline = {
          ...localDraft.pipeline,
          yaml: action.payload.yaml,
          indexing_yaml: action.payload.indexing_yaml,
          query_yaml: action.payload.query_yaml,
          edited: false,
          editedIndexingYaml: false,
          editedQueryYaml: false,
        };
        localDraft.message = initialState.message;
        localDraft.fetchPipelineYamlStatus = StatusCodes.SUCCESS;
        break;
      case `${FETCH_PIPELINE_INDEXING}/${FULFILLED}`:
        localDraft.pipeline = {
          ...localDraft.pipeline,
          indexing_details: action.payload,
        };
        localDraft.message = initialState.message;
        break;
      case `${FETCH_PIPELINES}/${PENDING}`: {
        const args = action.meta.arg;
        const { desiredStatus } = args;
        if (!desiredStatus) localDraft.fetchPipelinesListStatus = StatusCodes.IN_PROGRESS;
        if (desiredStatus === PipelineDesiredStatusCodes.DEPLOYED) {
          localDraft.fetchDeployedInProgress = true;
        }
        if (desiredStatus === PipelineDesiredStatusCodes.UNDEPLOYED) {
          localDraft.fetchDraftInProgress = true;
        }
        break;
      }
      case `${FETCH_PIPELINES}/${FULFILLED}`: {
        const args = action.meta.arg;
        const { desiredStatus } = args;
        if (action.payload === false) break;
        if (!desiredStatus) {
          localDraft.fetchPipelinesListStatus = StatusCodes.SUCCESS;
          localDraft.pipelines = action.payload;
          break;
        }
        if (desiredStatus === PipelineDesiredStatusCodes.DEPLOYED) {
          localDraft.fetchDeployedInProgress = false;
          localDraft.deployedPipelines = action.payload;
          break;
        }
        if (desiredStatus === PipelineDesiredStatusCodes.UNDEPLOYED) {
          localDraft.fetchDraftInProgress = false;
          localDraft.draftPipelines = action.payload;
          break;
        }
        localDraft.pipelines = action.payload;
        break;
      }
      case `${FETCH_PIPELINES}/${REJECTED}`: {
        const args = action.meta.arg;
        const { desiredStatus } = args;
        if (!desiredStatus) localDraft.fetchPipelinesListStatus = StatusCodes.ERROR;
        if (desiredStatus === PipelineDesiredStatusCodes.DEPLOYED) {
          localDraft.fetchDeployedInProgress = false;
        }
        if (desiredStatus === PipelineDesiredStatusCodes.UNDEPLOYED) {
          localDraft.fetchDraftInProgress = false;
        }
        break;
      }
      case `${DUPLICATE_PIPELINE_V1}/${FULFILLED}`:
        localDraft.pipeline = {
          ...initialState.pipeline,
          service_level: PipelineServiceLevel.DRAFT,
          deepset_cloud_version: DeepsetCloudVersion.V1,
          yaml: action.payload,
          edited: true,
        };
        localDraft.message = initialState.message;
        break;
      case `${DUPLICATE_PIPELINE_V2}/${FULFILLED}`:
        localDraft.pipeline = {
          ...initialState.pipeline,
          service_level: PipelineServiceLevel.DRAFT,
          deepset_cloud_version: DeepsetCloudVersion.V2,
          indexing_yaml: action.payload.indexingYaml,
          query_yaml: action.payload.queryYaml,
          edited: true,
        };
        localDraft.message = initialState.message;
        break;
      case `${CREATE_PIPELINE}/${PENDING}`:
      case `${UPDATE_PIPELINE_YAML}/${PENDING}`:
        localDraft.createUpdatePipelineStatus = StatusCodes.IN_PROGRESS;
        break;
      case `${CREATE_PIPELINE}/${REJECTED}`:
      case `${UPDATE_PIPELINE_YAML}/${REJECTED}`: {
        const { response } = action.payload;
        if (response && response.status === HTTPStatusCode.CONFLICT)
          localDraft.message = {
            type: MessageCodes.ERROR,
            content: VALIDATION_ERRORS.PIPELINE_NAME_ALREADY_USED,
          };
        localDraft.createUpdatePipelineStatus = StatusCodes.ERROR;
        break;
      }
      case `${CREATE_PIPELINE}/${FULFILLED}`: {
        localDraft.newPipelineName = action.payload.name;
        localDraft.createUpdatePipelineStatus = StatusCodes.SUCCESS;
        const { code } = action.meta.arg;
        localDraft.pipeline = {
          ...localDraft.pipeline,
          ...action.payload,
          yaml: code,
          status: PipelineStatusCodes.UNDEPLOYED,
          edited: false,
        };
        break;
      }
      case `${UPDATE_PIPELINE_YAML}/${FULFILLED}`: {
        localDraft.createUpdatePipelineStatus = StatusCodes.SUCCESS;
        const { code } = action.meta.arg;
        localDraft.pipeline = {
          ...localDraft.pipeline,
          ...action.payload,
          yaml: code,
          edited: false,
        };
        break;
      }
      case `${UPDATE_PIPELINE}/${FULFILLED}`:
        localDraft.actionStatus = StatusCodes.SUCCESS;
        localDraft.status = StatusCodes.SUCCESS;
        if (action.meta.arg.newPipelineName) {
          localDraft.pipeline = {
            ...localDraft.pipeline,
            name: action.meta.arg.newPipelineName,
          };
          localDraft.renamePipelineNameStatus = StatusCodes.SUCCESS;
        }
        break;
      case `${UPDATE_PIPELINE}/${PENDING}`:
        localDraft.actionStatus = StatusCodes.IN_PROGRESS;
        localDraft.status = StatusCodes.IN_PROGRESS;
        if (action.meta.arg.newPipelineName)
          localDraft.renamePipelineNameStatus = StatusCodes.IN_PROGRESS;
        break;
      case `${UPDATE_PIPELINE}/${REJECTED}`: {
        localDraft.actionStatus = StatusCodes.ERROR;
        const { response } = action.payload as Record<string, AxiosResponse | null>;
        if (action.meta.arg.newPipelineName)
          localDraft.renamePipelineNameStatus = StatusCodes.ERROR;

        const { status } = response || {};

        if (status === HTTPStatusCode.CONFLICT) {
          localDraft.message = {
            type: MessageCodes.ERROR,
            content: VALIDATION_ERRORS.PIPELINE_NAME_ALREADY_USED,
          };
          break;
        }
        break;
      }
      case RESET_RENAME_PIPELINE_NAME_STATUS:
        localDraft.message = initialState.message;
        localDraft.renamePipelineNameStatus = StatusCodes.IDLE;
        break;
      case `${ACTIVATE_PIPELINE}/${PENDING}`:
      case `${DELETE_PIPELINE}/${PENDING}`:
      case `${DELETE_MULTIPLE_PIPELINES}/${PENDING}`:
        localDraft.actionStatus = StatusCodes.IN_PROGRESS;
        break;
      case `${ACTIVATE_PIPELINE}/${FULFILLED}`:
      case `${DELETE_PIPELINE}/${FULFILLED}`:
        localDraft.actionStatus = StatusCodes.SUCCESS;
        break;
      case `${RUNTIME_ISSUE_DETECTION}/${FULFILLED}`:
        if (!action.payload || action.payload.length === 0) break;

        action.payload?.forEach((error: { message: string; title: string }) => {
          if (
            error.title === PipelineRuntimeIssues.INVALID_OPENAI_TOKEN_ERROR &&
            !localDraft.pipelineErrors.find(
              (e) => e.title === PipelineRuntimeIssues.INVALID_OPENAI_TOKEN_ERROR,
            )
          )
            localDraft.pipelineErrors.push({
              type: MessageCodes.ERROR,
              content: RUNTIME_ISSUES_DETECTION.INVALID_OPENAI_TOKEN_ERROR,
              title: OPENAI_KEY_MISSING_MESSAGE_TITLE,
            });
          else if (
            !localDraft.pipelineErrors.find((e) => e.title === error.title) &&
            error.title !== PipelineRuntimeIssues.INVALID_OPENAI_TOKEN_ERROR
          )
            localDraft.pipelineErrors.push({
              type: MessageCodes.ERROR,
              content: error.message,
              title: error.title,
            });
        });
        break;
      case `${VALIDATE_PIPELINE_YAML}/${REJECTED}`: {
        localDraft.pipelineErrors = [];
        if (!action.payload || action.payload.length === 0) break;
        const { response } = action.payload;
        if (!response) break;

        const {
          PIPELINE_MISSING_OPENAI_TOKEN_ERROR,
          PIPELINE_RECENTNESS_RANKER_MISSING_METADATA_ERROR,
        } = PipelineValidationYamlErrors;

        const addErrorIfNotExists = (title: string, content: string) => {
          const errorExists = localDraft.pipelineErrors.some((e: any) => e.title === title);
          if (!errorExists) {
            return [
              ...localDraft.pipelineErrors,
              {
                type: MessageCodes.ERROR,
                content,
                title,
              },
            ];
          }
          return localDraft.pipelineErrors;
        };

        response.data?.details?.forEach((error: { code: string; message: string }) => {
          switch (error.code) {
            case PIPELINE_MISSING_OPENAI_TOKEN_ERROR:
              localDraft.pipelineErrors = addErrorIfNotExists(
                OPENAI_KEY_MISSING_MESSAGE_TITLE,
                VALIDATION_ERRORS.OPENAI_KEY_MISSING,
              );
              break;
            case PIPELINE_RECENTNESS_RANKER_MISSING_METADATA_ERROR:
              localDraft.pipelineErrors = addErrorIfNotExists(
                DATE_DRIVEN_METAFIELD_MISSING_MESSAGE_TITLE,
                VALIDATION_ERRORS.DATE_DRIVEN_FIELD_MISSING,
              );
              break;
            default:
              localDraft.pipelineErrors = addErrorIfNotExists(error.code, error.message);
              break;
          }
        });
        break;
      }
      case `${DELETE_MULTIPLE_PIPELINES}/${FULFILLED}`:
        localDraft.actionStatus = StatusCodes.SUCCESS;
        break;
      case `${DEPLOY_PIPELINE}/${PENDING}`: {
        localDraft.actionStatus = StatusCodes.IN_PROGRESS;
        break;
      }
      case `${DEPLOY_PIPELINE}/${REJECTED}`: {
        localDraft.actionStatus = StatusCodes.ERROR;
        localDraft.pipeline.status = PipelineStatusCodes.DEPLOYMENT_FAILED;
        break;
      }
      case `${UNDEPLOY_PIPELINE}/${PENDING}`: {
        localDraft.actionStatus = StatusCodes.IN_PROGRESS;
        break;
      }
      case `${DEPLOY_PIPELINE}/${FULFILLED}`:
        localDraft.actionStatus = StatusCodes.SUCCESS;
        localDraft.pipeline.status =
          (action.payload && action.payload.status) || PipelineStatusCodes.DEPLOYED;
        break;
      case `${UNDEPLOY_PIPELINE}/${FULFILLED}`:
        localDraft.actionStatus = StatusCodes.SUCCESS;
        localDraft.pipeline.status =
          (action.payload && action.payload.status) || PipelineStatusCodes.UNDEPLOYED;
        break;
      case RESET_VALIDATE_NEW_PIPELINE_NAME:
        localDraft.message = initialState.message;
        break;
      case `${SET_PIPELINE_STATISTICS}/${FULFILLED}`:
        if (!action.payload || Object.keys(action.payload).length === 0) {
          localDraft.pipelineStatistics = initialState.pipelineStatistics;
          localDraft.pipelineStatisticsAnswers = initialState.pipelineStatisticsAnswers;
        } else
          Object.keys(action.payload).forEach((stat) => {
            if (stat in localDraft.pipelineStatistics) {
              localDraft.pipelineStatistics[stat as keyof IPipelineStatistics] =
                action.payload[stat];
            } else if (stat in localDraft.pipelineStatisticsAnswers) {
              localDraft.pipelineStatisticsAnswers[stat as keyof IPipelineStatisticsAnswers] =
                action.payload[stat];
            }
          });
        break;
      case `${SET_PIPELINE_STATISTICS}/${REJECTED}`:
        localDraft.pipelineStatistics = initialState.pipelineStatistics;
        localDraft.pipelineStatisticsAnswers = initialState.pipelineStatisticsAnswers;
        break;
      case `${SET_PIPELINE_LATEST_QUERIES}/${PENDING}`:
        localDraft.loadingLatestQueriesStatus = StatusCodes.IN_PROGRESS;
        break;
      case `${SET_PIPELINE_LATEST_QUERIES}/${FULFILLED}`: {
        localDraft.loadingLatestQueriesStatus = StatusCodes.SUCCESS;

        const latestQueries = get(action.payload, 'data[0].response[0]');

        if (!latestQueries) {
          localDraft.pipelineLatestQueries = initialState.pipelineLatestQueries;
          break;
        }

        localDraft.pipelineLatestQueries = action.payload;
        break;
      }
      case `${SET_PIPELINE_LATEST_QUERIES}/${REJECTED}`:
        localDraft.loadingLatestQueriesStatus = StatusCodes.ERROR;
        break;
      case `${GET_PIPELINE_LOGS}/${FULFILLED}`: {
        const { fetchMore } = action.meta.arg;

        if (fetchMore)
          localDraft.pipelineLogs = {
            ...action.payload,
            data: [...localDraft.pipelineLogs.data, ...action.payload.data],
          };
        else localDraft.pipelineLogs = action.payload;
        localDraft.pipelineLogsStatus = StatusCodes.SUCCESS;
        break;
      }
      case `${GET_PIPELINE_LOGS}/${PENDING}`:
        localDraft.pipelineLogsStatus = StatusCodes.IN_PROGRESS;
        break;
      case `${GET_PIPELINE_LOGS}/${REJECTED}`:
        localDraft.pipelineLogsStatus = StatusCodes.ERROR;
        break;
      case `${CHECK_PIPELINE_LOGS_HEALTH}/${FULFILLED}`:
        localDraft.pipelineLogsUnhealthy = {
          ...localDraft.pipelineLogsUnhealthy,
          [action.meta.arg.pipelineName]: action.payload.data.length > 0,
        };
        break;
      case `${EXPORT_PIPELINE_LOGS_CSV}/${REJECTED}`:
        localDraft.status = StatusCodes.ERROR;
        break;
      case `${GENERATE_PROTOTYPE_LINK}/${PENDING}`:
        localDraft.generateSharedPrototypeStatus = StatusCodes.IN_PROGRESS;
        break;
      case `${GET_PIPELINE_PROTOTYPE}/${PENDING}`:
        localDraft.loadingSharedPrototypeStatus = StatusCodes.IN_PROGRESS;
        break;
      case `${DELETE_PROTOTYPE_LINK}/${PENDING}`:
        localDraft.deleteSharedPrototypeStatus = StatusCodes.IN_PROGRESS;
        break;
      case `${GENERATE_PROTOTYPE_LINK}/${FULFILLED}`:
        localDraft.generateSharedPrototypeStatus = StatusCodes.SUCCESS;
        localDraft.currentSharedPrototype = action.payload;
        break;
      case `${GET_PIPELINE_PROTOTYPE}/${FULFILLED}`:
        localDraft.loadingSharedPrototypeStatus = StatusCodes.SUCCESS;
        localDraft.currentSharedPrototype = action.payload;
        break;
      case `${DELETE_PROTOTYPE_LINK}/${FULFILLED}`:
        localDraft.deleteSharedPrototypeStatus = StatusCodes.SUCCESS;
        localDraft.currentSharedPrototype = initialState.currentSharedPrototype;
        break;
      case `${GENERATE_PROTOTYPE_LINK}/${REJECTED}`:
        localDraft.generateSharedPrototypeStatus = StatusCodes.ERROR;
        break;
      case `${GET_PIPELINE_PROTOTYPE}/${REJECTED}`:
        localDraft.loadingSharedPrototypeStatus = StatusCodes.ERROR;
        break;
      default:
        break;
    }

    return localDraft;
  });
};

export default pipelineReducer;
