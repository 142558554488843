import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LatestRequests from '@components/dashboard/LatestRequests';
import NumberOfRequests from '@components/dashboard/NumberOfRequests';
import WorkspaceStatistic from '@components/dashboard/WorkspaceStatistic';
import useFreeLimits from '@modules/Upgrade/hooks/useFreeLimits';
import {
  getRequestHistory,
  getSearchCount,
  getWorkSpaceStatistic,
} from '@src/redux/actions/dashboardActions';
import {
  dashboardLoadingRequestHistorySelector,
  dashboardRequestHistorySelector,
  dashboardSearchCountSelector,
  dashboardWorkspaceStatsSelector,
} from '@src/redux/selectors/dashboardSelectors';
import UsageLimits from './components/usageLimits/UsageLimits';
import styles from './dashboardPage.module.scss';

// TODO: Error handling, loading handling, empty stat handling. - https://github.com/deepset-ai/haystack-hub-ui/issues/2439

const DashboardPage = () => {
  const dispatch = useDispatch();
  const { isLimitedUser } = useFreeLimits();
  const searchCount = useSelector(dashboardSearchCountSelector);
  const workspaceStats = useSelector(dashboardWorkspaceStatsSelector);
  const requestHistory = useSelector(dashboardRequestHistorySelector);
  const loadingRequestHistory = useSelector(dashboardLoadingRequestHistorySelector);
  const requestsToday = searchCount.length && searchCount[searchCount.length - 1].count;

  useEffect(() => {
    dispatch(getSearchCount());
    dispatch(getWorkSpaceStatistic());
    dispatch(getRequestHistory());
  }, []);

  return (
    <div className={styles.container} data-testid="dashboardPage">
      {isLimitedUser && (
        <div className={styles.item}>
          <UsageLimits />
        </div>
      )}
      <div className={styles.item}>
        <WorkspaceStatistic statistic={workspaceStats} requestsToday={requestsToday} />
      </div>
      <div className={styles.item}>
        <NumberOfRequests data={searchCount} />
      </div>
      <div className={styles.item}>
        <LatestRequests
          data={requestHistory}
          searchRequestCount={workspaceStats.searchRequestCount}
          loading={loadingRequestHistory}
        />
      </div>
    </div>
  );
};

export default DashboardPage;
