import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LayoutOutlined } from '@ant-design/icons';
import { Button, Tag } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { interpolateString } from '@utils/string';
import { useUserEvent } from '@hooks/useUserEvent';
import { COMMON_FORM_ERRORS, LEGACY_LABEL } from '@constants/common';
import {
  EventControlComponent,
  EventControlPipelineTemplateElement,
  UserEventType,
} from '@constants/event';
import {
  CATEGORY_ALL_TEMPLATES,
  CATEGORY_LABELS,
  CLEAR_ALL_FILTERS_BUTTON_LABEL,
  CREATE_EMPTY_FILE_BUTTON_LABEL,
  DEEPSET_CLOUD_VERSION_TITLES_AND_DESCRIPTIONS,
  EMPTY_FILTERED_PIPELINE_TEMPLATES,
  EMPTY_SEARCHED_PIPELINE_TEMPLATES,
  EMPTY_TEMPLATES_LIST_LABEL,
  SEE_ALL_LABEL,
} from '@constants/pipeline-templates';
import {
  DEFINE_PIPELINE_MODAL_TITLE,
  NAME_NEW_PIPELINE_MODAL_OK_BUTTON,
  NAME_NEW_PIPELINE_MODAL_TITLE,
} from '@constants/pipelines';
import {
  EMPTY_YAML_TEMPLATE,
  INDEXING_PIPELINE_EMPTY_YAML_TEMPLATE,
  QUERY_PIPELINE_EMPTY_YAML_TEMPLATE,
} from '@constants/pipelineTemplates';
import {
  createPipeline,
  resetMessage as resetPipelineMessage,
  setActiveTabPipelinesList,
} from '@redux/actions/pipelineActions';
import {
  getPipelineTemplatesByCategory,
  resetSelectTemplatesFilters,
  selectTemplatesCategory,
  setSelectedPipelineTemplate,
} from '@redux/actions/pipelineTemplatesActions';
import {
  pipelineCreateUpdatePipelineStatusSelector,
  pipelineMessageSelector,
} from '@redux/selectors/pipelineSelectors';
import {
  activeVersionTabTemplatesLandingPageSelector,
  pipelineTemplatesByCategorySelector,
  pipelineTemplatesSearchValueSelector,
  pipelineTemplatesSortValueSelector,
  selectedCategorySelector,
  selectedPipelineTemplateFiltersSelector,
} from '@redux/selectors/pipelineTemplatesSelectors';
import {
  DeepsetCloudVersion,
  IPipelineTemplate,
  PipelineTemplatesCategory,
} from '@redux/types/types';
import ContentMessage from '@components/contentMessage/ContentMessage';
import NamePipelineModal from '@components/namePipelineModal/NamePipelineModal';
import TemplatesCard from './TemplateCard';
import styles from './templatesList.module.scss';

const FETCH_LIMIT_FOR_ALL_GROUPED_TEMPLATES = 3;

const TemplatesList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { trackUserEvent, setEventProperties } = useUserEvent();
  const activeVersionTabTemplatesLandingPage = useSelector(
    activeVersionTabTemplatesLandingPageSelector,
  );
  const templatesByCategory = useSelector(pipelineTemplatesByCategorySelector);
  const selectedCategory = useSelector(selectedCategorySelector);
  const pipelineCreateUpdatePipelineStatus = useSelector(
    pipelineCreateUpdatePipelineStatusSelector,
  );
  const pipelineMessage = useSelector(pipelineMessageSelector);
  const pipelineTemplatesSearchValue = useSelector(pipelineTemplatesSearchValueSelector);
  const selectedPipelineTemplateFilters = useSelector(selectedPipelineTemplateFiltersSelector);
  const pipelineTemplatesSortValue = useSelector(pipelineTemplatesSortValueSelector);
  const [showRenamePipelineModal, setShowRenamePipelineModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<IPipelineTemplate | null>(null);

  const fetchAllGroupedTemplates = () => {
    const sharedParams = {
      limit: FETCH_LIMIT_FOR_ALL_GROUPED_TEMPLATES,
      searchValue: pipelineTemplatesSearchValue,
      filterValues: selectedPipelineTemplateFilters,
      sortValue: pipelineTemplatesSortValue,
    };

    dispatch(
      getPipelineTemplatesByCategory({
        ...sharedParams,
        category: PipelineTemplatesCategory.RECOMMENDED,
      }),
    );
    dispatch(
      getPipelineTemplatesByCategory({
        ...sharedParams,
        category: PipelineTemplatesCategory.DOCUMENT_SEARCH,
      }),
    );
    dispatch(
      getPipelineTemplatesByCategory({
        ...sharedParams,
        category: PipelineTemplatesCategory.BASIC_QA,
      }),
    );
    dispatch(
      getPipelineTemplatesByCategory({
        ...sharedParams,
        category: PipelineTemplatesCategory.ADVANCED_QA,
      }),
    );
    dispatch(
      getPipelineTemplatesByCategory({
        ...sharedParams,
        category: PipelineTemplatesCategory.CONVERSATIONAL,
      }),
    );
    dispatch(
      getPipelineTemplatesByCategory({
        ...sharedParams,
        category: PipelineTemplatesCategory.TEXT_ANALYSIS,
      }),
    );
    dispatch(
      getPipelineTemplatesByCategory({
        ...sharedParams,
        category: PipelineTemplatesCategory.VISUAL_QA,
      }),
    );
    dispatch(
      getPipelineTemplatesByCategory({
        ...sharedParams,
        category: PipelineTemplatesCategory.TEXT_TO_SQL,
      }),
    );
  };

  useEffect(() => {
    setEventProperties({ pipeline_template_session_id: uuidv4() });
  }, []);

  useEffect(() => {
    if (!selectedCategory) {
      fetchAllGroupedTemplates();
      return;
    }

    dispatch(
      getPipelineTemplatesByCategory({
        category: selectedCategory,
        searchValue: pipelineTemplatesSearchValue,
        filterValues: selectedPipelineTemplateFilters,
        sortValue: pipelineTemplatesSortValue,
      }),
    );
  }, [
    selectedCategory,
    pipelineTemplatesSearchValue,
    selectedPipelineTemplateFilters,
    pipelineTemplatesSortValue,
    activeVersionTabTemplatesLandingPage,
  ]);

  const thereAreActiveFilters = () =>
    !!Object.values(selectedPipelineTemplateFilters || {}).flat().length ||
    !!pipelineTemplatesSearchValue;

  const emptyCategoriesData = () => {
    if (selectedCategory) return !templatesByCategory[selectedCategory].total;
    return !Object.values(templatesByCategory).some((categoryData) => !!categoryData.total);
  };

  const getTemplatesByCategory = () => {
    if (!selectedCategory) return [];
    const { data: templates } = templatesByCategory[selectedCategory];
    return templates;
  };

  const getCategoryTitle = () => {
    if (!selectedCategory) return CATEGORY_ALL_TEMPLATES;
    return CATEGORY_LABELS[selectedCategory];
  };

  const onCategorySeeAllClick = (category: PipelineTemplatesCategory) => {
    dispatch(selectTemplatesCategory(category));
  };

  const sendMetrics = (pipelineName: string) => {
    trackUserEvent({
      type: UserEventType.CLICK,
      control: `${EventControlComponent.PIPELINE_TEMPLATE}/${EventControlPipelineTemplateElement.SELECT_PIPELINE_TEMPLATE}`,
      properties: {
        template: pipelineName,
      },
    });
  };

  const onViewDetails = (template: IPipelineTemplate) => {
    dispatch(setSelectedPipelineTemplate(template));
    navigate(`/pipelines/templates/${template.pipeline_name}`);
    sendMetrics(template.pipeline_name);
  };

  const onUseTemplate = (template: IPipelineTemplate) => {
    setShowRenamePipelineModal(true);
    setSelectedTemplate(template);

    sendMetrics(template.pipeline_name);
  };

  const onCreatePipeline = ({
    pipelineName,
    deepsetCloudVersion,
    indexingYaml,
    queryYaml,
  }: {
    pipelineName: string;
    deepsetCloudVersion?: DeepsetCloudVersion;
    indexingYaml?: string;
    queryYaml?: string;
  }) => {
    const { pipeline_yaml: pipelineYAML } = selectedTemplate || {};
    let payload = {};

    if (deepsetCloudVersion) {
      if (deepsetCloudVersion === DeepsetCloudVersion.V1)
        payload = {
          code: pipelineYAML || EMPTY_YAML_TEMPLATE,
          pipelineName,
          deepsetCloudVersion,
        };
      else if (deepsetCloudVersion === DeepsetCloudVersion.V2)
        payload = {
          pipelineName,
          deepsetCloudVersion,
          // TODO: Add values once the templates are ready
          indexingYaml: indexingYaml || INDEXING_PIPELINE_EMPTY_YAML_TEMPLATE,
          queryYaml: queryYaml || QUERY_PIPELINE_EMPTY_YAML_TEMPLATE,
        };
    } else if (!deepsetCloudVersion && selectedTemplate?.deepset_cloud_version) {
      if (selectedTemplate?.deepset_cloud_version === DeepsetCloudVersion.V1)
        payload = {
          code: pipelineYAML || EMPTY_YAML_TEMPLATE,
          pipelineName,
          deepsetCloudVersion: DeepsetCloudVersion.V1,
        };
      else if (selectedTemplate?.deepset_cloud_version === DeepsetCloudVersion.V2)
        payload = {
          pipelineName,
          deepsetCloudVersion: DeepsetCloudVersion.V2,
          // TODO: Add values once the templates are ready
          indexingYaml: selectedTemplate?.indexing_yaml || INDEXING_PIPELINE_EMPTY_YAML_TEMPLATE,
          queryYaml: selectedTemplate?.query_yaml || QUERY_PIPELINE_EMPTY_YAML_TEMPLATE,
        };
    } else
      payload = {
        code: pipelineYAML || EMPTY_YAML_TEMPLATE,
        pipelineName,
      };

    dispatch(createPipeline(payload));
  };

  const onCreateEmptyPipeline = () => {
    setShowRenamePipelineModal(true);
  };

  const onAfterPipelineCreated = (name: string) => {
    if (selectedTemplate) {
      navigate(`/pipelines/${name}/builder`);
      return;
    }

    dispatch(setActiveTabPipelinesList(''));
    navigate(`/pipelines/${name}/builder`);
  };

  const onClearAllFilters = () => {
    dispatch(resetSelectTemplatesFilters);
  };

  // Renders

  const renderNoFilteredData = () => {
    let contentMessage = {
      title: EMPTY_FILTERED_PIPELINE_TEMPLATES.TITLE,
      description: interpolateString(EMPTY_FILTERED_PIPELINE_TEMPLATES.DESCRIPTION, {
        clearFiltersLink: (
          <Button className={styles.linkButton} type="link" onClick={onClearAllFilters}>
            {CLEAR_ALL_FILTERS_BUTTON_LABEL}
          </Button>
        ),
      }),
    };

    if (pipelineTemplatesSearchValue) {
      contentMessage = {
        ...contentMessage,
        title: EMPTY_SEARCHED_PIPELINE_TEMPLATES.TITLE,
        description: EMPTY_SEARCHED_PIPELINE_TEMPLATES.DESCRIPTION,
      };
    }

    return (
      <ContentMessage
        icon={<LayoutOutlined />}
        title={contentMessage.title}
        description={contentMessage.description}
      />
    );
  };

  const renderAllGroupedTemplates = () => {
    const availableCategories = Object.keys(templatesByCategory) as PipelineTemplatesCategory[];

    return availableCategories.map((category) => {
      const { data: templates } = templatesByCategory[category];

      if (!templates?.length) return null;

      const slicedTemplates = templates.slice(0, FETCH_LIMIT_FOR_ALL_GROUPED_TEMPLATES);

      return (
        <div className={styles.categoryRow} key={category}>
          <div className={styles.categoryRow_header}>
            <span className={styles.categoryRow_header_category}>{CATEGORY_LABELS[category]}</span>
            <Button type="link" size="small" onClick={() => onCategorySeeAllClick(category)}>
              {SEE_ALL_LABEL}
            </Button>
          </div>
          <div className={styles.groupedList}>
            {slicedTemplates.map((template) => (
              <TemplatesCard
                key={template.pipeline_template_id}
                template={template}
                onViewDetails={() => onViewDetails(template)}
                onUseTemplate={() => onUseTemplate(template)}
              />
            ))}
          </div>
        </div>
      );
    });
  };

  const renderCategoryTemplates = () => {
    return getTemplatesByCategory().length === 0 ? (
      <div className={styles.emptyList}>
        {interpolateString(EMPTY_TEMPLATES_LIST_LABEL, {
          categoryName: <span className={styles.emptyList_categoryName}>{getCategoryTitle()}</span>,
        })}
      </div>
    ) : (
      <div className={styles.list}>
        {getTemplatesByCategory().map((template) => (
          <TemplatesCard
            key={template.pipeline_template_id}
            template={template}
            onViewDetails={() => onViewDetails(template)}
            onUseTemplate={() => onUseTemplate(template)}
          />
        ))}
      </div>
    );
  };

  return (
    <>
      <NamePipelineModal
        openModal={showRenamePipelineModal}
        closeModal={() => {
          setShowRenamePipelineModal(false);
          setSelectedTemplate(null);
        }}
        description={!selectedTemplate ? COMMON_FORM_ERRORS.INVALID_CHARACTERS : ''}
        defaultPipelineNameValue={selectedTemplate?.pipeline_name}
        modalTitle={!selectedTemplate ? DEFINE_PIPELINE_MODAL_TITLE : NAME_NEW_PIPELINE_MODAL_TITLE}
        okButtonText={NAME_NEW_PIPELINE_MODAL_OK_BUTTON}
        createPipeline={onCreatePipeline}
        onAfterPipelineCreated={onAfterPipelineCreated}
        pipelineCreateUpdatePipelineStatus={pipelineCreateUpdatePipelineStatus}
        pipelineMessage={pipelineMessage}
        resetMessage={() => dispatch(resetPipelineMessage)}
        showDeepsetCloudVersionOptions={!selectedTemplate}
      />
      <div className={styles.container}>
        {emptyCategoriesData() && thereAreActiveFilters() ? (
          renderNoFilteredData()
        ) : (
          <>
            <div className={styles.header}>
              <div className={styles.header_textContainer}>
                <div className={styles.header_textContainer_title}>
                  <h4>
                    {
                      DEEPSET_CLOUD_VERSION_TITLES_AND_DESCRIPTIONS[
                        activeVersionTabTemplatesLandingPage
                      ].title
                    }
                  </h4>
                  {activeVersionTabTemplatesLandingPage === DeepsetCloudVersion.V1 && (
                    <Tag bordered={false}>{LEGACY_LABEL}</Tag>
                  )}
                </div>
                <div>
                  {
                    DEEPSET_CLOUD_VERSION_TITLES_AND_DESCRIPTIONS[
                      activeVersionTabTemplatesLandingPage
                    ].description
                  }
                </div>
              </div>
              <Button size="small" onClick={onCreateEmptyPipeline}>
                {CREATE_EMPTY_FILE_BUTTON_LABEL}
              </Button>
            </div>
            <h5 className={styles.header_categoryTitle}>{getCategoryTitle()}</h5>

            {!selectedCategory ? renderAllGroupedTemplates() : renderCategoryTemplates()}
          </>
        )}
      </div>
    </>
  );
};

export default TemplatesList;
