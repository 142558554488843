import { createContext } from 'react';
import { Attributes, IdentifyOptions } from 'userflow.js';
import { IUserflowState } from '../state/userflowState';

export interface IUserflowContext extends IUserflowState {
  identify: (userId: string, attributes?: Attributes, opts?: IdentifyOptions) => void;
  init: () => Promise<void>;
}

const stub = (): never => {
  console.error('You forgot to wrap your component in <UserflowProvider>.');
  return {} as never;
};

export const initialContext = {
  isInitialized: false,
  identify: stub,
  init: stub,
};

const UserflowContext = createContext<IUserflowContext>(initialContext);

export default UserflowContext;
