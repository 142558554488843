import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@redux/store';

export const dashboardStoreSelector = (state: RootState) => state.dashboardStore;

const getWorkspaceStats = (state: RootState) => state.dashboardStore.workspaceStats;
const getSearchCount = (state: RootState) => state.dashboardStore.searchCount;
const getRequestHistory = (state: RootState) => state.dashboardStore.requestHistory;
const getLoadingRequestHistory = (state: RootState) => state.dashboardStore.loadingRequestHistory;

export const dashboardWorkspaceStatsSelector = createSelector(
  [getWorkspaceStats],
  (workspaceStats) => workspaceStats,
);

export const dashboardSearchCountSelector = createSelector(
  [getSearchCount],
  (searchCount) => searchCount,
);

export const dashboardRequestHistorySelector = createSelector(
  [getRequestHistory],
  (requestHistory) => requestHistory,
);

export const dashboardLoadingRequestHistorySelector = createSelector(
  [getLoadingRequestHistory],
  (loadingRequestHistory) => loadingRequestHistory,
);
