import { NUMBER_OF_USERS_PER_PAGE } from '@constants/common';
import { INamedEntity, IWorkspaceRoleItem } from '@redux/types/types';
import { http } from './base-http';

/* Get all users in the organization */
export interface IAllUsersData {
  limit?: number;
  after?: string;
  filter?: string;
  page_number?: number;
}

export const getAllUsersApi = (params?: IAllUsersData) => {
  const paramsMap = {
    limit: params?.limit || NUMBER_OF_USERS_PER_PAGE,
    ...(params?.after && { after: params?.after }),
    ...(params?.filter && { filter: params?.filter }),
    ...(params?.page_number && { page_number: params?.page_number }),
  };

  return http.get(`/api/v1/users`, { params: paramsMap });
};

/* Get details of current user */
export const getUserDetailsApi = () => {
  return http.get(`/api/v1/me`);
};

export const getUserApi = (userId: string) => {
  return http.get(`api/v1/users/${userId}`);
};

export const deleteUserApi = (userId: string) => {
  return http.delete(`api/v1/users/${userId}`);
};

export const assignUserRoleApi = (userId: string, payload: IWorkspaceRoleItem) => {
  return http.post(`api/v1/users/${userId}/assign`, {
    workspace_name: payload.name,
    role: payload.role,
  });
};

export const removeUserFromWorkspaceApi = (userId: string, payload: INamedEntity) => {
  return http.post(`api/v1/users/${userId}/unassign`, payload);
};
