import { createSelector } from '@reduxjs/toolkit';
import { IWorkspaceItem } from '@redux/reducers/organizationReducer';
import { RootState } from '@redux/store';

export const organizationStoreSelector = (state: RootState) => state.organizationStore;

const getOrganization = (state: RootState) => state.organizationStore.organization;
const getAllUsers = (state: RootState) => state.organizationStore.allUsers;
const getMessage = (state: RootState) => state.organizationStore.message;
const getConnections = (state: RootState) => state.organizationStore.connections;
const getWorkspaces = (state: RootState) => state.organizationStore.workspaces;
const getCurrentWorkspace = (state: RootState) => state.organizationStore.currentWorkspace;
const getAddWorkspaceStatus = (state: RootState) => state.organizationStore.addWorkspaceStatus;
const getDeletingWorkspaces = (state: RootState) =>
  state.organizationStore.currentDeletingWorkspaces;
const getDefaultIdleTimeout = (state: RootState) =>
  state.organizationStore.currentWorkspaceDefaultIdleTimeout;

export const organizationSelector = createSelector(
  [getOrganization],
  (organization) => organization,
);

export const organizationUsersSelector = createSelector([getAllUsers], (allUsers) => allUsers);

export const organizationMessageSelector = createSelector([getMessage], (message) => message);

export const organizationConnectionSelector = createSelector(
  [getConnections],
  (connections) => connections,
);

export const workspaceSelector = createSelector(
  [getWorkspaces, getCurrentWorkspace],
  (workspaces, currentWorkspace) => ({
    workspaces,
    currentWorkspace,
    currentWorkspaceId: workspaces.find(
      (workspace: IWorkspaceItem) => workspace.name === currentWorkspace,
    )?.workspace_id,
  }),
);

export const addWorkspaceStatusSelector = createSelector(
  [getAddWorkspaceStatus],
  (addWorkspaceStatus) => addWorkspaceStatus,
);

export const currentDeletingWorkspacesSelector = createSelector(
  [getDeletingWorkspaces],
  (currentDeletingWorkspaces) => currentDeletingWorkspaces,
);

export const currentWorkspaceDefaultIdleTimeoutSelector = createSelector(
  [getDefaultIdleTimeout],
  (currentWorkspaceDefaultIdleTimeout) => currentWorkspaceDefaultIdleTimeout,
);
