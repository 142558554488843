import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import usePipelineActions from '@hooks/usePipelineActions';
import { PipelineDesiredStatusCodes, PipelineStatusCodes } from '@constants/pipelines';
import {
  activatePipeline as activatePipelineAction,
  fetchPipelines,
  startPollingDeployedPipelines,
  stopPollingDeployedPipelines,
} from '@redux/actions/pipelineActions';
import { searchPipelineSelector } from '@redux/selectors/searchSelectors';

interface IUseActivatePipelineParams {
  isExternal?: boolean;
  selectedPipelineName?: string;
  allwaysPollSelectedPipeline?: boolean;
}

// TODO: Make it work with outside search page
const useActivatePipeline = ({
  isExternal,
  selectedPipelineName,
  allwaysPollSelectedPipeline,
}: IUseActivatePipelineParams = {}) => {
  const dispatch = useDispatch();
  const { pipeline, fetchPipeline, startPollingPipelineStatus, stopPollingPipelineStatus } =
    usePipelineActions();
  const searchPipeline = useSelector(searchPipelineSelector);

  const [selectedPipeline, setSelectedPipeline] = useState(selectedPipelineName || searchPipeline);
  const isDiferentPipelineSelected = selectedPipeline !== pipeline.name;
  const activatingPipeline = pipeline?.status === PipelineStatusCodes.ACTIVATING;

  useEffect(() => {
    if (searchPipeline) setSelectedPipeline(searchPipeline);
  }, [searchPipeline]);

  useEffect(() => {
    if (!selectedPipeline) return;
    fetchPipeline(selectedPipeline, isExternal);
  }, [selectedPipeline]);

  useEffect(() => {
    if (!selectedPipeline) return;

    if (allwaysPollSelectedPipeline) startPollingPipelineStatus(selectedPipeline, isExternal);

    if (activatingPipeline) {
      if (!allwaysPollSelectedPipeline) startPollingPipelineStatus(selectedPipeline, isExternal);

      // TODO: Remove once we change the logic in the playgroud
      // to use pipeline selector instead of all the pipelines
      if (!isExternal) {
        const payload = {
          currentPage: 1,
          pageSize: 100,
          searchValue: '',
          interval: 5000,
        };

        dispatch(
          startPollingDeployedPipelines(
            payload.currentPage,
            payload.pageSize,
            payload.searchValue,
            payload.interval,
          ),
        );
      }
    }

    if (!activatingPipeline) {
      if (!allwaysPollSelectedPipeline) stopPollingPipelineStatus();

      if (!isExternal) {
        dispatch(stopPollingDeployedPipelines());
      }
    }

    return () => {
      stopPollingPipelineStatus();

      if (!isExternal) {
        dispatch(stopPollingDeployedPipelines());
      }
    };
  }, [pipeline, activatingPipeline, selectedPipeline]);

  const activatePipeline = async (pipelineName: string) => {
    await dispatch(activatePipelineAction({ pipelineName, isExternal }));
    fetchPipeline(pipelineName, isExternal);

    if (!isExternal) {
      dispatch(
        fetchPipelines({
          currentPage: 1,
          pageSize: 100,
          searchValue: '',
          desiredStatus: PipelineDesiredStatusCodes.DEPLOYED,
        }),
      );
    }
  };

  return {
    activatingPipeline: activatingPipeline && !isDiferentPipelineSelected,
    activatePipeline,
  };
};

export default useActivatePipeline;
