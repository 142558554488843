import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from 'antd';
import { getPipelineIdleTimeoutLabel } from '@utils/pipelines';
import { interpolateString } from '@utils/string';
import SearchIdleStatusSVG from '@assets/search-idle-status.svg?react';
import SearchStarSVG from '@assets/SearchStarSVG';
import SearchSVG from '@assets/SearchSVG';
import UnexpectedErrorSVG from '@assets/unexpected-error.svg?react';
import { DONE_BUTTON_LABEL } from '@constants/common';
import { HTTPStatusCode, StatusCodes } from '@constants/enum/common';
import { PipelineStatusCodes } from '@constants/pipelines';
import {
  ACTIVATE_PIPELINE_BUTTON,
  ACTIVATING_PIPELINE_BUTTON,
  ACTIVATING_PIPELINE_DESCRIPTION,
  ACTIVATING_PIPELINE_TITLE,
  CREATE_PIPELINE_DOCUMENTATION_URL,
  NEW_PIPELINE_LINK,
  NO_ANSWER_DESCRIPTION,
  NO_ANSWER_LABEL,
  NO_DEPLOYED_PIPELINE_DESCRIPTION,
  NO_DEPLOYED_PIPELINE_LABEL,
  RUN_SEARCH_LABEL,
  SEARCHING_LABEL,
  STANDBY_STATUS_DESCRIPTION,
  STANDBY_STATUS_TITLE,
  UNDEPLOYED_PIPELINE_ERROR_DESCRIPTION,
  UNDEPLOYED_PIPELINE_ERROR_LABEL,
  UNDEPLOYED_PIPELINE_ERROR_QUESTION_LINK,
  UNDEPLOYED_PIPELINE_MODAL_DESCRIPTION,
  UNDEPLOYED_PIPELINE_MODAL_TITLE,
  UNEXPECTED_ERROR_DESCRIPTION,
  UNEXPECTED_ERROR_LABEL,
  UPGRADE_POPOVER_ACTIVATE_CONTENT,
  VIEW_DOCUMENTATION_LINK,
} from '@constants/search';
import { IMessage, IPipeline } from '@redux/types/types';
import AnimatedDeployingPipelineSVG from '@components/common/Images/AnimatedDeployingPipelineSVG';
import AnimatedSearchSVG from '@components/common/Images/AnimatedSearchSVG';
import UpgradePopover from '@modules/Upgrade/components/upgradePopover/UpgradePopover';
import { LimitCategory } from '@modules/Upgrade/consts/pricing';
import useFreeLimits from '@modules/Upgrade/hooks/useFreeLimits';
import useActivatePipeline from '@pages/search/hooks/useActivatePipeline';
import styles from './searchNoResultsContainer.module.scss';

interface ISearchNoResultsContainerProps {
  status: StatusCodes;
  query: string;
  selectedPipeline?: IPipeline;
  isExternal?: boolean;
  message?: IMessage;
}

const SearchNoResultsContainer = ({
  message,
  status,
  query,
  selectedPipeline,
  isExternal,
}: ISearchNoResultsContainerProps) => {
  const navigate = useNavigate();
  const { activatingPipeline, activatePipeline } = useActivatePipeline({
    isExternal,
    selectedPipelineName: selectedPipeline?.name,
    allwaysPollSelectedPipeline: true,
  });
  const { isLimitedUser, isLimitExceed } = useFreeLimits();
  const [isUndeployedModalVisible, setIsUndeployedModalVisibleTo] = useState(false);

  const pipelineIdleTimeout = getPipelineIdleTimeoutLabel(
    selectedPipeline?.idle_timeout_in_seconds || null,
  );

  const getNoResultsContentIdleStatusProps = () => {
    const defaultProps = {
      title: RUN_SEARCH_LABEL,
      image: (
        <div className={styles.noResults_svg}>
          <SearchSVG />
        </div>
      ),
      subtitle: null,
      footer: null,
    };

    if (!isExternal && !selectedPipeline) {
      return {
        ...defaultProps,
        title: NO_DEPLOYED_PIPELINE_LABEL,
        subtitle: (
          <span className={styles.noResults_subtitle}>
            {interpolateString(NO_DEPLOYED_PIPELINE_DESCRIPTION, {
              newPipelineLink: (
                <Button
                  key="NewPipelineLink"
                  type="link"
                  onClick={() => navigate('/pipelines/templates')}
                  className={styles.noResults_button}
                >
                  {NEW_PIPELINE_LINK}
                </Button>
              ),
              viewDocumentationLink: (
                <Button
                  key="viewDocumentationLink"
                  type="link"
                  target="_blank"
                  href={CREATE_PIPELINE_DOCUMENTATION_URL}
                  className={styles.noResults_button}
                >
                  {VIEW_DOCUMENTATION_LINK}
                </Button>
              ),
            })}
          </span>
        ),
        footer: null,
      };
    }

    if (selectedPipeline?.status === PipelineStatusCodes.IDLE && !activatingPipeline) {
      return {
        ...defaultProps,
        image: (
          <div className={styles.noResults_svg}>
            <SearchIdleStatusSVG />
          </div>
        ),
        title: STANDBY_STATUS_TITLE,
        subtitle: (
          <span className={styles.noResults_subtitle}>
            {interpolateString(STANDBY_STATUS_DESCRIPTION, {
              idleTimeout: pipelineIdleTimeout,
            })}
          </span>
        ),
        footer: (
          <UpgradePopover
            description={UPGRADE_POPOVER_ACTIVATE_CONTENT}
            hide={!isLimitedUser || isLimitExceed(LimitCategory.PIPELINE_HOURS)}
          >
            <Button
              key="activatePipelineButton"
              type="primary"
              data-testid="searchNoResults_activatePipelineButton"
              onClick={() => {
                if (isLimitedUser && isLimitExceed(LimitCategory.PIPELINE_HOURS)) return;
                activatePipeline(selectedPipeline.name);
              }}
            >
              {ACTIVATE_PIPELINE_BUTTON}
            </Button>
          </UpgradePopover>
        ),
      };
    }
    if (activatingPipeline || selectedPipeline?.status === PipelineStatusCodes.ACTIVATING) {
      return {
        ...defaultProps,
        image: (
          <div className={styles.noResults_svg}>
            <AnimatedDeployingPipelineSVG />
          </div>
        ),
        title: ACTIVATING_PIPELINE_TITLE,
        subtitle: (
          <span
            className={styles.noResults_subtitle}
            data-testid="searchNoResults_activatingPipeline_textContent"
          >
            {interpolateString(ACTIVATING_PIPELINE_DESCRIPTION, {
              whyIsThisLink: !isExternal ? (
                <Button
                  key="whyIsThisLink"
                  type="link"
                  onClick={() => setIsUndeployedModalVisibleTo(true)}
                  target="_blank"
                  className={styles.noResults_button}
                >
                  {UNDEPLOYED_PIPELINE_ERROR_QUESTION_LINK}
                </Button>
              ) : (
                ''
              ),
            })}
          </span>
        ),
        footer: (
          <Button key="activatingPipelineButton" type="primary" loading>
            {ACTIVATING_PIPELINE_BUTTON}
          </Button>
        ),
      };
    }

    return defaultProps;
  };

  const getNoResultsContentErrorStatusProps = () => {
    const defaultProps = {
      title: UNEXPECTED_ERROR_LABEL,
      image: (
        <div className={styles.noResults_svg}>
          <UnexpectedErrorSVG />
        </div>
      ),
      subtitle: <span className={styles.noResults_subtitle}>{UNEXPECTED_ERROR_DESCRIPTION}</span>,
      footer: null,
    };

    if (
      selectedPipeline?.status === PipelineStatusCodes.UNDEPLOYED ||
      selectedPipeline?.status === PipelineStatusCodes.DEPLOYMENT_SCHEDULED ||
      selectedPipeline?.status === PipelineStatusCodes.DEPLOYMENT_IN_PROGRESS ||
      message?.status === HTTPStatusCode.UNDEPLOYED_PIPELINE_ERROR_STATUS
    ) {
      return {
        title: UNDEPLOYED_PIPELINE_ERROR_LABEL,
        image: (
          <div className={styles.noResults_svg}>
            <AnimatedDeployingPipelineSVG />
          </div>
        ),
        subtitle: (
          <span className={styles.noResults_subtitle}>
            {interpolateString(UNDEPLOYED_PIPELINE_ERROR_DESCRIPTION, {
              whyIsThisLink: !isExternal ? (
                <Button
                  key="whyIsThisLink"
                  type="link"
                  onClick={() => setIsUndeployedModalVisibleTo(true)}
                  target="_blank"
                  className={styles.noResults_button}
                >
                  {UNDEPLOYED_PIPELINE_ERROR_QUESTION_LINK}
                </Button>
              ) : (
                ''
              ),
            })}
          </span>
        ),
        footer: null,
      };
    }

    return defaultProps;
  };

  const getNoResultsContentPropsByStatus = (statusCode: StatusCodes) => {
    const mappedPropsByStatusCode = {
      [StatusCodes.SUCCESS]: {
        title: `${NO_ANSWER_LABEL} '${query}'`,
        image: (
          <div className={styles.noResults_svg}>
            <SearchStarSVG />
          </div>
        ),
        subtitle: <span className={styles.noResults_subtitle}>{NO_ANSWER_DESCRIPTION}</span>,
        footer: null,
      },
      [StatusCodes.IN_PROGRESS]: {
        title: `${SEARCHING_LABEL} '${query}'`,
        image: (
          <div className={styles.noResults_svg}>
            <AnimatedSearchSVG />
          </div>
        ),
        subtitle: null,
        footer: null,
      },
      [StatusCodes.IDLE]: getNoResultsContentIdleStatusProps(),
      [StatusCodes.ERROR]: getNoResultsContentErrorStatusProps(),
    };

    return mappedPropsByStatusCode[statusCode];
  };

  const noResultsContentProps = getNoResultsContentPropsByStatus(status);
  if (!noResultsContentProps) return null;
  const { title, image, subtitle, footer } = noResultsContentProps;

  return (
    <>
      <Modal
        title={UNDEPLOYED_PIPELINE_MODAL_TITLE}
        open={isUndeployedModalVisible}
        centered
        onCancel={() => setIsUndeployedModalVisibleTo(false)}
        footer={
          <Button onClick={() => setIsUndeployedModalVisibleTo(false)} type="primary">
            {DONE_BUTTON_LABEL}
          </Button>
        }
      >
        {interpolateString(UNDEPLOYED_PIPELINE_MODAL_DESCRIPTION, {
          idleTimeout: pipelineIdleTimeout,
        })}
      </Modal>
      <div className={styles.noResults}>
        {image}
        <div className={styles.noResults_content}>
          <h4 className={styles.noResults_title}>{title}</h4>
          {subtitle}
        </div>
        {footer}
      </div>
    </>
  );
};

export default SearchNoResultsContainer;
