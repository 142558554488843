import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AutoComplete, Button, Flex, Modal, Pagination, Select, Typography } from 'antd';
import { interpolateString } from '@utils/string';
import { CANCEL_BUTTON_LABEL, DONE_BUTTON_LABEL, SAVE_BUTTON_LABEL } from '@constants/common';
import { StatusCodes, UserRoles, UserRolesMap } from '@constants/enum/common';
import {
  WORKSPACE_ACCESS_MODAL_AUTO_COMPLETE_PLACEHOLDER,
  WORKSPACE_ACCESS_MODAL_EXISTING_USERS,
  WORKSPACE_ACCESS_MODAL_TITLE,
} from '@constants/settings-page';
import { fetchUsers } from '@redux/actions/organizationActions';
import { assignUserWorkspaceRole, removeUserFromWorkspace } from '@redux/actions/userActions';
import { IWorkspaceItem } from '@redux/reducers/organizationReducer';
import { organizationUsersSelector } from '@redux/selectors/organizationSelectors';
import {
  assignUserToWorkspaceStatusSelector,
  removeUserFromWorkspaceStatusSelector,
} from '@redux/selectors/userSelectors';
import { IUserData } from '@redux/types/types';
import WorkspaceMember from './WorkspaceMember';
import styles from './manageWorkspaceAccessModal.module.scss';

interface IManageWorkspaceAccessProps {
  onClose: () => void;
  workspace: IWorkspaceItem;
}

const USERS_PER_PAGE = 5;

// TODO: Add proper API pagination and filtering
const ManageWorkspaceAccess: React.FC<IManageWorkspaceAccessProps> = ({ onClose, workspace }) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { users }: { users: IUserData[] } = useSelector(organizationUsersSelector);

  const assignUserToWorkspaceStatus = useSelector(assignUserToWorkspaceStatusSelector);
  const removeUserFromWorkspaceStatus = useSelector(removeUserFromWorkspaceStatusSelector);

  const [userToSetInWorkspace, setUserToSetInWorkspace] = useState<{
    user: IUserData;
    role: UserRoles;
  } | null>(null);
  const [userToRemoveFromWorkspace, setUserToRemoveFromWorkspace] = useState<string | null>(null);

  const [workspaceUsers, setWorkspaceUsers] = useState<IUserData[]>([]);
  const [nonWorkspaceUsers, setNonWorkspaceUsers] = useState<IUserData[]>([]);

  const isAssigningUser = assignUserToWorkspaceStatus === StatusCodes.IN_PROGRESS;
  const isRemovingUser = (userId: string) =>
    removeUserFromWorkspaceStatus[userId] === StatusCodes.IN_PROGRESS;

  const selectableOptions = useMemo(
    () => nonWorkspaceUsers.map((user) => ({ value: user.userID, label: user.fullName })),
    [nonWorkspaceUsers],
  );

  const getAllUsers = useCallback(() => {
    setCurrentPage(1);
    dispatch(fetchUsers({ limit: 99 }));
  }, [dispatch]);

  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    if (!users?.length) return;

    const nonAdmingUsers = users.filter((user) => user.role !== UserRoles.ADMIN);
    setWorkspaceUsers(
      nonAdmingUsers.filter((user) => user.workspaces.some((ws) => ws.name === workspace.name)),
    );
    setNonWorkspaceUsers(
      nonAdmingUsers.filter((user) => !user.workspaces.some((ws) => ws.name === workspace.name)),
    );
  }, [users, workspace]);

  useEffect(() => {
    if (assignUserToWorkspaceStatus === StatusCodes.SUCCESS) {
      getAllUsers();
      setUserToSetInWorkspace(null);
    }
  }, [assignUserToWorkspaceStatus]);

  useEffect(() => {
    if (!userToRemoveFromWorkspace) return;

    const userRemovedStatus = removeUserFromWorkspaceStatus[userToRemoveFromWorkspace];

    if (userRemovedStatus === StatusCodes.SUCCESS) {
      getAllUsers();
      setUserToRemoveFromWorkspace(null);
    }

    if (userRemovedStatus === StatusCodes.ERROR) {
      setUserToRemoveFromWorkspace(null);
    }
  }, [removeUserFromWorkspaceStatus]);

  const getUserWorkspaceRole = (user: IUserData) => {
    return user.workspaces.find((ws) => ws.name === workspace.name)?.role;
  };

  const resetModal = () => {
    setCurrentPage(1);
    setWorkspaceUsers([]);
    setNonWorkspaceUsers([]);
    setUserToSetInWorkspace(null);
    setUserToRemoveFromWorkspace(null);
  };

  const autoComplete = useCallback(
    (text: string) =>
      selectableOptions.filter((option: { label: string }) =>
        option.label.toLowerCase().includes(text.toLowerCase()),
      ),
    [selectableOptions],
  );

  const currentPageData = useMemo(() => {
    const startIndex = (currentPage - 1) * USERS_PER_PAGE;
    const endIndex = startIndex + USERS_PER_PAGE;
    const data = workspaceUsers.slice(startIndex, endIndex);

    if (data.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }

    return data;
  }, [currentPage, workspaceUsers]);

  const onUserSelected = useCallback(
    (userId: string) => {
      const user = nonWorkspaceUsers.find((u) => u.userID === userId);
      if (!user) return;
      setUserToSetInWorkspace({ user, role: UserRoles.EDITOR });
    },
    [nonWorkspaceUsers],
  );

  const onUserSelectedRoleChange = (role: UserRoles) => {
    if (!userToSetInWorkspace) return;
    setUserToSetInWorkspace({ ...userToSetInWorkspace, role });
  };

  const onAssignUserToWorkspace = ({ user, role }: { user: IUserData; role: UserRoles }) => {
    dispatch(
      assignUserWorkspaceRole({
        userId: user.userID,
        workspaceName: workspace.name,
        role,
      }),
    );
  };

  const onRoleChange = ({ user, role: newRole }: { user: IUserData; role: UserRoles }) => {
    if (getUserWorkspaceRole(user) === newRole) return;
    onAssignUserToWorkspace({ user, role: newRole });
  };

  const onRemove = (user: IUserData) => {
    setUserToRemoveFromWorkspace(user.userID);
    dispatch(removeUserFromWorkspace({ userId: user.userID, workspaceName: workspace.name }));
  };

  // Renders

  const renderAddUserView = () => {
    const userRolesEntries = Object.entries(UserRolesMap).filter(
      ([key]) => key !== UserRoles.ADMIN,
    );
    const userRolesOptions = userRolesEntries.map(([key, value]) => ({ label: value, value: key }));

    return (
      <Flex gap="small" className={styles.section}>
        <AutoComplete
          className={styles.searchInput}
          options={selectableOptions}
          onSelect={onUserSelected}
          onSearch={autoComplete}
          value={userToSetInWorkspace?.user?.fullName}
          placeholder={WORKSPACE_ACCESS_MODAL_AUTO_COMPLETE_PLACEHOLDER}
        />
        <Select
          className={styles.select}
          options={userRolesOptions}
          defaultValue={UserRolesMap[UserRoles.EDITOR]}
          onChange={(role: string) => onUserSelectedRoleChange(role as UserRoles)}
        />
      </Flex>
    );
  };

  const renderListView = () => {
    return (
      <>
        <AutoComplete
          className={styles.searchInput}
          options={selectableOptions}
          onSelect={onUserSelected}
          onSearch={autoComplete}
          placeholder={WORKSPACE_ACCESS_MODAL_AUTO_COMPLETE_PLACEHOLDER}
        />
        {!!workspaceUsers?.length && (
          <Flex gap="middle" vertical className={styles.section} flex={1}>
            <Typography.Title level={5}>
              {interpolateString(WORKSPACE_ACCESS_MODAL_EXISTING_USERS, {
                count: workspaceUsers.length,
              })}
            </Typography.Title>
            {currentPageData.map((user) => (
              <WorkspaceMember
                key={user.userID}
                user={user}
                workspaceName={workspace.name}
                onRemove={onRemove}
                onRoleChange={onRoleChange}
                isDisabled={isAssigningUser || isRemovingUser(user.userID)}
              />
            ))}
          </Flex>
        )}
        {workspaceUsers.length > USERS_PER_PAGE && (
          <Pagination
            current={currentPage}
            total={workspaceUsers.length}
            defaultPageSize={USERS_PER_PAGE}
            showSizeChanger={false}
            onChange={(page) => setCurrentPage(page)}
          />
        )}
      </>
    );
  };

  const renderFooter = () => {
    if (!userToSetInWorkspace) {
      return (
        <Flex justify="flex-end" gap="middle">
          <Button disabled={isRemovingUser(userToRemoveFromWorkspace || '')} onClick={onClose}>
            {CANCEL_BUTTON_LABEL}
          </Button>
          <Button
            disabled={isRemovingUser(userToRemoveFromWorkspace || '')}
            type="primary"
            onClick={onClose}
          >
            {DONE_BUTTON_LABEL}
          </Button>
        </Flex>
      );
    }

    return (
      <Flex justify="flex-end" gap="middle">
        <Button disabled={isAssigningUser} onClick={() => setUserToSetInWorkspace(null)}>
          {CANCEL_BUTTON_LABEL}
        </Button>
        <Button
          disabled={isAssigningUser}
          loading={isAssigningUser}
          type="primary"
          onClick={() => onAssignUserToWorkspace(userToSetInWorkspace)}
        >
          {SAVE_BUTTON_LABEL}
        </Button>
      </Flex>
    );
  };

  if (!workspace) return null;

  return (
    <Modal
      data-testid="workspacesSettings_manageAccess_modal"
      title={interpolateString(WORKSPACE_ACCESS_MODAL_TITLE, { workspaceName: workspace.name })}
      open={true}
      onCancel={onClose}
      afterClose={resetModal}
      className={styles.modal}
      footer={renderFooter()}
    >
      <Flex gap="large" vertical className={styles.scrollWrapper}>
        {!!userToSetInWorkspace ? renderAddUserView() : renderListView()}
      </Flex>
    </Modal>
  );
};

export default ManageWorkspaceAccess;
