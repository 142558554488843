import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ThunderboltFilled, ThunderboltOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import styles from './upgradeTag.module.scss';
import { PREMIUM_LABEL, UPGRADE_LABEL } from '../../consts/pricing';

interface IUpgradeTagProps {
  isPremium?: boolean;
}

const UpgradeTag: React.FC = ({ isPremium }: IUpgradeTagProps) => {
  const navigate = useNavigate();
  return (
    <Tag
      icon={isPremium ? <ThunderboltOutlined /> : <ThunderboltFilled />}
      color="yellow"
      bordered={false}
      onClick={() => navigate('/upgrade')}
      className={styles.upgradeTag}
    >
      {isPremium ? PREMIUM_LABEL : UPGRADE_LABEL}
    </Tag>
  );
};

export default UpgradeTag;
