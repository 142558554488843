import { get } from 'lodash';
import { HTTPStatusCode } from '@constants/enum/common';
import { addNotification, addSequentialNotification } from '@redux/actions/notificationActions';
import { NotificationType } from '@redux/types/types';

export const normalizeErrorMessage = (error: any) => {
  return get(error, 'response.data.errors[0]', error?.message || 'Unexpected error occured.');
};

export const getStatusCode = (error: any) => {
  return get(error, 'response.status', HTTPStatusCode.INTERNAL_SERVER_ERROR);
};

export const handleErrorNotification = (error: any, dispatch: any, sq?: boolean) => {
  if (sq)
    dispatch(
      addSequentialNotification({
        content: normalizeErrorMessage(error),
        type: NotificationType.Error,
      }),
    );
  else
    dispatch(
      addNotification({
        content: normalizeErrorMessage(error),
        type: NotificationType.Error,
      }),
    );
};
