import React, { memo, useEffect, useRef } from 'react';
import { theme } from 'antd';
import Chart, { ChartDataset, ChartOptions } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ISearchCountData } from '@utils/transformation/dashboard';
import { CHART_TITLE } from '@constants/dashboard';
import styles from './dashboard.module.scss';

const { useToken } = theme;

interface INumberOfRequestsProps {
  data: ISearchCountData[];
}

interface IChartConfig {
  options: ChartOptions<'line'>;
  getDataset: (data: ISearchCountData[], primaryColor: string) => ChartDataset<'line'>;
}

const createChartConfig = (primaryColor: string): IChartConfig => {
  const DATALABELS_ANCHOR = 'end';
  const DATALABELS_ALIGN = 'top';

  return {
    options: {
      responsive: true,
      maintainAspectRatio: false,
      animation: false,
      scales: {
        y: {
          beginAtZero: true,
          grace: '11%',
          grid: { display: false },
          ticks: { display: false, stepSize: 1 },
        },
        x: {
          grid: { display: false },
        },
      },
      plugins: {
        datalabels: {
          color: primaryColor,
          anchor: DATALABELS_ANCHOR,
          align: DATALABELS_ALIGN,
        },
        legend: {
          display: false,
        },
      },
    },
    getDataset: (data) => ({
      data: data.map((row) => row.count),
      borderColor: primaryColor,
      backgroundColor: '#f0f7ff',
      fill: true,
    }),
  };
};

const NumberOfRequests = ({ data }: INumberOfRequestsProps) => {
  const { token } = useToken();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<Chart<'line'> | null>(null);
  const chartConfig = createChartConfig(token.colorPrimary);

  useEffect(() => {
    if (!canvasRef.current) return;

    const ctx = canvasRef.current.getContext('2d');
    if (!ctx) return;

    Chart.register(ChartDataLabels);

    chartRef.current = new Chart(ctx, {
      type: 'line',
      options: chartConfig.options,
      data: {
        labels: data.map((row) => row.date),
        datasets: [chartConfig.getDataset(data, token.colorPrimary)],
      },
    });

    return () => {
      chartRef.current?.destroy();
      chartRef.current = null;
    };
  }, []);

  useEffect(() => {
    if (!chartRef.current) return;

    const chart = chartRef.current;
    chart.data.labels = data.map((row) => row.date);
    chart.data.datasets[0].data = data.map((row) => row.count);
    chart.update('none');
  }, [data]);

  return (
    <section>
      <div className={styles.blockTitle}>{CHART_TITLE}</div>
      <div className={styles.chartContainer}>
        <canvas ref={canvasRef} />
      </div>
    </section>
  );
};

export default memo(NumberOfRequests);
