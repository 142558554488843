import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { DeleteOutlined, PlusOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, ButtonProps, Space, Tabs, Tooltip } from 'antd';
import { pipelineInProgress } from '@utils/pipelines';
import { interpolateString } from '@utils/string';
import usePipelineActions from '@hooks/usePipelineActions';
import { COMMON_FORM_ERRORS, DOCUMENTATION_LINK_LABEL } from '@constants/common';
import {
  DELETE_SELECTED_PIPELINES_PLURAL_LABEL,
  DELETE_SELECTED_PIPELINES_SINGULAR_LABEL,
} from '@constants/data-page';
import { StatusCodes } from '@constants/enum/common';
import { LOGS_LINK, PIPELINE_STATUS_MESSAGES } from '@constants/pipeline-details';
import {
  ACTIVATE_PIPELINE_BUTTON_LABEL,
  CANCEL_BUTTON_LABEL,
  CREATE_PIPELINE_LABEL,
  CREATED_BY_LABEL,
  DATE_LABEL,
  DECLINE_BUTTON_LABEL,
  DEFINE_PIPELINE_MODAL_TITLE,
  DELETE_BUTTON_LABEL,
  DEPLOY_BUTTON_LABEL,
  DETAILS_BUTTON_LABEL,
  DOCS_PIPELINES_LINK,
  DUPLICATE_BUTTON_LABEL,
  EDIT_BUTTON_LABEL,
  EMPTY_PIPELINES_PAGE_SECTION,
  EXPORT_PIPELINE_FEEDBACK_CSV_BUTTON_LABEL,
  IndexingStatusMessages,
  MenuActionsPipelinesList,
  NAME_LABEL,
  NAME_NEW_PIPELINE_MODAL_OK_BUTTON,
  NAME_NEW_PIPELINE_MODAL_TITLE,
  OBSERVABILITY_BUTTON_LABEL,
  PAGE_SIZE_PIPELINES_LIST,
  PIPELINE_SERVICE_LEVELS_LABELS,
  PipelineOutputType,
  PIPELINES_LIST_DEFAULT_SORTING_KEY_ALL_TAB,
  PIPELINES_LIST_SORTING_DATATABLE_OPTIONS,
  PipelineStatusCodes,
  PROMPT_EXPLORER_BUTTON_LABEL,
  RENAME_BUTTON_LABEL,
  RENAME_PIPELINE_MODAL_OK_BUTTON,
  RENAME_PIPELINE_MODAL_TITLE,
  SERVICE_LEVEL_LABEL,
  SHARE_BUTTON_LABEL,
  STATUS_LABEL,
  TABS_OPTIONS_PIPELINE_LIST,
  TABS_PIPELINES_LIST_KEYS,
  TRY_AGAIN_BUTTON_LABEL,
  UNDEPLOY_BUTTON_LABEL,
  UPGRADE_POPOVER_PIPELINE_CONTENT,
} from '@constants/pipelines';
import { DeepsetCloudVersion, IPipeline } from '@redux/types/types';
import UserAvatar from '@components/common/userAvatar/userAvatar';
import DataTable from '@components/dataTable/DataTable';
import DataTableActions from '@components/dataTable/DataTableActions';
import EmptyPageDescription from '@components/emptyPageDescription/EmptyPageDescription';
import NamePipelineModal from '@components/namePipelineModal/NamePipelineModal';
import PipelineConfirmationModal from '@components/pipelines/pipelineConfirmationModal/PipelineConfirmationModal';
import PipelineDeepsetCloudVersionTag from '@components/pipelines/pipelineDeepsetCloudVersionTag/PipelineDeepsetCloudVersionTag';
import PipelineServiceLevelBadge from '@components/pipelines/pipelineServiceLevelBadge/PipelineServiceLevelBadge';
import PipelineStatusTag from '@components/pipelineStatusTag/PipelineStatusTag';
import ShareModal from '@components/shareModal/ShareModal';
import UpgradeBanner from '@modules/Upgrade/components/upgradeBanner/UpgradeBanner';
import UpgradePopover from '@modules/Upgrade/components/upgradePopover/UpgradePopover';
import { FREE_TIER_LIMITS, LimitCategory } from '@modules/Upgrade/consts/pricing';
import useFreeLimits from '@modules/Upgrade/hooks/useFreeLimits';
import usePipelines from '@pages/pipelines/hooks/usePipelines';
import usePipelinesList from '@pages/pipelines/hooks/usePipelinesList';
import styles from './pipelinesList.module.scss';

const PipelinesLandingSVG = React.lazy(() => import('@assets/empty/pipelines-landing.svg?react'));

const PipelinesList = () => {
  const {
    status,
    deployPipeline,
    activatePipeline,
    actionStatus,
    message,
    deletePipeline,
    updatePipeline,
    fetchPipelineIndexing,
    pipelineIndexingData,
    renamePipelineNameStatus,
    pipelineCreateUpdatePipelineStatus,
    resetRenamePipelineNameStatus,
    resetMessage,
    newPipelineName,
    resetNewPipelineName,
  } = usePipelineActions();

  const {
    pipelinesList,
    totalPipelines,
    getPipelinesList,
    startPollingPipelinesList,
    stopPollingPipelinesList,
    activeTabPipelinesLandingPage,
    fetchPipelinesListStatus,
    sortValuePipelinesList,
    pipelineListDataParams,
  } = usePipelines();

  const {
    onChangeTab,
    handleMoreActionClick,
    pipelineToShare,
    setPipelineToShare,
    createPipelineFromEmptyYaml,
    setPipelineToDelete,
    pipelineToUndeploy,
    totalSelectedPipelines,
    setTotalSelectedPipelines,
    openPipelineConfirmationModal,
    getOnOkPipelineConfirmationModal,
    getOnCancelPipelineConfirmationModal,
    getPipelinesForConfirmationModal,
    onUndeployPipeline,
    pipelineToRename,
    showRenamePipelineModal,
    onAfterPipelineCreated,
    onAfterPipelineRenamed,
    onCloseNamePipelineModal,
    onGotToLogs,
    handleMenuCreatePipelineClick,
    onDeleteMultiplePipelines,
    createPipelineFromEmptyYamlHandler,
    isPipelineUnhealthy,
    resetCurrentPage,
    getEmptyLabelForTable,
  } = usePipelinesList();

  const { isLimitedUser, isLimitExceed } = useFreeLimits();

  const observabilityButtonRef = useRef<HTMLButtonElement>(null);

  const loading =
    status === StatusCodes.IN_PROGRESS ||
    actionStatus === StatusCodes.IN_PROGRESS ||
    fetchPipelinesListStatus === StatusCodes.IN_PROGRESS;
  const thereAreNoPipelines =
    fetchPipelinesListStatus === StatusCodes.SUCCESS &&
    pipelinesList.length === 0 &&
    !activeTabPipelinesLandingPage;

  const thereAreActiveFilters = () => !!pipelineListDataParams.current?.searchValue;

  const getUnhealthyMessage = (pipeline: IPipeline) => {
    const { name } = pipeline;
    const pipelineStatusMessage = PIPELINE_STATUS_MESSAGES.find((statusMessage) => {
      return statusMessage.status === IndexingStatusMessages.INDEXED;
    });

    if (pipelineStatusMessage && isPipelineUnhealthy(pipeline)) {
      const mainMessage = `${pipelineStatusMessage.title}. ${pipelineStatusMessage.message}`;
      const description = interpolateString(mainMessage, {
        logs: (
          <Button
            type="link"
            className={styles.pipelineStatus_alertLink}
            onClick={() => onGotToLogs(name)}
          >
            {LOGS_LINK}
          </Button>
        ),
      });
      return description;
    }
    return '';
  };

  const getNamePipelineModalTitle = () => {
    if (createPipelineFromEmptyYaml) return DEFINE_PIPELINE_MODAL_TITLE;
    if (createPipelineFromEmptyYaml) return NAME_NEW_PIPELINE_MODAL_TITLE;
    return RENAME_PIPELINE_MODAL_TITLE;
  };

  // Renders

  const columns: any = [
    {
      title: NAME_LABEL,
      dataIndex: 'name',
      key: 'name',
      width: '40%',
      render: (_: any, row: IPipeline) => (
        <>
          <PipelineDeepsetCloudVersionTag deepsetCloudVersion={row.deepset_cloud_version} />
          <Link to={`/pipelines/${row.name}`} className={styles.nameLink}>
            {row.name}
          </Link>
        </>
      ),
    },
    {
      title: SERVICE_LEVEL_LABEL,
      dataIndex: 'service_level',
      key: 'service_level',
      width: '10%',
      render: (_: any, row: any) => {
        return (
          <>
            <PipelineServiceLevelBadge serviceLevel={row.service_level} />
            <span className={styles.serviceLevelLabel}>
              {
                PIPELINE_SERVICE_LEVELS_LABELS[
                  row.service_level as keyof typeof PIPELINE_SERVICE_LEVELS_LABELS
                ]
              }
            </span>
          </>
        );
      },
    },
    {
      title: DATE_LABEL,
      dataIndex: 'created_at',
      key: 'created_at',
      width: '15%',
      render: (date: string) => new Date(date).toLocaleString(),
    },
    {
      title: STATUS_LABEL,
      dataIndex: 'indexing',
      key: 'status',
      width: '10%',
      render: (_: any, row: any) => {
        return (
          <div className={styles.pipelineStatus}>
            <PipelineStatusTag
              pipeline={row}
              indexingData={pipelineIndexingData}
              getIndexingDetails={(name) => fetchPipelineIndexing(name)}
            />
            {isPipelineUnhealthy(row) && (
              <Tooltip placement="top" title={getUnhealthyMessage(row)}>
                <WarningOutlined className={styles.pipelineStatus_unhealthyIcon} />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: CREATED_BY_LABEL,
      dataIndex: 'created_by',
      key: 'created_by',
      width: '10%',
      render: (_: any, row: any) => {
        return (
          <div className={styles.createdBy}>
            <UserAvatar user={row.created_by} />
          </div>
        );
      },
    },
    {
      key: 'action',
      width: '15%',
      align: 'right' as const,
      render: (text: any, record: any) => {
        const items = [
          {
            label: DETAILS_BUTTON_LABEL,
            key: MenuActionsPipelinesList.Details,
          },
          {
            label: EDIT_BUTTON_LABEL,
            key:
              record.deepset_cloud_version === DeepsetCloudVersion.V2
                ? MenuActionsPipelinesList.Studio
                : MenuActionsPipelinesList.Edit,
          },
          {
            label: RENAME_BUTTON_LABEL,
            key: MenuActionsPipelinesList.RenamePipeline,
          },
          {
            label: DUPLICATE_BUTTON_LABEL,
            key: MenuActionsPipelinesList.Duplicate,
          },
          ...(record.status === PipelineStatusCodes.DEPLOYED && record.supports_prompt
            ? [
                {
                  label: PROMPT_EXPLORER_BUTTON_LABEL,
                  key: MenuActionsPipelinesList.PromptExplorer,
                },
              ]
            : []),
          ...(record.output_type === PipelineOutputType.GENERATIVE
            ? [
                {
                  label: OBSERVABILITY_BUTTON_LABEL,
                  key: MenuActionsPipelinesList.Observability,
                },
              ]
            : []),
          {
            label: EXPORT_PIPELINE_FEEDBACK_CSV_BUTTON_LABEL,
            key: MenuActionsPipelinesList.ExportFeedbackCSV,
          },
          ...(record.desired_status === PipelineStatusCodes.DEPLOYED
            ? [
                {
                  label: UNDEPLOY_BUTTON_LABEL,
                  key: MenuActionsPipelinesList.Undeploy,
                  'data-testid': 'undeployPipeline_menuActionsButton',
                },
              ]
            : []),
          {
            label: DELETE_BUTTON_LABEL,
            key: MenuActionsPipelinesList.Delete,
            danger: true,
            icon: <DeleteOutlined />,
          },
        ];

        let buttonProps = {
          label: DEPLOY_BUTTON_LABEL,
          action: deployPipeline,
          loading: pipelineInProgress(record.status),
          disabled: isLimitedUser && isLimitExceed(LimitCategory.PIPELINE_HOURS),
        };

        if (record.desired_status === PipelineStatusCodes.DEPLOYED) {
          if (record.status === PipelineStatusCodes.DEPLOYMENT_FAILED)
            buttonProps = { ...buttonProps, label: TRY_AGAIN_BUTTON_LABEL };
          else if (record.status === PipelineStatusCodes.IDLE)
            buttonProps = {
              ...buttonProps,
              label: ACTIVATE_PIPELINE_BUTTON_LABEL,
              action: activatePipeline,
            };
          else
            buttonProps = {
              ...buttonProps,
              label: SHARE_BUTTON_LABEL,
              action: async (pipelineName: string) => setPipelineToShare(pipelineName),
            };
        }

        if (pipelineInProgress(record.status))
          buttonProps = {
            ...buttonProps,
            label: CANCEL_BUTTON_LABEL,
            action:
              record.status === PipelineStatusCodes.DEPLOYMENT_IN_PROGRESS ||
              record.status === PipelineStatusCodes.DEPLOYMENT_SCHEDULED
                ? () => onUndeployPipeline(record)
                : deployPipeline,
            loading: false,
            disabled: false,
          };

        return (
          <DataTableActions
            menu={{
              items,
              onClick: ({ key }) => handleMoreActionClick(key, record.name, record),
              'data-testid': 'pipelines_tableRow_moreActions_button',
              ref:
                !observabilityButtonRef.current &&
                record.output_type === PipelineOutputType.GENERATIVE
                  ? observabilityButtonRef
                  : undefined,
            }}
            item={record.name}
            onDelete={deletePipeline}
            onCancelDelete={() => setPipelineToDelete(null)}
            primaryButton={
              record.status !== PipelineStatusCodes.ACTIVATING ? buttonProps : undefined
            }
            cancelButtonLabel={DECLINE_BUTTON_LABEL}
          />
        );
      },
    },
  ];

  const renderCreatePipelineDropdown = () => {
    return isLimitedUser && totalPipelines >= FREE_TIER_LIMITS.PIPELINES ? (
      <UpgradePopover description={UPGRADE_POPOVER_PIPELINE_CONTENT}>
        <Button type="primary">
          <Space>{CREATE_PIPELINE_LABEL}</Space>
        </Button>
      </UpgradePopover>
    ) : (
      <Button
        icon={<PlusOutlined />}
        type="primary"
        onClick={() => handleMenuCreatePipelineClick()}
      >
        <Space>{CREATE_PIPELINE_LABEL}</Space>
      </Button>
    );
  };

  const renderEmptyPipelineState = () => {
    return (
      <EmptyPageDescription
        title={EMPTY_PIPELINES_PAGE_SECTION.TITLE}
        description={EMPTY_PIPELINES_PAGE_SECTION.DESCRIPTION.map(({ paragraph }) => ({
          paragraph: interpolateString(paragraph, {
            documentationLink: (
              <a href={DOCS_PIPELINES_LINK} target="_blank" rel="noreferrer">
                {DOCUMENTATION_LINK_LABEL}
              </a>
            ),
          }),
        }))}
        image={<PipelinesLandingSVG />}
        action={renderCreatePipelineDropdown()}
      />
    );
  };

  const renderPipelinesListTable = () => {
    return (
      <>
        <UpgradeBanner category={LimitCategory.PIPELINE_HOURS} />
        <Tabs
          className={styles.tabs}
          activeKey={activeTabPipelinesLandingPage || TABS_PIPELINES_LIST_KEYS.ALL}
          onChange={(activeKey) => onChangeTab(activeKey)}
          tabBarExtraContent={renderCreatePipelineDropdown()}
          items={TABS_OPTIONS_PIPELINE_LIST.map((tab) => {
            return {
              label: tab.label,
              key: tab.key,
              disabled: fetchPipelinesListStatus === StatusCodes.IN_PROGRESS,
            };
          })}
        />

        <DataTable
          data={pipelinesList}
          total={totalPipelines}
          loading={loading}
          columns={columns}
          getData={(currentPage, pageSize, sortValue, searchValue) =>
            getPipelinesList(currentPage, pageSize, sortValue, searchValue)
          }
          refetch={actionStatus === StatusCodes.SUCCESS}
          rowKey="name"
          locale={{ emptyText: getEmptyLabelForTable() }}
          polling={{
            enabled: true,
            startPolling: startPollingPipelinesList,
            stopPolling: stopPollingPipelinesList,
          }}
          sorting={{
            selectedValue:
              sortValuePipelinesList === PIPELINES_LIST_DEFAULT_SORTING_KEY_ALL_TAB
                ? ''
                : sortValuePipelinesList,
            options: PIPELINES_LIST_SORTING_DATATABLE_OPTIONS,
            allowClear: activeTabPipelinesLandingPage?.length === 0,
          }}
          pagination={{
            pageSize: PAGE_SIZE_PIPELINES_LIST,
            currentPageNumber: resetCurrentPage ? 1 : undefined,
          }}
          selectActions={[
            {
              type: 'default' as ButtonProps['type'],
              danger: true,
              label: (totalSelectedPipelines === 1
                ? interpolateString(DELETE_SELECTED_PIPELINES_SINGULAR_LABEL, {
                    total: totalSelectedPipelines,
                  })
                : interpolateString(DELETE_SELECTED_PIPELINES_PLURAL_LABEL, {
                    total: totalSelectedPipelines,
                  })) as string,
              onClick: (pipelineNames) => onDeleteMultiplePipelines(pipelinesList, pipelineNames),
            },
          ]}
          setTotalSelectedItems={(totalFilesSelected) =>
            setTotalSelectedPipelines(totalFilesSelected)
          }
          rowAnimation={{
            newRowName: newPipelineName,
            afterNewRowAnimation: () => resetNewPipelineName(),
          }}
        />
      </>
    );
  };

  return (
    <div className={styles.container}>
      {thereAreNoPipelines && !thereAreActiveFilters()
        ? renderEmptyPipelineState()
        : renderPipelinesListTable()}
      {pipelineToShare && (
        <ShareModal pipelineName={pipelineToShare} onCancel={() => setPipelineToShare('')} />
      )}
      {showRenamePipelineModal && (
        <NamePipelineModal
          openModal={showRenamePipelineModal}
          closeModal={onCloseNamePipelineModal}
          modalTitle={getNamePipelineModalTitle()}
          okButtonText={
            createPipelineFromEmptyYaml
              ? NAME_NEW_PIPELINE_MODAL_OK_BUTTON
              : RENAME_PIPELINE_MODAL_OK_BUTTON
          }
          pipelineName={createPipelineFromEmptyYaml ? '' : pipelineToRename}
          description={createPipelineFromEmptyYaml ? COMMON_FORM_ERRORS.INVALID_CHARACTERS : ''}
          renamePipeline={(payload: { pipelineName: string; newPipelineName: string }) => {
            if (!createPipelineFromEmptyYaml) updatePipeline(payload);
          }}
          createPipelineFromEmptyYaml={(
            pipelineName: string,
            deepsetCloudversion?: DeepsetCloudVersion,
          ) => {
            if (createPipelineFromEmptyYaml) {
              createPipelineFromEmptyYamlHandler(pipelineName, deepsetCloudversion);
            }
          }}
          onAfterPipelineCreated={onAfterPipelineCreated}
          onAfterPipelineRenamed={onAfterPipelineRenamed}
          renamePipelineNameStatus={
            createPipelineFromEmptyYaml ? undefined : renamePipelineNameStatus
          }
          pipelineCreateUpdatePipelineStatus={pipelineCreateUpdatePipelineStatus}
          resetRenamePipelineNameStatus={resetRenamePipelineNameStatus}
          pipelineMessage={message}
          resetMessage={resetMessage}
          showDeepsetCloudVersionOptions={createPipelineFromEmptyYaml}
        />
      )}
      {openPipelineConfirmationModal && (
        <PipelineConfirmationModal
          open={openPipelineConfirmationModal}
          okText={pipelineToUndeploy ? UNDEPLOY_BUTTON_LABEL : DELETE_BUTTON_LABEL}
          onOk={getOnOkPipelineConfirmationModal}
          cancelText={CANCEL_BUTTON_LABEL}
          onCancel={getOnCancelPipelineConfirmationModal}
          pipelines={getPipelinesForConfirmationModal()}
          undeployingPipeline={!!pipelineToUndeploy}
        />
      )}
    </div>
  );
};

export default PipelinesList;
