import React, { useEffect, useState } from 'react';
import Icon, { CopyOutlined, DownloadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Avatar, Button, Drawer, Modal, Tag, Tooltip } from 'antd';
import { isNil } from 'lodash';
import { formatNumberToLocaleString } from '@utils/math';
import { sortCollectionAlphabeticallyBy } from '@utils/sort';
import { trimContent } from '@utils/string';
import { getUserPreference } from '@utils/userPreferences';
import BookmarkFilledSVG from '@assets/icons/BookmarkFilled.svg?react';
import usePipelineFeedback from '@hooks/usePipelineFeedback';
import { useUserEvent } from '@hooks/useUserEvent';
import {
  FilterProp,
  FiltersProp,
  FilterType,
  SelectedFilterItem,
  SelectedFilters,
} from '@constants/data-table';
import { StatusCodes } from '@constants/enum/common';
import {
  EventControlComponent,
  EventControlDataTableElement,
  EventControlElement,
  UserEventType,
} from '@constants/event';
import {
  COLUMN_TITLES,
  COPY_BUTTON_LABEL,
  COPY_TOOLTIP,
  DONE_BUTTON_LABEL,
  DOWNLOAD_FEEDBACK_LABEL,
  FEEDBACK_FILTER_FEEDBACK_TYPE_LABEL,
  FEEDBACK_FILTER_TAGS_LABEL,
  FEEDBACK_FILTER_USER_LABEL,
  FEEDBACK_FILTERS_FIELD_KEYS,
  FEEDBACK_SORTING_DATATABLE_OPTIONS,
  FEEDBACK_TYPE_LABELS,
  FILTERS_COLUMN_LABEL,
  FILTERS_LABEL,
  TITLE,
} from '@constants/pipeline-feedback';
import { FeedbackSearchResultType } from '@constants/pipelines';
import { ICreatedBy } from '@redux/types/commonTypes';
import {
  FeedbackType,
  IAPIPaginationData,
  IPipelineFeedbackData,
  ITag,
  PipelineFeedback,
} from '@redux/types/types';
import { contextRender } from '@components/common/contextRender/contextRender';
import SwitchWithText from '@components/common/switchWithText/SwitchWithText';
import DataTable from '@components/dataTable/DataTable';
import PipelineFeedbackRatingTag from '@components/PipelineFeedbackRatingTag/PipelineFeedbackRatingTag';
import SearchResultActions from '@components/search/organisms/resultActions/SearchResultActions';
import SearchResultBookmark from '@components/search/organisms/searchResultBookmark/SearchResultBookmark';
import styles from './pipelineFeedbackTable.module.scss';

interface IPipelineFeedbackTableProps {
  pipelineId: string;
  pipelineName: string;
  feedback: PipelineFeedback;
  selectedSortValue: string;
  tags: IAPIPaginationData<ITag[]>;
  bookmarkFilter: SelectedFilterItem;
  getFeedbacks: (payload: {
    currentPage: number;
    pageSize: number;
    searchValue: string;
    sortValue?: string;
    filterValues?: SelectedFilters;
  }) => void;
  onQueryClick: (idx: number | undefined) => void;
  onBookmarkFilterClick: (value: boolean) => void;
}

const PipelineFeedbackTable = (props: IPipelineFeedbackTableProps) => {
  const {
    pipelineId,
    pipelineName,
    feedback,
    tags,
    selectedSortValue,
    bookmarkFilter,
    getFeedbacks,
    onQueryClick,
    onBookmarkFilterClick,
  } = props;

  const {
    exportPipelineFeedbackCSV,
    getPipelineFeedbackStatus,
    getPipelineFeedbackFilterUser,
    pipelineFeedbackFilterValues,
    providedFeedbackByResultId,
    setProvidedFeedbackByResultId,
  } = usePipelineFeedback();
  const { data, total } = feedback;
  const [filtersModal, setFiltersModal] = useState({
    open: false,
    filters: {},
  });
  const [feedbackDrawerContent, setFeedbackDrawerContent] = useState('');
  const { trackUserEvent } = useUserEvent({ pipeline_id: pipelineId, pipeline_name: pipelineName });

  useEffect(() => {
    if (!data?.length) return;
    data.forEach((item: IPipelineFeedbackData) => {
      setProvidedFeedbackByResultId(item.result_id, item);
    });
  }, [data]);

  const isGenQAPipelineType =
    data[0]?.search_history?.type === FeedbackSearchResultType.GENERATIVE_QUESTION_ANSWERING;

  useEffect(() => {
    if (pipelineId) getPipelineFeedbackFilterUser(pipelineId);
  }, [pipelineId]);

  const getTagsFilterValues = () => {
    const { data: tagsData } = tags;
    if (!tagsData?.length) return [];
    return tagsData.map(({ tag_id: key, name: label }) => ({
      key,
      label,
    }));
  };

  const getFilters = (): FiltersProp | [] => {
    const { users } = pipelineFeedbackFilterValues;

    const feedbackType = {
      type: FilterType.SELECT,
      key: FEEDBACK_FILTERS_FIELD_KEYS.FEEDBACK_TYPE,
      title: FEEDBACK_FILTER_FEEDBACK_TYPE_LABEL,
      options: [
        {
          key: String(FeedbackType.ACCURATE),
          label: FEEDBACK_TYPE_LABELS[FeedbackType.ACCURATE],
          value: FeedbackType.ACCURATE,
        },
        {
          key: String(FeedbackType.FAIRLY_ACCURATE),
          label: FEEDBACK_TYPE_LABELS[FeedbackType.FAIRLY_ACCURATE],
          value: FeedbackType.FAIRLY_ACCURATE,
        },
        {
          key: String(FeedbackType.INACCURATE),
          label: FEEDBACK_TYPE_LABELS[FeedbackType.INACCURATE],
          value: FeedbackType.INACCURATE,
        },
      ],
      style: { minWidth: '147px' },
    } as FilterProp<FilterType.SELECT>;
    const tagsFilter = {
      type: FilterType.MULTI_SELECT,
      key: FEEDBACK_FILTERS_FIELD_KEYS.TAGS_ID,
      title: FEEDBACK_FILTER_TAGS_LABEL,
      options: getTagsFilterValues(),
    } as FilterProp<FilterType.MULTI_SELECT>;
    const createdByFilter = {
      type: FilterType.MULTI_SELECT,
      key: FEEDBACK_FILTERS_FIELD_KEYS.USER,
      title: FEEDBACK_FILTER_USER_LABEL,
      options: users,
      style: { minWidth: '120px' },
    } as FilterProp<FilterType.MULTI_SELECT>;

    return [feedbackType, ...(users?.length ? [createdByFilter] : []), tagsFilter];
  };

  const previewFeedbackItem = (item: any) => {
    setFeedbackDrawerContent(item);
  };

  const handleCancelFiltersModal = () => {
    setFiltersModal({
      open: false,
      filters: '',
    });
  };

  const onCopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  const onDownloadFeedbacksClick = () => {
    exportPipelineFeedbackCSV(pipelineId, pipelineName);

    trackUserEvent({
      type: UserEventType.CLICK,
      control: `${EventControlComponent.PIPELINE_FEEDBACK_TABLE}/${EventControlElement.DOWNLOAD}`,
      properties: {},
    });
  };

  const onQueryItemClick = (idx: number) => {
    onQueryClick(idx);

    const { feedback_id: feedbackId } = data[idx];
    trackUserEvent({
      type: UserEventType.CLICK,
      control: `${EventControlComponent.PIPELINE_FEEDBACK_TABLE}/${EventControlDataTableElement.ITEM}`,
      properties: {
        feedback_id: feedbackId,
      },
    });
  };

  const onFiltersModalOpen = (filters: Record<string, unknown>, idx: number) => {
    setFiltersModal({
      open: true,
      filters,
    });

    const { feedback_id: feedbackId } = data[idx];
    trackUserEvent({
      type: UserEventType.CLICK,
      control: `${EventControlComponent.PIPELINE_FEEDBACK_TABLE}/${EventControlElement.FILTERS}`,
      properties: {
        feedback_id: feedbackId,
      },
    });
  };

  const optionalColumns = [
    { key: 'answer', title: COLUMN_TITLES.ANSWER },
    ...(!isGenQAPipelineType ? [{ key: 'context', title: COLUMN_TITLES.CONTEXT }] : []),
    ...(!isGenQAPipelineType ? [{ key: 'relevance', title: COLUMN_TITLES.RELEVANCE }] : []),
    ...(isGenQAPipelineType ? [{ key: 'prompt', title: COLUMN_TITLES.PROMPT }] : []),
    { key: 'rank', title: COLUMN_TITLES.RANK },
    { key: 'user', title: COLUMN_TITLES.USER },
    { key: 'query_id', title: COLUMN_TITLES.QUERY_ID },
    { key: 'duration', title: COLUMN_TITLES.DURATION },
    { key: 'query_date', title: COLUMN_TITLES.QUERY_DATE },
    { key: 'filters', title: COLUMN_TITLES.FILTERS },
    { key: 'file_name', title: COLUMN_TITLES.FILE_NAME },
    { key: 'file_id', title: COLUMN_TITLES.FILE_ID },
  ];

  let defaultColumns = getUserPreference('feedbackColumnSelection') || [
    'query',
    'answer',
    'context',
    'prompt',
    'feedbackRating',
    'feedbackNotes',
    'feedbackTags',
    'rank',
    'user',
    'relevance',
  ];

  if (isGenQAPipelineType)
    defaultColumns = defaultColumns.filter(
      (column: any) => column !== 'context' && column !== 'relevance',
    );
  else defaultColumns = defaultColumns.filter((column: any) => column !== 'prompt');

  const columns = [
    {
      key: 'feedbackBookmarked',
      align: 'center',
      dataIndex: 'bookmarked',
      fixed: 'left',
      width: 18,
      className: styles.bookmarkColumn,
      render: (_: boolean, result: IPipelineFeedbackData) => {
        return (
          <SearchResultBookmark
            withLabel={false}
            resultId={result.result_id}
            queryId={result.search_history?.query_id}
            pipelineId={pipelineId}
          />
        );
      },
    },
    {
      title: COLUMN_TITLES.QUERY,
      dataIndex: ['search_history', 'search', 'query'],
      key: 'query',
      fixed: 'left',
      width: 300,
      render: (query: string, _: unknown, idx: number) => (
        <Button
          type="link"
          size="small"
          className={styles.queryLink}
          onClick={() => {
            onQueryItemClick(idx);
          }}
        >
          <InfoCircleOutlined />
          {trimContent(query, 250)}
        </Button>
      ),
    },
    ...(data &&
    data.length > 0 &&
    data.some((row: any) => row.search_history && row.search_history.answer)
      ? [
          {
            title: COLUMN_TITLES.ANSWER,
            dataIndex: ['search_history', 'answer'],
            key: 'answer',
            width: 300,
            render: (answer: string) =>
              contextRender({
                content: answer,
                maxCharacters: 250,
                previewButtonHandler: previewFeedbackItem,
              }),
          },
        ]
      : []),
    ...(!isGenQAPipelineType
      ? [
          {
            title: COLUMN_TITLES.CONTEXT,
            dataIndex: 'search_history',
            key: 'context',
            width: 600,
            render: (result: any) => {
              const { context, offsets_in_context: contextOffset } = result;
              const { start: answerStart, end: answerEnd } =
                (contextOffset && contextOffset[0]) || {};

              return contextRender({
                content: context,
                answerStart,
                answerEnd,
                previewButtonHandler: previewFeedbackItem,
              });
            },
          },
        ]
      : []),
    ...(isGenQAPipelineType
      ? [
          {
            title: COLUMN_TITLES.PROMPT,
            dataIndex: ['search_history', 'prompt'],
            key: 'prompt',
            width: 300,
            render: (prompt: string) =>
              contextRender({
                content: prompt,
                maxCharacters: 250,
                previewButtonHandler: previewFeedbackItem,
              }),
          },
        ]
      : []),
    {
      title: COLUMN_TITLES.FEEDBACK_RATING,
      dataIndex: 'score',
      key: 'feedbackRating',
      render: (value: FeedbackType, result: IPipelineFeedbackData) => {
        // We read from store first, in case the user updated the feedback
        // that way we avoid an extra API call
        const providedFeedback = providedFeedbackByResultId[result.result_id];
        const providedFeedbackRating = providedFeedback?.score || value;

        if (!providedFeedbackRating)
          return (
            <SearchResultActions
              resultId={result.result_id}
              queryId={result.search_history?.query_id}
              pipelineId={pipelineId}
              pipelineName={pipelineName}
              withBookmark={false}
            />
          );
        return <PipelineFeedbackRatingTag type={providedFeedbackRating} />;
      },
    },
    {
      title: COLUMN_TITLES.FEEDBACK_NOTES,
      dataIndex: 'comment',
      key: 'feedbackNotes',
      render: (comment: string, result: IPipelineFeedbackData) => {
        const providedFeedback = providedFeedbackByResultId[result.result_id];
        const providedFeedbackComment = providedFeedback?.comment || comment;

        return <div>{providedFeedbackComment}</div>;
      },
    },
    {
      title: COLUMN_TITLES.FEEDBACK_TAGS,
      key: 'tags',
      dataIndex: 'tags',
      width: 120,
      render: (rowTags: ITag[], result: IPipelineFeedbackData) => {
        const providedFeedback = providedFeedbackByResultId[result.result_id];
        const providedFeedbackTags = providedFeedback?.tags || rowTags;

        return (
          <>
            {!!providedFeedbackTags &&
              sortCollectionAlphabeticallyBy(providedFeedbackTags, 'name').map((tag: ITag) => {
                const { tag_id: tagID, name } = tag;
                return (
                  <Tag key={tagID} className={styles.pipelineFeedbackTable_tags}>
                    {name}
                  </Tag>
                );
              })}
          </>
        );
      },
    },
    ...(!isGenQAPipelineType
      ? [
          {
            title: COLUMN_TITLES.RELEVANCE,
            dataIndex: ['search_history', 'score'],
            key: 'relevance',
            render: (value: number) => `${formatNumberToLocaleString(value * 100)}%`,
          },
        ]
      : []),
    {
      title: COLUMN_TITLES.RANK,
      dataIndex: ['search_history', 'rank'],
      key: 'rank',
    },
    {
      title: COLUMN_TITLES.USER,
      dataIndex: ['created_by'],
      key: 'user',
      render: (user: ICreatedBy) => {
        if (isNil(user?.family_name) && isNil(user?.given_name)) return null;
        const { family_name: familyName, given_name: givenName } = user;
        return (
          <Tooltip title={`${givenName} ${familyName}`}>
            <Avatar>{givenName[0] + familyName[0]}</Avatar>
          </Tooltip>
        );
      },
    },
    {
      title: COLUMN_TITLES.QUERY_ID,
      dataIndex: ['search_history', 'query_id'],
      key: 'query_id',
    },
    {
      title: COLUMN_TITLES.DURATION,
      dataIndex: ['search_history', 'search', 'duration'],
      key: 'duration',
      render: (value: number) => `${formatNumberToLocaleString(value)}s`,
    },
    {
      title: COLUMN_TITLES.QUERY_DATE,
      dataIndex: ['search_history', 'search', 'created_at'],
      key: 'query_date',
      render: (date: string) => new Date(date).toLocaleDateString(),
    },
    ...(data &&
    data.length > 0 &&
    data.some((row) => Object.keys(row?.search_history?.search?.filters || {})?.length > 0)
      ? [
          {
            title: COLUMN_TITLES.FILTERS,
            dataIndex: ['search_history', 'search', 'filters'],
            key: 'filters',
            render: (filters: Record<string, unknown>, _: unknown, idx: number) => {
              if (!filters || Object.keys(filters).length === 0) {
                return null;
              }

              return (
                <Button
                  className={styles.pipelineFeedbackTable_filtersButton}
                  type="link"
                  onClick={() => onFiltersModalOpen(filters, idx)}
                >
                  {FILTERS_COLUMN_LABEL} ({Object.keys(filters).length})
                </Button>
              );
            },
          },
        ]
      : []),
    {
      title: COLUMN_TITLES.FILE_NAME,
      dataIndex: ['search_history', 'files'],
      key: 'file_name',
      render: (
        files: {
          id: string;
          name: string;
        }[],
      ) => files.map((file) => <div key={file.id}>{file.name}</div>),
    },
    {
      title: COLUMN_TITLES.FILE_ID,
      dataIndex: ['search_history', 'files'],
      key: 'file_id',
      render: (
        files: {
          id: string;
          name: string;
        }[],
      ) => files.map((file) => <div key={file.id}>{file.id}</div>),
    },
    {
      key: 'action',
      width: '80px',
      align: 'right' as const,
      fixed: 'right' as const,
    },
  ];

  // Renders

  const renderBookmarkFilter = () => {
    return (
      <SwitchWithText
        text={bookmarkFilter.label}
        icon={<Icon component={BookmarkFilledSVG} />}
        value={bookmarkFilter.value as boolean}
        onSwitchChange={onBookmarkFilterClick}
      />
    );
  };

  const filtersModalRender = () => {
    const { open: filtersModalOpen, filters: filtersModalFilters } = filtersModal;
    const filtersJson = JSON.stringify(filtersModalFilters, null, 2);
    return (
      <Modal
        title={FILTERS_LABEL}
        open={filtersModalOpen}
        centered
        onCancel={handleCancelFiltersModal}
        footer={
          <>
            <Tooltip
              title={COPY_TOOLTIP}
              trigger="click"
              className={styles.copyButton}
              placement="bottom"
            >
              <Button icon={<CopyOutlined />} onClick={() => onCopyToClipboard(filtersJson)}>
                {COPY_BUTTON_LABEL}
              </Button>
            </Tooltip>
            <Button type="primary" onClick={handleCancelFiltersModal}>
              {DONE_BUTTON_LABEL}
            </Button>
          </>
        }
      >
        <pre className={styles.jsonView}>{filtersJson}</pre>
      </Modal>
    );
  };

  const feedbackDetails = () => (
    <Drawer
      open={!!feedbackDrawerContent}
      onClose={() => setFeedbackDrawerContent('')}
      size="large"
      className={styles.detailsDrawer}
    >
      <pre>{feedbackDrawerContent}</pre>
    </Drawer>
  );

  return (
    <section className={styles.section}>
      <h5> {TITLE} </h5>
      <DataTable
        data={data}
        columns={columns}
        total={total}
        searchAvailable
        border
        loading={getPipelineFeedbackStatus === StatusCodes.IN_PROGRESS}
        getData={(
          currentPage: number,
          pageSize: number,
          searchValue: string,
          sortValue?: string,
          filterValues?: SelectedFilters,
        ) => getFeedbacks({ currentPage, pageSize, searchValue, sortValue, filterValues })}
        sorting={{
          selectedValue: selectedSortValue,
          options: FEEDBACK_SORTING_DATATABLE_OPTIONS,
        }}
        rowSelection={false}
        rowKey="feedback_id"
        filters={getFilters()}
        primaryAction={{
          label: DOWNLOAD_FEEDBACK_LABEL,
          onClick: () => onDownloadFeedbacksClick(),
          disabled: total === 0,
          secondary: true,
          icon: <DownloadOutlined />,
        }}
        scroll={{ x: 'max-content' }}
        columnsConfig={{
          optionalColumns,
          defaultColumns,
          cacheLocation: 'feedbackColumnSelection',
        }}
        surSecondaryActions={renderBookmarkFilter()}
        userEventsTrackingHandlers={{
          onSearch: (value) =>
            trackUserEvent({
              type: UserEventType.KEYDOWN,
              control: `${EventControlComponent.PIPELINE_FEEDBACK_TABLE}/${EventControlDataTableElement.SEARCH}`,
              properties: {
                value,
              },
            }),
          onPageChange: (value) =>
            trackUserEvent({
              type: UserEventType.CLICK,
              control: `${EventControlComponent.PIPELINE_FEEDBACK_TABLE}/${EventControlDataTableElement.PAGINATION}`,
              properties: {
                value,
              },
            }),
          onSort: (value) =>
            trackUserEvent({
              type: UserEventType.CLICK,
              control: `${EventControlComponent.PIPELINE_FEEDBACK_TABLE}/${EventControlDataTableElement.SORT}`,
              properties: {
                value,
              },
            }),
          onFilter: (filterKey) =>
            trackUserEvent({
              type: UserEventType.CLICK,
              control: `${EventControlComponent.PIPELINE_FEEDBACK_TABLE}/${EventControlDataTableElement.FILTER}`,
              properties: {
                filter_key: filterKey,
              },
            }),
          onClearFilter: (filterKey) =>
            trackUserEvent({
              type: UserEventType.CLICK,
              control: `${EventControlComponent.PIPELINE_FEEDBACK_TABLE}/${EventControlDataTableElement.CLEAR_FILTER}`,
              properties: {
                filter_key: filterKey,
              },
            }),
          onClearAllFilters: () =>
            trackUserEvent({
              type: UserEventType.CLICK,
              control: `${EventControlComponent.PIPELINE_FEEDBACK_TABLE}/${EventControlDataTableElement.CLEAR_ALL_FILTERS}`,
              properties: {},
            }),
        }}
      />
      {filtersModalRender()}
      {feedbackDetails()}
    </section>
  );
};

export default PipelineFeedbackTable;
