import { createSelector } from 'reselect';
import { RootState } from '@redux/store';

export const userStoreSelector = (state: RootState) => state.userStore;

export const userSelector = createSelector(userStoreSelector, (userStore) => userStore.user);

export const userLoginErrorSelector = createSelector(
  userStoreSelector,
  (userStore) => userStore.loginError,
);

export const assignUserToWorkspaceStatusSelector = createSelector(
  userStoreSelector,
  (userStore) => userStore.assignUserToWorkspaceStatus,
);

export const removeUserFromWorkspaceStatusSelector = createSelector(
  userStoreSelector,
  (userStore) => userStore.removeUserFromWorkspaceStatus,
);
