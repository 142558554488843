import React from 'react';
import { UserDeleteOutlined } from '@ant-design/icons';
import { Button, Flex, Select, Typography } from 'antd';
import { UserRoles, UserRolesMap } from '@constants/enum/common';
import { IUserData } from '@redux/types/types';
import styles from './workspaceMember.module.scss';

interface IWorkspaceMemberProps {
  user: IUserData;
  workspaceName: string;
  isDisabled?: boolean;
  onRemove: (user: IUserData) => void;
  onRoleChange: (change: { user: IUserData; role: UserRoles }) => void;
}

const WorkspaceMember: React.FC<IWorkspaceMemberProps> = ({
  onRemove,
  onRoleChange,
  isDisabled = false,
  user,
  workspaceName,
}) => {
  const { email, fullName, workspaces } = user;
  const workspace = workspaces.find((ws) => ws.name === workspaceName);
  const defaultRow = workspace ? (workspace.role as UserRoles) : UserRoles.EDITOR;

  const userRolesEntries = Object.entries(UserRolesMap).filter(([key]) => key !== UserRoles.ADMIN);
  const userRolesMap = userRolesEntries.map(([key, value]) => ({ label: value, value: key }));

  return (
    <Flex justify="space-between" align="center" className={styles.container}>
      <Typography.Paragraph>
        {fullName}
        <br />
        <small>{email}</small>
      </Typography.Paragraph>
      <Flex align="flex-start" justify="flex-end">
        <Select
          className={styles.select}
          options={userRolesMap}
          defaultValue={UserRolesMap[defaultRow]}
          onChange={(role: string) => onRoleChange({ user, role: role as UserRoles })}
          disabled={isDisabled}
        />
        <Button
          type="link"
          size="small"
          danger
          icon={<UserDeleteOutlined />}
          onClick={() => onRemove(user)}
          disabled={isDisabled}
        />
      </Flex>
    </Flex>
  );
};

export default WorkspaceMember;
