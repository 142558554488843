export enum MessageCodes {
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
  INFO = 'info',
}

export enum StatusCodes {
  IN_PROGRESS = 'in_progress',
  SUCCESS = 'success',
  ERROR = 'error',
  IDLE = 'idle',
}

export enum HTTPStatusCode {
  OK = 200,
  CREATED = 201,
  NOT_FOUND = 404,
  CONFLICT = 409,
  INTERNAL_SERVER_ERROR = 500,
  UNDEPLOYED_PIPELINE_ERROR_STATUS = 591,
  BAD_REQUEST = 400,
}

export enum UserRoles {
  ADMIN = 'ADMIN',
  EDITOR = 'EDITOR',
  SEARCH_USER = 'SEARCH_USER',
}

export const UserRolesMap = {
  [UserRoles.ADMIN]: 'Admin',
  [UserRoles.EDITOR]: 'Editor',
  [UserRoles.SEARCH_USER]: 'Search User',
};

export type TagColors = 'default' | 'processing' | 'error' | 'success' | 'warning';

export enum MIMETypes {
  PDF = 'application/pdf',
  CSV = 'text/csv',
  TXT = 'text/plain',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  XML = 'text/xml',
  HTML = 'text/html',
  MD = 'text/markdown',
  JSON = 'application/json',
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  SVG = 'image/svg+xml',
}

export enum DateRangeInterval {
  MINUTE = 'minute',
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export enum SupportedViewerLanguage {
  JSON = 'json',
  YAML = 'yaml',
  MARKDOWN = 'markdown',
  HTML = 'html',
  XML = 'xml',
  PLAIN_TEXT = 'plaintext',
  PYTHON = 'python',
}
