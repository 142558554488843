import { IUserflowState } from '../state/userflowState';

export enum ActionType {
  INITIALISED = 'INITIALISED',
}
type Action = { type: ActionType.INITIALISED };

export const reducer = (state: IUserflowState, action: Action): IUserflowState => {
  switch (action.type) {
    case ActionType.INITIALISED:
      return {
        ...state,
        isInitialized: true,
      };
  }
};
