import React from 'react';
import { Button, Dropdown, Popconfirm } from 'antd';
import { MenuProps } from 'antd/lib/menu';
import MoreOutlined from '@assets/icons/more-outlined.svg?react';
import {
  CANCEL_BUTTON_LABEL,
  CONFIRM_BUTTON_LABEL,
  DELETE_CONFIRMATION_MESSAGE,
} from '@constants/data-page';
import styles from './dataTable.module.scss';

export interface ExtendedMenuProps extends MenuProps {
  'data-testid'?: string;
  ref?: React.RefObject<HTMLButtonElement>;
}

interface IDataTableActionsProps {
  item: string;
  menu: ExtendedMenuProps;
  itemToDelete?: string;
  deleteConfirmationMessage?: string;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  primaryButton?: {
    label: string;
    action: (item: string) => void;
    visible?: boolean;
    loading?: boolean;
    icon?: any;
    danger?: boolean;
  };
  onDelete?: (item: string) => void;
  onCancelDelete?: () => void;
}

const DataTableActions = (props: IDataTableActionsProps) => {
  const {
    menu,
    onDelete,
    onCancelDelete,
    item,
    itemToDelete,
    primaryButton,
    deleteConfirmationMessage = DELETE_CONFIRMATION_MESSAGE,
    confirmButtonLabel = CONFIRM_BUTTON_LABEL,
    cancelButtonLabel = CANCEL_BUTTON_LABEL,
  } = props;
  return (
    <div className={styles.moreActions}>
      {primaryButton && primaryButton.visible !== false && (
        <Button type="link" onClick={() => primaryButton.action(item)} {...primaryButton}>
          {primaryButton.label}
        </Button>
      )}
      <Dropdown menu={menu} placement="bottomRight" trigger={['click']}>
        <Button
          className={styles.moreActions_button}
          type="text"
          icon={<MoreOutlined />}
          data-testid={menu['data-testid']}
          ref={menu.ref}
        />
      </Dropdown>
      {deleteConfirmationMessage && (
        <Popconfirm
          title={deleteConfirmationMessage}
          open={itemToDelete === item}
          placement="bottomRight"
          okText={confirmButtonLabel}
          cancelText={cancelButtonLabel}
          onConfirm={() => onDelete && onDelete(item)}
          onCancel={() => onCancelDelete && onCancelDelete()}
        />
      )}
    </div>
  );
};

export default DataTableActions;
