import React from 'react';
import {
  AppstoreAddOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  DeploymentUnitOutlined,
  FileOutlined,
  ProjectOutlined,
  QuestionCircleOutlined,
  UserOutlined,
} from '@ant-design/icons';
import airbusSVG from '@assets/icons/partners/airbus.svg';
import bafinSVG from '@assets/icons/partners/bafin.svg';
import infineonSVG from '@assets/icons/partners/infineon.svg';
import manzSVG from '@assets/icons/partners/manz.svg';
import siemensSVG from '@assets/icons/partners/siemens.svg';
import ypulseSVG from '@assets/icons/partners/ypulse.svg';

export const UPGRADE_LABEL = 'Upgrade';
export const PREMIUM_LABEL = 'Premium';

// LIMITS
export enum LimitCategory {
  WORKSPACES = 'WORKSPACES',
  PIPELINE_HOURS = 'PIPELINE_HOURS',
  FILES = 'FILES',
  PIPELINES = 'PIPELINES',
  INVITE = 'INVITE',
}

export const FREE_TIER_LIMITS: Record<LimitCategory, number> = {
  WORKSPACES: 1,
  PIPELINE_HOURS: 100,
  FILES: 50,
  PIPELINES: 2,
  INVITE: 0,
};

export const UPGRADE_BANNER_MESSAGE: Record<LimitCategory, string> = {
  WORKSPACES: 'Upgrade to a paid plan to create more workspaces.',
  PIPELINE_HOURS:
    'You’ve reached the limit for pipeline hours. Upgrade to a paid plan to use your pipelines.',
  FILES: 'You’ve reached the limit for file uploads. Upgrade to a paid plan to upload more files.',
  PIPELINES: 'Upgrade your plan to create additional pipelines.',
  INVITE: 'Upgrade to a paid plan to invite more users.',
};

export const TITLE = 'One platform for smart AI management';
export const DESCRIPTION =
  'Streamline your AI workflows with a single, easy-to-use platform. Save time, reduce costs, and get your AI into production faster.';
export const PRICING_PLANS = [
  {
    title: 'Studio',
    description: 'Great for individuals prototyping AI applications',
    price: '$0',
    buttonText: 'Current Plan',
    buttonDisabled: true,
    features: [
      { icon: <AppstoreAddOutlined />, text: '1 workspace' },
      { icon: <UserOutlined />, text: '1 user' },
      { icon: <ClockCircleOutlined />, text: '100 pipeline hours' },
      { icon: <FileOutlined />, text: '50 files (max 10MB per file)' },
      { icon: <ProjectOutlined />, text: '2 development pipelines' },
      { icon: <DeploymentUnitOutlined />, text: 'Cloud deployment' },
      { icon: <QuestionCircleOutlined />, text: 'Community support on Discord' },
    ],
  },
  {
    title: 'Enterprise',
    description: 'Great for teams building production-grade AI applications',
    price: 'Custom',
    buttonText: 'Contact Us',
    buttonDisabled: false,
    features: [
      { icon: <AppstoreAddOutlined />, text: 'Unlimited workspaces' },
      { icon: <UserOutlined />, text: 'Unlimited users' },
      { icon: <ClockCircleOutlined />, text: 'Custom pipeline hours' },
      { icon: <FileOutlined />, text: 'Custom data plan' },
      { icon: <ProjectOutlined />, text: 'Unlimited development pipelines' },
      { icon: <ProjectOutlined />, text: 'Unlimited production (high availability) pipelines' },
      { icon: <DeploymentUnitOutlined />, text: 'Cloud or custom deployment' },
      {
        icon: <QuestionCircleOutlined />,
        text: 'Dedicated account team, solution engineers, and private Slack channel',
      },
    ],
  },
];

export const LOGOS = [
  { src: airbusSVG, alt: 'Airbus' },
  { src: infineonSVG, alt: 'Infineon' },
  { src: siemensSVG, alt: 'Siemens' },
  { src: bafinSVG, alt: 'BaFin' },
  { src: manzSVG, alt: 'Manz' },
  { src: ypulseSVG, alt: 'Pulse' },
];

// FEATURE COMPARISON

export const FEATURE_COMPARISION_TITLE = 'Compare plans and features';
export const FEATURE_COMPARISION_OPTIONS = [
  { label: 'Free', price: '$0', buttonText: 'Current Plan', buttonDisabled: true },
  { label: 'Enterprise', price: 'Custom', buttonText: 'Contact Sales', buttonDisabled: false },
];

export const FEATURE_COMPARISON_SECTIONS = [
  {
    title: 'Usage',
    features: [
      { label: 'Pipeline hours', free: '100 hours', enterprise: 'Custom' },
      { label: 'Data', free: '50 files (max 10MB per file)', enterprise: 'Custom' },
      { label: 'Development pipelines', free: '2', enterprise: 'Unlimited' },
      { label: 'Production pipelines', free: <CloseOutlined />, enterprise: 'Unlimited' },
      { label: 'Workspaces', free: '1', enterprise: 'Unlimited' },
      { label: 'Users', free: '1', enterprise: 'Unlimited' },
    ],
  },
  {
    title: 'Features',
    features: [
      { label: 'Pipeline Builder', free: <CheckOutlined />, enterprise: <CheckOutlined /> },
      { label: 'Prompt Explorer', free: <CloseOutlined />, enterprise: <CheckOutlined /> },
      { label: 'Jobs', free: <CloseOutlined />, enterprise: <CheckOutlined /> },
      {
        label: 'Groundedness Observability',
        free: <CloseOutlined />,
        enterprise: <CheckOutlined />,
      },
      { label: 'Playground', free: <CheckOutlined />, enterprise: <CheckOutlined /> },
      { label: 'Search history', free: '14 days', enterprise: 'Unlimited' },
      { label: 'Pipeline templates', free: 'Basic', enterprise: 'Basic + Premium' },
      { label: 'Component library', free: <CheckOutlined />, enterprise: <CheckOutlined /> },
      { label: 'Custom components', free: <CheckOutlined />, enterprise: <CheckOutlined /> },
      { label: 'Shareable prototypes', free: <CheckOutlined />, enterprise: <CheckOutlined /> },
      { label: 'Feedback collection', free: <CheckOutlined />, enterprise: <CheckOutlined /> },
      { label: 'Integrations', free: <CheckOutlined />, enterprise: <CheckOutlined /> },
      { label: 'API access', free: <CheckOutlined />, enterprise: <CheckOutlined /> },
    ],
  },
  {
    title: 'Infrastructure',
    features: [
      { label: 'Cloud deployment', free: '100 hours', enterprise: 'Custom' },
      {
        label: 'Other deployment options',
        free: <CloseOutlined />,
        enterprise: 'Upon request',
      },
      { label: 'Dedicated infrastructure', free: <CloseOutlined />, enterprise: <CheckOutlined /> },
    ],
  },
  {
    title: 'Other',
    features: [
      {
        label: 'Support',
        free: 'Community on Discord',
        enterprise: 'Dedicated account team, solution engineers and private Slack channel',
      },
      { label: 'SSO (Single Sign On)', free: <CloseOutlined />, enterprise: <CheckOutlined /> },
      {
        label: 'Role based access control',
        free: <CloseOutlined />,
        enterprise: <CheckOutlined />,
      },
      { label: 'Compliance', free: 'SOC 2 / GDPR', enterprise: 'SOC 2 / GDPR' },
    ],
  },
];

// CONFIRM MODAL

export const UPGRADE_CONFIRM_MODAL_TITLE = 'Contact Us';
export const UPGRADE_CONFIRM_MODAL_CONTENT =
  'Thank you for showing an interest in the Enterprise plan. Once you confirm, we will reach out via email to schedule a call and discuss your needs.';
export const UPGRADE_CONFIRM_MODAL_OK_TEXT = 'Confirm';
export const UPGRADE_CONFIRM_MODAL_CANCEL_TEXT = 'Cancel';
export const UPGRADE_CONFIRM_MODAL_SUCCESS_NOTIFICATION =
  'Your interest for the deepset Enterprise plan has been submitted successfully.';
