import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import usePipelineQuery from '@hooks/usePipelineQuery';
import { useScreen } from '@hooks/useScreen';
import { UserRoles } from '@constants/enum/common';
import { PipelineDesiredStatusCodes, PipelineOutputType } from '@constants/pipelines';
import { getPipelineMeta } from '@redux/actions/metadataFiltersActions';
import {
  fetchPipelines,
  stopPollingDeployedPipelines,
  stopPollingPipelineStatus,
} from '@redux/actions/pipelineActions';
import { setSearchPipeline } from '@redux/actions/searchActions';
import { pipelineSelector, pipelinesSelector } from '@redux/selectors/pipelineSelectors';
import { searchMessageSelector, searchPipelineSelector } from '@redux/selectors/searchSelectors';
import { userSelector } from '@redux/selectors/userSelectors';
import {
  DeepsetCloudVersion,
  IAPIPaginationData,
  IMessage,
  IPipeline,
  IUserData,
  MetadataFiltersType,
} from '@redux/types/types';
import ConversationalSearch from '@components/search/organisms/search/ConversationalSearch';
import SearchComponent from '@components/search/organisms/search/search';
import UpgradeBanner from '@modules/Upgrade/components/upgradeBanner/UpgradeBanner';
import { LimitCategory } from '@modules/Upgrade/consts/pricing';
import styles from './searchPage.module.scss';

// TODO: Move search logic to Search component
const SearchPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search, searchResult, searchStatus, resetSearchResults } = usePipelineQuery();
  const searchPipeline = useSelector(searchPipelineSelector);
  const message: IMessage = useSelector(searchMessageSelector);
  const { data: pipelines }: IAPIPaginationData<IPipeline[]> = useSelector((state) =>
    pipelinesSelector(state, PipelineDesiredStatusCodes.DEPLOYED),
  );
  const pipeline: IPipeline = useSelector(pipelineSelector);
  // TOFIX: Temp hack to disable file access to specific org and role https://github.com/deepset-ai/haystack-hub-ui/issues/2432
  const { organization, role }: IUserData = useSelector(userSelector);
  const { isMobileScreen, isTabletScreen } = useScreen();
  const isSmallerScreen = isMobileScreen || isTabletScreen;
  const [filtersVisible, setFiltersVisible] = useState(!isSmallerScreen);
  const { pipelineName } = useParams() as { pipelineName: string };
  const [selectedPipeline, setSelectedPipeline] = useState<IPipeline | undefined>(
    pipelines.find((pipelineItem: IPipeline) => {
      return pipelineItem.name === searchPipeline || pipelineItem.name === pipelineName;
    }),
  );

  const tempDisableMoreActions =
    organization === '111057eb-62eb-4cb0-969f-df7fc1f21b03' && role === UserRoles.SEARCH_USER;

  useEffect(() => {
    resetSearchResults();
    // TODO: Think how we can use pagination in this context
    dispatch(
      fetchPipelines({
        currentPage: 1,
        pageSize: 100,
        searchValue: '',
        desiredStatus: PipelineDesiredStatusCodes.DEPLOYED,
      }),
    );
    document.body.style.overflow = 'auto';

    if (pipelineName) dispatch(setSearchPipeline(pipelineName));

    return () => {
      resetSearchResults();
      dispatch(stopPollingPipelineStatus());
      dispatch(stopPollingDeployedPipelines());
    };
  }, [pipelineName, selectedPipeline?.name]);

  useEffect(() => {
    if (!searchPipeline) {
      // FIXME: Will need rework once we introduce pagination
      // TODO: First use default pipeline, then use first pipeline in the list
      const firstCreatedPipeline = pipelines[pipelines.length - 1];
      if (firstCreatedPipeline) dispatch(setSearchPipeline(firstCreatedPipeline.name));
    }
  }, [dispatch, pipelines, searchPipeline]);

  useEffect(() => {
    if (!selectedPipeline && searchPipeline) {
      dispatch(setSearchPipeline(''));
      if (pipelineName) navigate('/');
    }
  }, [dispatch, pipelines]);

  // Get metadata filters for a specific pipeline selected
  useEffect(() => {
    if (selectedPipeline) dispatch(getPipelineMeta({ pipelineName: selectedPipeline.name }));
  }, [dispatch, selectedPipeline?.name]);

  useEffect(() => {
    if (pipeline.name === selectedPipeline?.name) setSelectedPipeline(pipeline);
  }, [pipeline]);

  useEffect(() => {
    dispatch(stopPollingPipelineStatus());
    setSelectedPipeline(
      pipelines.find((pipelineItem: IPipeline) => {
        return pipelineItem.name === searchPipeline;
      }),
    );
  }, [searchPipeline]);

  const onClickSearch = async (query: string, filters: Record<string, MetadataFiltersType>) => {
    search({
      pipelineName: searchPipeline,
      pipelineOutputType: selectedPipeline?.output_type,
      query,
      filters,
      viewPrompts: selectedPipeline?.supports_prompt,
      isV2: selectedPipeline?.deepset_cloud_version === DeepsetCloudVersion.V2,
    });
  };

  return (
    <>
      <UpgradeBanner category={LimitCategory.PIPELINE_HOURS} />
      <div className={styles.searchPage} data-testid="searchPage" translate="no">
        {selectedPipeline?.output_type !== PipelineOutputType.CHAT ? (
          <SearchComponent
            selectedPipeline={selectedPipeline}
            selectedPipelineName={selectedPipeline?.name || ''}
            handleSearch={onClickSearch}
            searchResult={searchResult}
            searchStatus={searchStatus}
            displayMetaDataFilters
            displayResultsFileOptions={!tempDisableMoreActions}
            displayResultsMoreOptions
            searchFiltersVisible={filtersVisible}
            onSearchFiltersClose={() => setFiltersVisible(false)}
            onToggleFiltersClick={() => setFiltersVisible(!filtersVisible)}
            message={message}
          />
        ) : (
          <ConversationalSearch
            pipelineName={searchPipeline || pipelineName}
            pipeline={selectedPipeline}
            displayMetaDataFilters
            searchFiltersVisible={filtersVisible}
            onSearchFiltersClose={() => setFiltersVisible(false)}
            onToggleFiltersClick={() => setFiltersVisible(!filtersVisible)}
          />
        )}
      </div>
    </>
  );
};

export default SearchPage;
