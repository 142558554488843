import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useNotifications from '@hooks/useNotifications';
import usePipelineActions from '@hooks/usePipelineActions';
import usePipelineFeedback from '@hooks/usePipelineFeedback';
import { PipelineDetailsTabsKeys } from '@constants/pipeline-details';
import {
  DEFAULT_PAGE_NUMBER_PIPELINES_LIST,
  EMPTY_PIPELINES_ALL_TABLE_LABEL,
  EMPTY_PIPELINES_DEVELOPMENT_TABLE_LABEL,
  EMPTY_PIPELINES_DRAFT_TABLE_LABEL,
  EMPTY_PIPELINES_PRODUCTION_TABLE_LABEL,
  MenuActionsPipelinesList,
  PAGE_SIZE_PIPELINES_LIST,
  PipelineStatusCodes,
  TABS_PIPELINES_LIST_KEYS,
  UPGRADE_POPOVER_PIPELINE_CONTENT,
} from '@constants/pipelines';
import {
  EMPTY_YAML_TEMPLATE,
  INDEXING_PIPELINE_EMPTY_YAML_TEMPLATE,
  QUERY_PIPELINE_EMPTY_YAML_TEMPLATE,
} from '@constants/pipelineTemplates';
import { userSelector } from '@redux/selectors/userSelectors';
import {
  DeepsetCloudVersion,
  IPipeline,
  NotificationType,
  PipelineServiceLevel,
} from '@redux/types/types';
import { FREE_TIER_LIMITS } from '@modules/Upgrade/consts/pricing';
import usePipelines from './usePipelines';

const usePipelinesList = () => {
  const navigate = useNavigate();
  const [pipelineToShare, setPipelineToShare] = useState('');
  const [createPipelineFromEmptyYaml, setCreatePipelineFromEmptyYaml] = useState(false);
  const [pipelineToDelete, setPipelineToDelete] = useState<IPipeline | null>(null);
  const [pipelineToUndeploy, setPipelineToUndeploy] = useState<IPipeline | null>(null);
  const [totalSelectedPipelines, setTotalSelectedPipelines] = useState<number>(0);
  const [selectedPipelinesToDelete, setSelectedPipelinesToDelete] = useState<IPipeline[]>([]);
  const [selectedPipelineNamesToDelete, setSelectedPipelineNamesToDelete] = useState<string[]>([]);
  const [openPipelineConfirmationModal, setOpenPipelineConfirmationModal] = useState(false);
  const [pipelineToRename, setPipelineToRename] = useState('');
  const [showRenamePipelineModal, setShowRenamePipelineModal] = useState(false);
  const [resetCurrentPage, setResetCurrentPage] = useState(false);
  const { isLimitedUser } = useSelector(userSelector);

  const {
    deleteMultiplePipelines,
    getPipelinesList,
    activeTabPipelinesLandingPage,
    setActiveTabPipelinesList,
    totalPipelines,
  } = usePipelines();

  const {
    createPipeline,
    deletePipeline,
    undeployPipeline,
    duplicatePipelineV1,
    duplicatePipelineV2,
    pipelineLogsUnhealthy,
    setActiveTabPipelineDetailsPage,
    resetRenamePipelineNameStatus,
    exportPipelineDocuments,
  } = usePipelineActions();

  const { exportPipelineFeedbackCSV } = usePipelineFeedback();
  const { addNotification } = useNotifications();

  const handleDeletePipeline = (pipeline: IPipeline) => {
    setPipelineToDelete(pipeline);
    setOpenPipelineConfirmationModal(true);
  };

  const onUndeployPipeline = async (pipeline: IPipeline) => {
    setPipelineToUndeploy(pipeline);
    setOpenPipelineConfirmationModal(true);
  };

  const handleRenamePipeline = (pipelineName: string) => {
    setPipelineToRename(pipelineName);
    setShowRenamePipelineModal(true);
  };

  const goToStudio = (pipelineName: string) => {
    navigate(`/pipelines/${pipelineName}/builder`);
  };

  const handleMoreActionClick = async (key: string, pipelineName: string, pipeline: IPipeline) => {
    if (key === MenuActionsPipelinesList.Details) navigate(`/pipelines/${pipelineName}`);
    if (key === MenuActionsPipelinesList.Observability) navigate(`/groundedness/${pipelineName}`);
    if (key === MenuActionsPipelinesList.Edit) {
      if (pipeline.deepset_cloud_version === DeepsetCloudVersion.V1)
        navigate(`/pipelines/designer/${pipelineName}`);
      else if (pipeline.deepset_cloud_version === DeepsetCloudVersion.V2)
        navigate(`/pipelines/editor/${pipelineName}`);
    }
    if (key === MenuActionsPipelinesList.PromptExplorer)
      navigate(`/prompt-explorer/${pipelineName}`);
    if (key === MenuActionsPipelinesList.Delete) handleDeletePipeline(pipeline);
    if (key === MenuActionsPipelinesList.Duplicate) {
      if (isLimitedUser && totalPipelines >= FREE_TIER_LIMITS.PIPELINES) {
        addNotification({
          content: UPGRADE_POPOVER_PIPELINE_CONTENT,
          type: NotificationType.Warning,
        });
        return;
      }
      if (pipeline.deepset_cloud_version === DeepsetCloudVersion.V1)
        duplicatePipelineV1(pipelineName);
      else if (pipeline.deepset_cloud_version === DeepsetCloudVersion.V2)
        duplicatePipelineV2(pipelineName);
    }
    if (key === MenuActionsPipelinesList.ExportFeedbackCSV)
      exportPipelineFeedbackCSV(pipeline.pipeline_id, pipelineName);
    if (key === MenuActionsPipelinesList.Undeploy) onUndeployPipeline(pipeline);
    if (key === MenuActionsPipelinesList.RenamePipeline) handleRenamePipeline(pipelineName);
    if (key === MenuActionsPipelinesList.Studio) goToStudio(pipelineName);
    if (key === MenuActionsPipelinesList.ExportDocuments)
      exportPipelineDocuments({ name: pipelineName, status: pipeline.status });
  };

  const getOnCancelPipelineConfirmationModal = () => {
    setOpenPipelineConfirmationModal(false);
    setPipelineToDelete(null);
    setPipelineToUndeploy(null);
    setSelectedPipelinesToDelete([]);
    setSelectedPipelineNamesToDelete([]);
  };

  const getOnOkPipelineConfirmationModal = async () => {
    if (pipelineToDelete) await deletePipeline(pipelineToDelete.name);
    else if (pipelineToUndeploy) await undeployPipeline(pipelineToUndeploy.name);
    else if (selectedPipelineNamesToDelete.length)
      await deleteMultiplePipelines(selectedPipelineNamesToDelete);

    getOnCancelPipelineConfirmationModal();
  };

  const getPipelinesForConfirmationModal = () => {
    if (selectedPipelinesToDelete.length > 0) return selectedPipelinesToDelete;
    if (pipelineToDelete) return [pipelineToDelete];
    if (pipelineToUndeploy) return [pipelineToUndeploy];
    return [];
  };

  const onChangeTab = async (key: string) => {
    setResetCurrentPage(true);
    if (key === TABS_PIPELINES_LIST_KEYS.ALL) await setActiveTabPipelinesList('');
    else await setActiveTabPipelinesList(key);
    setResetCurrentPage(false);
  };

  const onAfterPipelineCreated = () => {
    setShowRenamePipelineModal(false);
    setCreatePipelineFromEmptyYaml(false);
    setActiveTabPipelinesList('');
    getPipelinesList(
      DEFAULT_PAGE_NUMBER_PIPELINES_LIST,
      PAGE_SIZE_PIPELINES_LIST,
      '',
      PipelineServiceLevel.DRAFT,
    );
  };

  const onAfterPipelineRenamed = () => {
    resetRenamePipelineNameStatus();
    setShowRenamePipelineModal(false);
  };

  const onCloseNamePipelineModal = () => {
    setShowRenamePipelineModal(false);
    setCreatePipelineFromEmptyYaml(false);
  };

  const onGotToLogs = (pipelineName: string) => {
    setActiveTabPipelineDetailsPage(PipelineDetailsTabsKeys.LOGS);
    navigate(`/pipelines/${pipelineName}`);
  };

  const handleMenuCreatePipelineClick = () => {
    navigate('/pipelines/templates');
  };

  const onDeleteMultiplePipelines = async (pipelinesList: IPipeline[], pipelineNames: string[]) => {
    const selectedPipelines = pipelineNames.map((name) =>
      pipelinesList.find((p: IPipeline) => p.name === name),
    ) as IPipeline[];
    if (selectedPipelines)
      setSelectedPipelinesToDelete([...selectedPipelines.map((p) => p).filter(Boolean)]);
    setOpenPipelineConfirmationModal(true);
    setSelectedPipelineNamesToDelete(pipelineNames);
  };

  const createPipelineFromEmptyYamlHandler = (
    pipelineName: string,
    deepsetCloudVersion?: DeepsetCloudVersion,
  ) => {
    let payload = {};

    if (deepsetCloudVersion) {
      if (deepsetCloudVersion === DeepsetCloudVersion.V1)
        payload = {
          code: EMPTY_YAML_TEMPLATE,
          deepsetCloudVersion,
        };
      else if (deepsetCloudVersion === DeepsetCloudVersion.V2)
        payload = {
          deepsetCloudVersion,
          // TODO: Add values once the templates are ready
          indexingYaml: INDEXING_PIPELINE_EMPTY_YAML_TEMPLATE,
          queryYaml: QUERY_PIPELINE_EMPTY_YAML_TEMPLATE,
        };
    } else payload = { code: EMPTY_YAML_TEMPLATE };

    createPipeline({ ...payload, pipelineName });
  };

  const isPipelineUnhealthy = (pipeline: IPipeline) => {
    const { indexing, name, status: pipelineStatus } = pipeline;

    if (
      pipelineStatus === PipelineStatusCodes.DEPLOYED &&
      indexing.failed_file_count === 0 &&
      indexing.pending_file_count === 0 &&
      pipelineLogsUnhealthy[name]
    )
      return true;
    return false;
  };

  const getEmptyLabelForTable = () => {
    if (activeTabPipelinesLandingPage === TABS_PIPELINES_LIST_KEYS.PRODUCTION)
      return EMPTY_PIPELINES_PRODUCTION_TABLE_LABEL;
    if (activeTabPipelinesLandingPage === TABS_PIPELINES_LIST_KEYS.DEVELOPMENT)
      return EMPTY_PIPELINES_DEVELOPMENT_TABLE_LABEL;
    if (activeTabPipelinesLandingPage === TABS_PIPELINES_LIST_KEYS.DRAFT)
      return EMPTY_PIPELINES_DRAFT_TABLE_LABEL;
    return EMPTY_PIPELINES_ALL_TABLE_LABEL;
  };

  return {
    onChangeTab,
    handleMoreActionClick,
    pipelineToShare,
    setPipelineToShare,
    createPipelineFromEmptyYaml,
    setCreatePipelineFromEmptyYaml,
    pipelineToDelete,
    setPipelineToDelete,
    setPipelineToUndeploy,
    pipelineToUndeploy,
    totalSelectedPipelines,
    setTotalSelectedPipelines,
    selectedPipelinesToDelete,
    setSelectedPipelinesToDelete,
    selectedPipelineNamesToDelete,
    setSelectedPipelineNamesToDelete,
    openPipelineConfirmationModal,
    setOpenPipelineConfirmationModal,
    getOnOkPipelineConfirmationModal,
    getOnCancelPipelineConfirmationModal,
    getPipelinesForConfirmationModal,
    onUndeployPipeline,
    pipelineToRename,
    setPipelineToRename,
    showRenamePipelineModal,
    setShowRenamePipelineModal,
    onAfterPipelineCreated,
    onAfterPipelineRenamed,
    onCloseNamePipelineModal,
    onGotToLogs,
    handleMenuCreatePipelineClick,
    onDeleteMultiplePipelines,
    createPipelineFromEmptyYamlHandler,
    isPipelineUnhealthy,
    resetCurrentPage,
    getEmptyLabelForTable,
  };
};

export default usePipelinesList;
