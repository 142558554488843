import { useDispatch, useSelector } from 'react-redux';
import { UserRoles } from '@constants/enum/common';
import { fetchPipelines } from '@redux/actions/pipelineActions';
import {
  getAllTimeUsageMetricValue,
  getUsageLimits,
  getUsageMetricValue,
} from '@redux/actions/usageActions';
import { pipelinesSelector } from '@redux/selectors/pipelineSelectors';
import {
  allTimeUsageMetricsSelector,
  usageLimitsSelector,
  usageMetricsSelector,
} from '@redux/selectors/usageSelectors';
import { userSelector } from '@redux/selectors/userSelectors';
import useUsageDateRange from './useUsageDateRange';

const useMeteringUsage = () => {
  const dispatch = useDispatch();
  const { selectedDateRange } = useUsageDateRange();
  const { role } = useSelector(userSelector);
  const usageMetrics = useSelector(usageMetricsSelector);
  const allTimeUsageMetrics = useSelector(allTimeUsageMetricsSelector);
  const usageLimits = useSelector(usageLimitsSelector);
  // TODO: Move to usage reducer
  const { total: totalPipelines } = useSelector((state) => pipelinesSelector(state, ''));

  const fetchUsageMetrics = () => {
    if (role !== UserRoles.ADMIN) return;

    const dateRangeValues = {
      from: selectedDateRange?.from,
      to: selectedDateRange?.to,
    };
    dispatch(
      fetchPipelines({
        currentPage: 1,
        pageSize: 1,
        searchValue: '',
      }),
    );
    dispatch(getUsageLimits());
    dispatch(getUsageMetricValue({ dateRange: dateRangeValues }));
  };

  const fetchAllTimeUsageMetrics = () => {
    if (role !== UserRoles.ADMIN) return;

    dispatch(getUsageLimits());
    dispatch(getAllTimeUsageMetricValue());
  };

  return {
    usageMetrics,
    allTimeUsageMetrics,
    usageLimits,
    totalPipelines,
    fetchUsageMetrics,
    fetchAllTimeUsageMetrics,
  };
};

export default useMeteringUsage;
