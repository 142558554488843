import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CopyOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Alert,
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { interpolateString } from '@utils/string';

import { CANCEL_BUTTON_LABEL } from '@constants/common';
import {
  ADD_ANOTHER_KEY_BUTTON_LABEL,
  ADD_NEW_KEY_BUTTON_LABEL,
  API_ID_LABEL,
  API_KEY_LABEL,
  API_KEY_PLACEHOLDER,
  API_KEYS_HEADER,
  CANCEL_CREATE_BUTTON_LABEL,
  CLOSE_BUTTON_LABEL,
  CONNECT_BUTTON_LABEL,
  CONNECT_MODAL_SUBTITLE,
  CONNECT_MODAL_TITLE,
  CONNECTIONS_CONFIG,
  COPY_TOOLTIP,
  CREATED_BY_LABEL,
  DELETE_BUTTON_LABEL,
  DELETE_CONFIRMATION_MESSAGE,
  DISCONNECT_BUTTON_LABEL,
  DISCONNECT_CONFIRMATION_MESSAGE,
  DISCONNECT_CONFIRMATION_TITLE,
  EXPIRATION_DATEPICKER_LABEL,
  EXPIRES_LABEL,
  GENERATE_KEY_BUTTON_LABEL,
  GENERATE_KEY_INFO_LABEL,
  GENERATE_KEY_TITLE,
  INTEGRATION_TITLE,
  MODEL_PROVIDER_LABEL_CONNECTED,
  MODEL_PROVIDER_LABEL_INVALID,
  MODEL_PROVIDER_LABEL_NO_CONNECTED,
  MODEL_PROVIDERS_NAMES_MAPPED,
  NEW_API_KEY_HEADER,
  NEW_API_KEY_INFO,
  POP_CANCEL_BUTTON_LABEL,
  POP_CONFIRM_BUTTON_LABEL,
} from '@constants/settings-page';
import {
  deleteAPIKey,
  deleteModelRegistryToken,
  fetchAPIKeys,
  fetchModelRegistryTokens,
  generateAPIKey,
  postModelRegistryToken,
  resetMessage,
} from '@redux/actions/organizationActions';
import {
  organizationConnectionSelector,
  organizationMessageSelector,
} from '@redux/selectors/organizationSelectors';
import {
  DeepsetCloudVersion,
  IAPIKey,
  IMessage,
  IModelRegistryToken,
  ModelProviders,
} from '@redux/types/types';
import PipelineDeepsetCloudVersionTag from '@components/pipelines/pipelineDeepsetCloudVersionTag/PipelineDeepsetCloudVersionTag';
import TopNavigation from '@components/settings/TopNavigation';
import styles from './connectionsPage.module.scss';

const { Option } = Select;

const RenderIntegration = ({
  logo,
  provider,
  formItems,
  label,
  labelConnected,
  tokenFormatter,
  supportedVersions,
}: {
  logo: { src: string; description: string };
  provider: ModelProviders;
  formItems: any[];
  label?: string;
  labelConnected?: string;
  tokenFormatter?: (values: any) => string;
  supportedVersions?: string[];
}) => {
  const dispatch = useDispatch();
  const { modelRegistryTokens } = useSelector(organizationConnectionSelector);
  const message: IMessage = useSelector(organizationMessageSelector);
  const [confirmationModal, confirmationModalContext] = Modal.useModal();

  const getModelProviderToken = (tokens: IModelRegistryToken[]) => {
    if (!tokens || tokens.length < 1) return null;

    return modelRegistryTokens.find((token: IModelRegistryToken) => token.provider === provider);
  };

  const getTokenDescription = (existingToken: IModelRegistryToken) => {
    if (!existingToken) {
      if (label) return label;
      return interpolateString(MODEL_PROVIDER_LABEL_NO_CONNECTED, {
        provider: MODEL_PROVIDERS_NAMES_MAPPED[provider],
        private: provider === ModelProviders.huggingface ? 'private ' : '',
      });
    }
    if (existingToken.invalid) {
      return (
        <Tag icon={<ExclamationCircleOutlined />} color="warning">
          {interpolateString(MODEL_PROVIDER_LABEL_INVALID, {
            provider: MODEL_PROVIDERS_NAMES_MAPPED[provider],
            private: provider === ModelProviders.huggingface ? 'private ' : '',
          })}
        </Tag>
      );
    }
    if (labelConnected) return labelConnected;

    return interpolateString(MODEL_PROVIDER_LABEL_CONNECTED, {
      provider: MODEL_PROVIDERS_NAMES_MAPPED[provider],
      private: provider === ModelProviders.huggingface ? 'private ' : '',
    });
  };

  const existingToken = getModelProviderToken(modelRegistryTokens);
  const [token, setToken] = useState(existingToken);
  const [modalVisible, setModalVisible] = useState(false);

  const onFinish = async (values: any) => {
    let formattedValues = values;
    if (tokenFormatter) {
      formattedValues = tokenFormatter(values);
    } else if (values.token) {
      formattedValues = values.token;
    }

    await dispatch(
      postModelRegistryToken({ modelToken: formattedValues, modelProvider: provider }),
    );
    dispatch(fetchModelRegistryTokens());
  };

  const handleDisconnect = async () => {
    await dispatch(deleteModelRegistryToken(provider));
    setToken(null);
  };

  const handleCancelModal = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    const currentToken = getModelProviderToken(modelRegistryTokens);
    if (currentToken) {
      setModalVisible(false);
      setToken(currentToken);
    } else {
      setToken(null);
    }
  }, [modelRegistryTokens]);

  useEffect(() => {
    if (!modalVisible) dispatch(resetMessage);
  }, [modalVisible]);

  return (
    <>
      <div className={styles.integration}>
        <div className={styles.integration_input}>
          <img src={logo.src} alt={logo.description} className={styles.integration_logo} />
          <div className={styles.integration_input_label}>
            <div>
              <span className={styles.integration_input_label_title}>
                {MODEL_PROVIDERS_NAMES_MAPPED[provider]}
              </span>
            </div>
            <small>{getTokenDescription(existingToken)}</small>
          </div>
        </div>
        {token ? (
          <Button
            danger
            onClick={async () => {
              const confirmed = await confirmationModal.confirm({
                title: interpolateString(DISCONNECT_CONFIRMATION_TITLE, {
                  provider: MODEL_PROVIDERS_NAMES_MAPPED[provider],
                }),
                content: interpolateString(DISCONNECT_CONFIRMATION_MESSAGE, {
                  provider: MODEL_PROVIDERS_NAMES_MAPPED[provider],
                }),
                okText: DISCONNECT_BUTTON_LABEL,
                okButtonProps: {
                  danger: true,
                },
                centered: true,
              });
              if (confirmed) handleDisconnect();
            }}
          >
            {DISCONNECT_BUTTON_LABEL}
          </Button>
        ) : (
          <Button onClick={() => setModalVisible(true)}>{CONNECT_BUTTON_LABEL}</Button>
        )}
      </div>
      <Modal
        title={interpolateString(CONNECT_MODAL_TITLE, {
          provider: MODEL_PROVIDERS_NAMES_MAPPED[provider],
        })}
        open={modalVisible}
        footer={null}
        classNames={{
          body: styles.integrationModal_body,
        }}
        onCancel={handleCancelModal}
        centered
      >
        {message && message.content && (
          <Alert message={message.content} type={message.type} banner />
        )}
        <Form name={provider} layout="vertical" autoComplete="off" onFinish={onFinish}>
          <div className={styles.integrationModal_form}>
            {supportedVersions && supportedVersions.length > 0 && (
              <div className={styles.integrationModal_form_subtitle}>
                {interpolateString(CONNECT_MODAL_SUBTITLE, {
                  provider: (
                    <span className={styles.integrationModal_form_subtitle_connectionName}>
                      {MODEL_PROVIDERS_NAMES_MAPPED[provider]}
                    </span>
                  ),
                  version: (
                    <span className={styles.integrationModal_form_subtitle_version}>
                      {supportedVersions.map((version) => (
                        <PipelineDeepsetCloudVersionTag
                          key={version}
                          deepsetCloudVersion={version as DeepsetCloudVersion}
                        />
                      ))}
                    </span>
                  ),
                })}
              </div>
            )}
            {formItems.map((item) => (
              <Form.Item
                key={item.name}
                label={item.label}
                name={item.name}
                rules={item.rules}
                initialValue={item.defaultValue}
              >
                {item.options ? (
                  <Select placeholder={item.placeholder}>
                    {item.options.map((option: { value: string; label: string }) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                ) : (
                  <Input placeholder={item.placeholder} />
                )}
              </Form.Item>
            ))}
          </div>
          <Divider />
          <Form.Item className={styles.form_actions}>
            <Button htmlType="reset" onClick={handleCancelModal}>
              {CANCEL_BUTTON_LABEL}
            </Button>
            <Button type="primary" htmlType="submit">
              {CONNECT_BUTTON_LABEL}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      {confirmationModalContext}
    </>
  );
};

const ConnectionsPage = () => {
  const dispatch = useDispatch();
  const { apiKeys } = useSelector(organizationConnectionSelector);
  const [currentPage, setCurrentPage] = useState(1);
  const [APIModalVisible, setAPIModalVisible] = useState(false);
  const [newAPIKey, setNewAPIKey] = useState('');
  const [expiryDate, setExpiryDate] = useState('');

  const pageSize = 5;

  useEffect(() => {
    dispatch(resetMessage);
    dispatch(fetchAPIKeys({ currentPage, pageSize }));
    dispatch(fetchModelRegistryTokens());
  }, [currentPage, dispatch]);

  // Check if the last item deleted on the table
  useEffect(() => {
    if (apiKeys.total > 0 && apiKeys.data.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [apiKeys.data.length, apiKeys.total]);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  const onDeleteAPIKey = async (id: string) => {
    await dispatch(deleteAPIKey(id));
    dispatch(fetchAPIKeys({ currentPage, pageSize }));
  };

  const onGenerateAPIKey = async () => {
    const resultAction = await dispatch(generateAPIKey(expiryDate));
    const apiKey = unwrapResult(resultAction as any);
    setNewAPIKey(apiKey);
    dispatch(fetchAPIKeys({ currentPage, pageSize }));
  };

  const onRegenerateAPIKey = () => {
    setNewAPIKey('');
    setExpiryDate('');
  };

  const onCopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  const onDatePick = (value: Dayjs | null) => {
    if (value) setExpiryDate(value.format());
    else setExpiryDate('');
    setNewAPIKey('');
  };

  const showModal = () => {
    setAPIModalVisible(true);
    setNewAPIKey('');
  };

  const handleCancel = () => {
    setAPIModalVisible(false);
    setNewAPIKey('');
  };

  const columns = [
    {
      title: API_ID_LABEL,
      dataIndex: 'api_token_id',
      key: 'api_token_id',
    },
    {
      title: API_KEY_LABEL,
      dataIndex: 'api_key',
      key: 'api_key',
    },
    {
      title: EXPIRES_LABEL,
      dataIndex: 'expires_at',
      key: 'expires_at',
      render: (expires_at: string) => new Date(expires_at).toLocaleString(),
    },
    {
      title: CREATED_BY_LABEL,
      dataIndex: 'user',
      key: 'user',
      render: (user: { given_name: string; family_name: string }) => (
        <>
          {user.given_name} {user.family_name}
        </>
      ),
    },
    {
      key: 'action',
      width: 100,
      render: (text: any, record: any) => (
        <Popconfirm
          title={DELETE_CONFIRMATION_MESSAGE}
          placement="left"
          onConfirm={() => onDeleteAPIKey(record.api_token_id)}
          okText={POP_CONFIRM_BUTTON_LABEL}
          cancelText={POP_CANCEL_BUTTON_LABEL}
        >
          <Button type="link" danger>
            {DELETE_BUTTON_LABEL}
          </Button>
        </Popconfirm>
      ),
    },
  ];

  const keys = apiKeys
    ? apiKeys.data.map((key: IAPIKey) => ({ ...key, api_key: API_KEY_PLACEHOLDER }))
    : [];

  return (
    <div className="content-wrapper">
      <TopNavigation />
      <div className={styles.container}>
        <h5 className={styles.header}>{INTEGRATION_TITLE}</h5>
        <section className={styles.integrations}>
          {CONNECTIONS_CONFIG.map((connection) => (
            <RenderIntegration
              key={connection.provider}
              logo={connection.logo}
              provider={connection.provider}
              formItems={connection.formItems}
              label={connection.label}
              labelConnected={connection.labelConnected}
              tokenFormatter={connection.tokenFormatter}
              supportedVersions={connection.supportedVersions}
            />
          ))}
        </section>

        <h5 className={styles.header}>{API_KEYS_HEADER}</h5>
        <section className={styles.apiKeys}>
          <Table
            columns={columns}
            dataSource={keys}
            className={styles.apiKeys_table}
            rowKey="api_token_id"
            sortDirections={['descend']}
            pagination={{
              total: apiKeys.total,
              position: ['bottomRight'],
              pageSize,
              onChange: onPageChange,
            }}
          />
          <Button
            icon={<PlusOutlined />}
            onClick={() => showModal()}
            className={`${
              apiKeys.total === 0 ? styles.apiKeys_generate_empty : styles.apiKeys_generate
            } `}
          >
            {ADD_NEW_KEY_BUTTON_LABEL}
          </Button>
        </section>

        <Modal
          title={GENERATE_KEY_TITLE}
          open={APIModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <div className={styles.generateKey}>
            {newAPIKey ? (
              <section className={styles.keyContainer}>
                <div>
                  <h3>{NEW_API_KEY_HEADER}</h3>
                  <p className={styles.keyContainer_key}>
                    {newAPIKey}
                    <Tooltip title={COPY_TOOLTIP} trigger="click" className={styles.copyButton}>
                      <Button
                        type="link"
                        size="large"
                        icon={<CopyOutlined />}
                        onClick={() => onCopyToClipboard(newAPIKey)}
                      />
                    </Tooltip>
                  </p>
                </div>
                <p className={styles.keyContainer_info}>{NEW_API_KEY_INFO}</p>
              </section>
            ) : (
              <>
                <p className={styles.generateKey_info}>{GENERATE_KEY_INFO_LABEL}</p>
                <label htmlFor="apikey_expirationDate">
                  {EXPIRATION_DATEPICKER_LABEL}
                  <DatePicker
                    id="apikey_expirationDate"
                    className={styles.datePicker}
                    showToday={false}
                    onChange={onDatePick}
                    disabledDate={(current) => {
                      return current && current < dayjs().endOf('day');
                    }}
                  />
                </label>
              </>
            )}
          </div>
          <div className={styles.modal_buttons}>
            {newAPIKey ? (
              <>
                <Button type="default" onClick={() => onRegenerateAPIKey()}>
                  {ADD_ANOTHER_KEY_BUTTON_LABEL}
                </Button>
                <Button type="primary" onClick={() => handleCancel()}>
                  {CLOSE_BUTTON_LABEL}
                </Button>
              </>
            ) : (
              <>
                <Button type="default" onClick={() => handleCancel()}>
                  {CANCEL_CREATE_BUTTON_LABEL}
                </Button>
                <Button
                  type="primary"
                  onClick={() => onGenerateAPIKey()}
                  disabled={!expiryDate || !!newAPIKey}
                >
                  {GENERATE_KEY_BUTTON_LABEL}
                </Button>
              </>
            )}
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ConnectionsPage;
