import { useSelector } from 'react-redux';
import { UserRoles } from '@constants/enum/common';
import { IWorkspaceItem } from '@redux/reducers/organizationReducer';
import { workspaceSelector } from '@redux/selectors/organizationSelectors';
import { userSelector } from '@redux/selectors/userSelectors';
import { IUserData } from '@redux/types/types';

export const useUser = () => {
  const user: IUserData = useSelector(userSelector);
  const { workspaces: allWorkspaces }: { workspaces: IWorkspaceItem[] } =
    useSelector(workspaceSelector);

  const hasSomeWorkpaceAccess = (role: UserRoles) => {
    if (user.role === UserRoles.ADMIN) return true;
    return user.workspaces.some((workspace) => workspace.role === role);
  };

  const hasWorkspaceAccess = (role: UserRoles, workspaceName: string) => {
    if (user.role === UserRoles.ADMIN) return true;
    const workspace = user.workspaces.find((w) => w.name === workspaceName);
    return workspace?.role === role;
  };

  const getAccessibleWorkspaces = () => {
    if (user.role === UserRoles.ADMIN) return allWorkspaces;
    const accessibleWorkspaces = allWorkspaces.filter((workspace: IWorkspaceItem) =>
      user.workspaces.some((w) => w.name === workspace.name),
    );
    return accessibleWorkspaces;
  };

  return { user, hasSomeWorkpaceAccess, hasWorkspaceAccess, getAccessibleWorkspaces };
};
