import React, { Fragment, ReactElement, Suspense } from 'react';
import UpgradeButton from '@modules/Upgrade/components/upgradeButton/UpgradeButton';
import UpgradeTag from '@modules/Upgrade/components/upgradeTag/UpgradeTag';
import styles from './emptyPageDescription.module.scss';

interface EmptyPageDescriptionProps {
  title: string;
  description: { paragraph: string | ReactElement }[];
  image: ReactElement;
  action?: ReactElement;
  limited?: boolean;
}

const EmptyPageDescription = ({
  title,
  description,
  image,
  action,
  limited,
}: EmptyPageDescriptionProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <div className={styles.textContainer}>
          <h4 className={styles.title}>
            {title}
            {limited && <UpgradeTag isPremium />}
          </h4>
          {description.map(({ paragraph }, idx) => (
            <Fragment key={`${String(paragraph)}-${idx}`}>
              <div>{paragraph}</div>
              {idx !== description.length - 1 && <br />}
            </Fragment>
          ))}
        </div>
        <div className={styles.actionContainer}>{limited ? <UpgradeButton /> : action}</div>
        <div className={styles.imageContainer}>
          <Suspense>{image}</Suspense>
        </div>
      </div>
    </div>
  );
};

export default EmptyPageDescription;
