import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { message, MessageArgsProps, notification, NotificationArgsProps } from 'antd';
import {
  notificationsSelector,
  sequentialNotificationsSelector,
} from '@redux/selectors/notificationsSelectors';
import { INotification, NotificationMode, NotificationType } from '@redux/types/types';
import MarkdownViewer from '@components/MarkdownViewer/MarkdownViewer';
import styles from './notificationCenter.module.scss';

// TODO: Add a proper queue system for notifications
const NotificationCenter = () => {
  const notifications: INotification[] = useSelector(notificationsSelector);
  const sequentialNotifications: INotification[] = useSelector(sequentialNotificationsSelector);
  const [messageApi, messageContextHolder] = message.useMessage();
  const [notificationApi, notificationContextHolder] = notification.useNotification();
  const currentDisplayRef = useRef<{ key: string | null; mode: NotificationMode | null }>({
    key: null,
    mode: null,
  });

  const getDisplayConfig = (item: INotification): MessageArgsProps | NotificationArgsProps => {
    const { key, mode, type, title, content, duration } = item;
    return mode === NotificationMode.Notification
      ? {
          key,
          type:
            (type === NotificationType.Loading ? NotificationType.Info : type) ||
            NotificationType.Info,
          message: title,
          description: <MarkdownViewer>{content}</MarkdownViewer>,
          duration,
          placement: 'bottomRight',
        }
      : {
          key,
          type: type || NotificationType.Info,
          content: (
            <div data-testid="message-container">
              <MarkdownViewer>{item.content}</MarkdownViewer>
            </div>
          ),
          duration,
          className: styles.messageContainer,
        };
  };

  const displayNotificationOrMessage = (
    mode: NotificationMode,
    config: NotificationArgsProps | MessageArgsProps,
  ) => {
    if (mode === NotificationMode.Notification)
      notificationApi.open(config as NotificationArgsProps);
    else messageApi.open(config as MessageArgsProps);
  };

  const showNotificationOrMessage = (item: INotification) => {
    const { mode = NotificationMode.Message } = item;
    const displayConfig = getDisplayConfig(item);

    displayNotificationOrMessage(mode, displayConfig);
  };

  const showSequentialNotificationOrMessage = (item: INotification) => {
    const key = item.key || Date.now().toString();
    const { mode = NotificationMode.Message } = item;

    const displayConfig = getDisplayConfig({ ...item, key });

    currentDisplayRef.current = { key, mode };

    displayNotificationOrMessage(mode, displayConfig);
  };

  const dismissCurrent = () => {
    const { key, mode } = currentDisplayRef.current;
    if (key) {
      if (mode === NotificationMode.Notification) {
        notificationApi.destroy(key);
      } else {
        messageApi.destroy(key);
      }
      currentDisplayRef.current = { key: null, mode: null };
    }
  };

  useEffect(() => {
    if (sequentialNotifications.length > 0) {
      const latestItem = sequentialNotifications[sequentialNotifications.length - 1];
      const latestKey = latestItem.key || Date.now().toString();

      if (currentDisplayRef.current.key !== latestKey) {
        dismissCurrent();
        showSequentialNotificationOrMessage({
          ...latestItem,
          key: latestKey,
        });
      }
    }
  }, [sequentialNotifications]);

  useEffect(() => {
    const latestNotification = notifications[notifications.length - 1];
    if (!latestNotification) return;

    showNotificationOrMessage(latestNotification);
  }, [notifications]);

  return (
    <>
      {messageContextHolder} {notificationContextHolder}
    </>
  );
};

export default NotificationCenter;
