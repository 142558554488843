import { useMemo } from 'react';
import { WHITE_LABEL_ORGS } from '@constants/white-label';

interface UseWhiteLabelProps {
  orgId?: string;
}

export const useWhiteLabel = ({ orgId }: UseWhiteLabelProps) => {
  const whiteLabelConfig = useMemo(() => {
    if (!orgId) return null;
    return WHITE_LABEL_ORGS[orgId] || null;
  }, [orgId]);

  const updateDocumentMetadata = () => {
    if (!whiteLabelConfig) return;

    const favicon = document.querySelector<HTMLLinkElement>('link[rel="icon"]');
    if (favicon) favicon.href = whiteLabelConfig.favicon;

    document.title = whiteLabelConfig.title;
  };

  return {
    isWhiteLabeled: !!whiteLabelConfig,
    whiteLabelAssets: whiteLabelConfig,
    updateDocumentMetadata,
  };
};
