import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { useUser } from '@hooks/useUser';
import { UserRoles } from '@src/constants/enum/common';

interface PrivateRouteProps {
  component: React.ComponentType;
  roles: Array<UserRoles>;
}

const PrivateRoute = ({ component, roles, ...args }: PrivateRouteProps) => {
  const {
    user: { role: topLevelRole },
    hasSomeWorkpaceAccess,
  } = useUser();
  const { user } = useAuth0();

  // Top level roles, should be only used by Admin
  // It might be deprecated in the future
  const hasAccess = roles.some(
    (role) => topLevelRole === UserRoles.ADMIN || hasSomeWorkpaceAccess(role),
  );

  if (!user || !hasAccess) return <Navigate to="/login" />;

  const AuthenticatedComponent = withAuthenticationRequired(component, args);

  return <AuthenticatedComponent />;
};

export default PrivateRoute;
