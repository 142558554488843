import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@redux/store';

export const usageStoreSelector = (state: RootState) => state.usageStore;

const getUsageLimits = (state: RootState) => state.usageStore.usageLimits;
const getAllTimeUsageMetrics = (state: RootState) => state.usageStore.allTimeUsageMetrics;
const getUsageMetrics = (state: RootState) => state.usageStore.usageMetrics;
const getPipelineUsageHours = (state: RootState) => state.usageStore.pipelineUsageHours;
const getPipelineUsageMetrics = (state: RootState) => state.usageStore.pipelineUsageMetrics;
const getFetchingPipelineUsageMetricsStatus = (state: RootState) =>
  state.usageStore.fetchingPipelineUsageMetricsStatus;
const getSelectedDateRange = (state: RootState) => state.usageStore.selectedDateRange;
const getPipelineUsageMetricsSortValue = (state: RootState) =>
  state.usageStore.pipelineUsageMetricsSortValue;
const getPipelineUsageMetricsFiltersValues = (state: RootState) =>
  state.usageStore.pipelineUsageMetricsFiltersValues;

export const usageLimitsSelector = createSelector([getUsageLimits], (usageLimits) => usageLimits);

export const allTimeUsageMetricsSelector = createSelector(
  [getAllTimeUsageMetrics],
  (allTimeUsageMetrics) => allTimeUsageMetrics,
);

export const usageMetricsSelector = createSelector(
  [getUsageMetrics],
  (usageMetrics) => usageMetrics,
);

export const pipelineHoursUsageSelector = createSelector(
  [getPipelineUsageHours],
  (pipelineUsageHours) => pipelineUsageHours,
);

export const pipelineUsageMetricsSelector = createSelector(
  [getPipelineUsageMetrics],
  (pipelineUsageMetrics) => pipelineUsageMetrics,
);

export const fetchingPipelineUsageMetricsStatusSelector = createSelector(
  [getFetchingPipelineUsageMetricsStatus],
  (fetchingPipelineUsageMetricsStatus) => fetchingPipelineUsageMetricsStatus,
);

export const selectedDateRangeSelector = createSelector(
  [getSelectedDateRange],
  (selectedDateRange) => selectedDateRange,
);

export const pipelineUsageMetricsSortValueSelector = createSelector(
  [getPipelineUsageMetricsSortValue],
  (pipelineUsageMetricsSortValue) => pipelineUsageMetricsSortValue,
);

export const pipelineUsageMetricsFiltersValuesSelector = createSelector(
  [getPipelineUsageMetricsFiltersValues],
  (pipelineUsageMetricsFiltersValues) => pipelineUsageMetricsFiltersValues,
);
