import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { getPipelineYamlApi } from '@api/pipeline';
import { RootState } from '@redux/store';
import { StudioYamlTabsKeys } from '../constants/pipeline-studio';

interface StudioState {
  activeTab: StudioYamlTabsKeys;
  isYamlView: boolean;
  indexingYaml: string;
  queryYaml: string;
}

interface FetchPipelineYAMLResponse {
  indexing_yaml: string;
  query_yaml: string;
}

// TODO: Remove this, it is here just for demonstration purposes
const fetchPipelineYAML = createAsyncThunk(
  'users/fetchPipelineYAML',
  async (pipelineName: string) => {
    const response = await getPipelineYamlApi(pipelineName);
    console.log(response.data);
    return response.data as FetchPipelineYAMLResponse;
  },
);

const initialState: StudioState = {
  activeTab: StudioYamlTabsKeys.INDEXING_YAML,
  isYamlView: false,
  indexingYaml: '',
  queryYaml: '',
};

export const studioSlice = createSlice({
  name: 'studio',
  initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<StudioYamlTabsKeys>) => {
      state.activeTab = action.payload;
    },
    setIsYamlView: (state, action: PayloadAction<boolean>) => {
      state.isYamlView = action.payload;
    },
    setIndexingYaml: (state, action: PayloadAction<string>) => {
      state.indexingYaml = action.payload;
    },
    setQueryYaml: (state, action: PayloadAction<string>) => {
      state.queryYaml = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPipelineYAML.fulfilled, (state, action) => {
      // This is an example of how to use the fetched data
      const { indexing_yaml: indexingYaml, query_yaml: queryYaml } = action.payload;
      state.indexingYaml = indexingYaml;
      state.queryYaml = queryYaml;
    });
  },
});

export const { setActiveTab, setIsYamlView, setIndexingYaml, setQueryYaml } = studioSlice.actions;

export default studioSlice.reducer;

// SELECTORS

export const selectActiveTab = (state: RootState) => state.studioStore.activeTab;
export const selectIsYamlView = (state: RootState) => state.studioStore.isYamlView;
export const selectIndexingYaml = (state: RootState) => state.studioStore.indexingYaml;
export const selectQueryYaml = (state: RootState) => state.studioStore.queryYaml;
