import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Empty, Select } from 'antd';
import { useScreen } from '@hooks/useScreen';
import { PipelineDesiredStatusCodes } from '@constants/pipelines';
import {
  EMPTY_PIPELINE_SELECT_LABEL,
  EMPTY_PIPELINE_SELECT_MESAGGE,
  SELECT_PIPELINE_PLACEHOLDER,
  SELECT_PIPELINE_TITLE,
} from '@constants/search';
import { fetchPipelineIndexing, fetchPipelines } from '@redux/actions/pipelineActions';
import { setSearchPipeline } from '@redux/actions/searchActions';
import {
  pipelineIndexingDetailsSelector,
  pipelinesSelector,
} from '@redux/selectors/pipelineSelectors';
import { IPipeline, IPipelineIndexingData } from '@redux/types/types';
import EllipsisMiddle from '@components/common/ellipsisMiddle/EllipsisMiddle';
import PipelineDeepsetCloudVersionTag from '@components/pipelines/pipelineDeepsetCloudVersionTag/PipelineDeepsetCloudVersionTag';
import PipelineServiceLevelBadge from '@components/pipelines/pipelineServiceLevelBadge/PipelineServiceLevelBadge';
import PipelineStatusTag from '@components/pipelineStatusTag/PipelineStatusTag';
import styles from './searchPipelineSelect.module.scss';

const { Option } = Select;

interface ISearchPipelineSelectProps {
  selectedPipeline?: IPipeline;
  selectedPipelineName?: string;
  loading?: boolean;
}

const SearchPipelineSelect = ({
  selectedPipeline,
  selectedPipelineName,
  loading,
}: ISearchPipelineSelectProps) => {
  const dispatch = useDispatch();
  const { data: pipelines }: { data: IPipeline[] } = useSelector((state) =>
    pipelinesSelector(state, PipelineDesiredStatusCodes.DEPLOYED),
  );
  const pipelineIndexingData: IPipelineIndexingData = useSelector(pipelineIndexingDetailsSelector);
  const { isMobileScreen, isTabletScreen } = useScreen();
  const isSmallerScreen = isMobileScreen || isTabletScreen;
  const [pipelineOptions, setPipelineOptions] = useState<JSX.Element[]>([]);

  useEffect(() => {
    setPipelineOptions(
      pipelines.map(({ name, service_level: serviceLevel, deepset_cloud_version: version }) => (
        <Option key={name} value={name} data-testid={`pipelineOption_${name}`}>
          <div className={styles.pipelineOption}>
            <PipelineServiceLevelBadge serviceLevel={serviceLevel} />
            <EllipsisMiddle>{name}</EllipsisMiddle>
            <PipelineDeepsetCloudVersionTag deepsetCloudVersion={version} />
          </div>
        </Option>
      )),
    );
  }, [pipelines]);

  const fetchPipelineIndexingDetails = (pipelineName: string) => {
    dispatch(fetchPipelineIndexing(pipelineName));
  };

  const onPipelineSelect = (value: string) => {
    dispatch(setSearchPipeline(value));
  };

  // Empty message when there's no pipelines to select
  const emptyOption = (
    <Option className={styles.emptyPipeline}>
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={<span>{EMPTY_PIPELINE_SELECT_MESAGGE}</span>}
      />
    </Option>
  );

  return (
    <>
      <div className={styles.pipelineSelect}>
        <h6 className={styles.pipelineSelect_title}>{SELECT_PIPELINE_TITLE}</h6>
        {selectedPipeline && (
          <PipelineStatusTag
            pipeline={selectedPipeline}
            getIndexingDetails={fetchPipelineIndexingDetails}
            indexingData={pipelineIndexingData}
          />
        )}
      </div>
      <Select
        size={isSmallerScreen ? 'large' : 'middle'}
        showSearch
        placeholder={SELECT_PIPELINE_PLACEHOLDER}
        onChange={onPipelineSelect}
        value={selectedPipelineName || ''}
        disabled={loading}
        notFoundContent={EMPTY_PIPELINE_SELECT_LABEL}
        data-testid="pipelineSelector"
        popupMatchSelectWidth={false}
        onFocus={() =>
          dispatch(
            fetchPipelines({
              currentPage: 1,
              pageSize: 100,
              searchValue: '',
              desiredStatus: PipelineDesiredStatusCodes.DEPLOYED,
            }),
          )
        }
      >
        {pipelineOptions.length > 0 ? pipelineOptions : emptyOption}
      </Select>
    </>
  );
};

export default SearchPipelineSelect;
