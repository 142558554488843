/* eslint-disable react/prop-types */
import React, { CSSProperties } from 'react';
import Markdown, { Components } from 'react-markdown';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Tooltip, Typography } from 'antd';
import { CodeProps } from 'react-markdown/lib/ast-to-react';
import { coy } from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkGfm from 'remark-gfm';
import { parseTableDataToCsvString } from '@utils/data';
import { downloadBlobFile } from '@utils/file';
import useExtractDataFromNodes, { ExtractorType, TableData } from '@hooks/useExtractDataFromNodes';
import { DOWNLOAD_TABLE_BUTTON_LABEL, TABLE_LABEL } from '@constants/common';
import styles from './markdownViewer.module.scss';

const { Title } = Typography;

interface IMarkdownViewerProps {
  children: string;
  customComponents?: Components;
  customCodeStyle?: Record<string, CSSProperties>;
}

export const LinkRender = ({
  href,
  children,
}: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>) => {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

export const CodeRender = ({
  className,
  children,
  customStyle = {},
  ...rest
}: CodeProps & { customStyle: { [key: string]: CSSProperties } }) => {
  const [, language] = /language-(\w+)/.exec(className || '') || [];

  if (!language)
    return (
      <code {...rest} className={className}>
        {children}
      </code>
    );

  const style = {
    ...coy,
    ...customStyle,
  };

  return (
    <SyntaxHighlighter style={style} language={language} PreTag="div">
      {children as string}
    </SyntaxHighlighter>
  );
};

export const TableRender = ({ children, ...props }: React.HTMLProps<HTMLTableElement>) => {
  const { headers, rows } = useExtractDataFromNodes(children, ExtractorType.TABLE) as TableData;

  const generateTableTitle = () => {
    if (!headers.length) return TABLE_LABEL;
    return headers.slice(0, 3).join(', ');
  };

  const handleDownloadAsCsv = () => {
    const tableTitle = generateTableTitle();
    const fileName = `${tableTitle.toLowerCase().replace(/[^a-z0-9]/g, '_')}.csv`;

    const csvData = parseTableDataToCsvString(
      headers.map((header) => ({ dataIndex: header, key: header, title: header })),
      rows,
    );
    downloadBlobFile(fileName, csvData);
  };

  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableHeader}>
        <Title level={5}>{generateTableTitle()}</Title>
        <Tooltip title={DOWNLOAD_TABLE_BUTTON_LABEL} placement="top">
          <Button
            type="text"
            icon={<DownloadOutlined />}
            onClick={handleDownloadAsCsv}
            size="small"
          />
        </Tooltip>
      </div>
      <table {...props}>{children}</table>
    </div>
  );
};

const MarkdownViewer = ({
  children,
  customComponents = {},
  customCodeStyle = {},
}: IMarkdownViewerProps) => {
  return (
    <Markdown
      remarkPlugins={[remarkGfm]}
      components={{
        a: LinkRender,
        code: (props) => <CodeRender {...props} customStyle={customCodeStyle} />,
        table: TableRender,
        ...customComponents,
      }}
      className={styles.container}
    >
      {children}
    </Markdown>
  );
};

export default MarkdownViewer;
