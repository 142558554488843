import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import NotFoundErrorSVG from '@assets/not-found-error.svg?react';
import {
  LOGOUT_BUTTON_LABEL,
  NOT_FOUND_WORKSPACE_ACCESS_ERROR_DESCRIPTION,
  NOT_FOUND_WORKSPACE_ACCESS_ERROR_LABEL,
} from '@constants/common';
import styles from './notWorkspaceAccessPage.module.scss';

const NotFoundWorkspaceAccessPage = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.container_wrapper}>
        <NotFoundErrorSVG />
        <h3 className={styles.container_wrapper_title}>{NOT_FOUND_WORKSPACE_ACCESS_ERROR_LABEL}</h3>
        <div className={styles.container_wrapper_subtitle}>
          {NOT_FOUND_WORKSPACE_ACCESS_ERROR_DESCRIPTION}
        </div>
        <Button onClick={() => navigate('/logout')}>{LOGOUT_BUTTON_LABEL}</Button>
      </div>
    </div>
  );
};

export default NotFoundWorkspaceAccessPage;
