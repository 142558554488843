import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterOutlined } from '@ant-design/icons';
import { Badge, Button } from 'antd';
import { isEmpty } from 'lodash';
import usePipelineFeedback from '@hooks/usePipelineFeedback';
import { useScreen } from '@hooks/useScreen';
import { StatusCodes } from '@constants/enum/common';
import { PipelineStatusCodes } from '@constants/pipelines';
import { APPLY_AND_SEARCH_BUTTON, SEARCH_BUTTON } from '@constants/search';
import { setAppliedMetaDataFilterValues } from '@redux/actions/metadataFiltersActions';
import {
  appliedMetaFilterValuesSelector,
  selectedMetaFilterValuesSelector,
} from '@redux/selectors/metadataFiltersSelectors';
import { IMessage, IPipeline, ISearchResult, MetadataFiltersType } from '@redux/types/types';
import SearchCardLayout from '@components/search/layouts/searchCard/SearchCardLayout';
import SearchQueryInput from '@components/search/molecules/searchQueryInput/SearchQueryInput';
import SearchResultsContainer from '@components/search/organisms/results/SearchResultsContainer';
import useActivatePipeline from '@pages/search/hooks/useActivatePipeline';
import styles from './search.module.scss';
import SearchNoResultsContainer from '../results/SearchNoResultsContainer';
import SearchMetadataFilters from '../searchMetadataFilters/SearchMetadataFilters';

interface ISearchComponentProps {
  isExternal?: boolean;
  selectedPipeline?: IPipeline;
  selectedPipelineName?: string;
  handleSearch: (query: string, metaFilters: Record<string, MetadataFiltersType>) => void;
  searchResult: ISearchResult | null;
  searchStatus: StatusCodes;
  displayMetaDataFilters: boolean;
  displayResultsFileOptions: boolean;
  displayResultsMoreOptions: boolean;
  searchFiltersVisible?: boolean;
  onSearchFiltersClose?: () => void;
  onToggleFiltersClick?: () => void;
  message?: IMessage;
}

const SearchComponent = (props: ISearchComponentProps) => {
  const {
    isExternal,
    selectedPipeline,
    selectedPipelineName,
    handleSearch,
    searchResult,
    searchStatus,
    displayMetaDataFilters,
    displayResultsFileOptions,
    displayResultsMoreOptions,
    searchFiltersVisible = true,
    onSearchFiltersClose,
    onToggleFiltersClick,
    message,
  } = props;
  const dispatch = useDispatch();
  const { getFeedbackTags, resetFeedbackTags } = usePipelineFeedback();
  const { activatePipeline, activatingPipeline } = useActivatePipeline({
    isExternal,
    selectedPipelineName,
    allwaysPollSelectedPipeline: true,
  });
  const { isMobileScreen, isTabletScreen } = useScreen();
  const isSmallerScreen = isMobileScreen || isTabletScreen;

  const selectedMetaFilterValues = useSelector(selectedMetaFilterValuesSelector);
  const appliedMetaFilterValues = useSelector(appliedMetaFilterValuesSelector);
  // TODO: Check if we can remove this state
  const [cachedQuery, setCachedQuery] = useState('');

  const searchInputQuery = useRef('');

  const { answers = [], documents = [] } = searchResult || {};
  const isEmptyResults = !answers.length && !documents.length;
  const isSearching = searchStatus === StatusCodes.IN_PROGRESS;

  useEffect(() => {
    if (selectedPipelineName) {
      setCachedQuery('');
    }
    resetFeedbackTags();
  }, [selectedPipelineName]);

  useEffect(() => {
    if (!selectedPipeline?.pipeline_id) return;

    getFeedbackTags({ pipelineId: selectedPipeline.pipeline_id, isExternal });
  }, [selectedPipeline?.pipeline_id]);

  const onClickSearch = async (query: string) => {
    searchInputQuery.current = query;
    if (selectedPipeline?.status === PipelineStatusCodes.IDLE) {
      activatePipeline(selectedPipeline.name);
    } else {
      handleSearch(query, selectedMetaFilterValues);
      dispatch(setAppliedMetaDataFilterValues(selectedMetaFilterValues));
    }
  };

  useEffect(() => {
    if (selectedPipeline?.status === PipelineStatusCodes.DEPLOYED && cachedQuery !== '') {
      onClickSearch(cachedQuery);
      setCachedQuery('');
    }
  }, [selectedPipeline?.status]);

  useEffect(() => {
    setCachedQuery(searchInputQuery.current);
  }, [searchInputQuery.current]);

  const onClickApplyFilters = () => {
    const query = searchInputQuery.current;
    handleSearch(query, selectedMetaFilterValues);
  };

  // Renders

  const renderSearchBarExtraButton = () => {
    if (
      isEmptyResults &&
      !isSearching &&
      isEmpty(appliedMetaFilterValues) &&
      !isEmpty(selectedMetaFilterValues)
    )
      return APPLY_AND_SEARCH_BUTTON;

    return SEARCH_BUTTON;
  };

  return (
    <>
      <SearchCardLayout
        sideMenu={
          searchFiltersVisible && (
            <SearchMetadataFilters
              selectedPipeline={selectedPipeline}
              pipelineName={selectedPipelineName!}
              displayMetaDataFilters={displayMetaDataFilters}
              isExternal={isExternal}
              isEmptyResults={isEmptyResults}
              isLoading={isSearching}
              searchStatus={searchStatus}
              onClickApplyFilters={onClickApplyFilters}
              onSearchFiltersClose={onSearchFiltersClose}
            />
          )
        }
        searchBar={
          <>
            {isSmallerScreen && (
              <div className={styles.searchBar_filtersButtonWrapper}>
                <Badge
                  dot={!isEmpty(selectedMetaFilterValues) || !isEmpty(appliedMetaFilterValues)}
                >
                  <Button size="large" icon={<FilterOutlined />} onClick={onToggleFiltersClick} />
                </Badge>
              </div>
            )}
            <SearchQueryInput
              onQuerySubmit={onClickSearch}
              loading={isSearching}
              disabled={!selectedPipelineName || isSearching || activatingPipeline}
              enterButton={renderSearchBarExtraButton()}
            />
          </>
        }
        body={
          !isEmptyResults ? (
            <SearchResultsContainer
              isExternal={isExternal}
              results={searchResult!}
              status={searchStatus}
              query={searchInputQuery.current}
              pipelineName={selectedPipelineName || ''}
              pipelineId={selectedPipeline?.pipeline_id || ''}
              displayFileOptions={displayResultsFileOptions}
              displayMoreOptions={displayResultsMoreOptions}
            />
          ) : (
            <SearchNoResultsContainer
              selectedPipeline={selectedPipeline}
              status={searchStatus}
              query={searchInputQuery.current}
              isExternal={isExternal}
              message={message}
            />
          )
        }
      />
    </>
  );
};

export default SearchComponent;
