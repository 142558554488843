import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Divider, Drawer } from 'antd';
import { isEqual } from 'lodash';
import { useScreen } from '@hooks/useScreen';
import { StatusCodes } from '@constants/enum/common';
import {
  APPLY_FILTERS_BUTTON,
  CANCEL_FILTERS_BUTTON,
  CLEAR_FILTERS_BUTTON,
  SEARCH_FILTERS_TITLE,
} from '@constants/search';
import {
  selectMetaDataFilterValues,
  setAppliedMetaDataFilterValues,
} from '@redux/actions/metadataFiltersActions';
import {
  appliedMetaFilterValuesSelector,
  selectedMetaFilterValuesSelector,
} from '@redux/selectors/metadataFiltersSelectors';
import { IPipeline } from '@redux/types/types';
import MetadataFilters from '@components/metadataFilters/MetadataFilters';
import DebugModal from '@components/search/features/DebugModal/DebugModal';
import styles from './searchMetadataFilters.module.scss';
import SearchPipelineSelect from '../searchPipelineSelect/SearchPipelineSelect';

interface ISearchMetadataFiltersProps {
  selectedPipeline?: IPipeline;
  pipelineName: string;
  displayMetaDataFilters: boolean;
  isExternal?: boolean;
  isEmptyResults: boolean;
  isLoading: boolean;
  onClickApplyFilters?: () => void;
  onSearchFiltersClose?: () => void;
  searchStatus: StatusCodes;
}

const SearchMetadataFilters = ({
  selectedPipeline,
  pipelineName,
  displayMetaDataFilters,
  isExternal = false,
  isEmptyResults,
  isLoading,
  onClickApplyFilters,
  onSearchFiltersClose,
  searchStatus,
}: ISearchMetadataFiltersProps) => {
  const dispatch = useDispatch();
  const { isMobileScreen, isTabletScreen } = useScreen();
  const isSmallerScreen = isMobileScreen || isTabletScreen;
  const selectedMetaFilterValues = useSelector(selectedMetaFilterValuesSelector);
  const appliedMetaFilterValues = useSelector(appliedMetaFilterValuesSelector);

  const onClearAllFilters = () => {
    dispatch(selectMetaDataFilterValues({}));
    dispatch(setAppliedMetaDataFilterValues({}));
  };

  const onClickCancelFilters = () => {
    dispatch(selectMetaDataFilterValues(appliedMetaFilterValues));
  };

  const onApplyFilters = () => {
    if (onClickApplyFilters) onClickApplyFilters();
    dispatch(setAppliedMetaDataFilterValues(selectedMetaFilterValues));

    if (onSearchFiltersClose && isMobileScreen) onSearchFiltersClose();
  };

  // Renders

  const renderSearchFiltersHeader = () => (
    <header className={styles.searchFilters_header}>
      <div className={styles.searchFilters_header_title}>
        <h5>{SEARCH_FILTERS_TITLE}</h5>
        {Object.keys(selectedMetaFilterValues).length > 0 && (
          <Button size="small" onClick={onClearAllFilters}>
            {CLEAR_FILTERS_BUTTON}
          </Button>
        )}
      </div>
    </header>
  );

  const renderSearchFiltersBody = () => (
    <div className={styles.searchFilters_content}>
      {!isExternal && (
        <SearchPipelineSelect
          selectedPipeline={selectedPipeline}
          selectedPipelineName={pipelineName}
          loading={isLoading}
        />
      )}
      {displayMetaDataFilters && (
        <MetadataFilters
          isExternal={isExternal}
          pipelineName={pipelineName!}
          requestWithoutErrors={searchStatus === StatusCodes.SUCCESS}
        />
      )}
    </div>
  );

  const renderSearchFiltersFooter = () => {
    if (isEmptyResults || isEqual(selectedMetaFilterValues, appliedMetaFilterValues)) return null;
    return (
      <footer className={styles.searchFilters_footer}>
        <Divider className={styles.searchFilters_footer_divider} />
        <div className={styles.searchFilters_footer_options}>
          <Button
            onClick={onClickCancelFilters}
            className={styles.searchFilters_cancelButton}
            size={isSmallerScreen ? 'large' : 'middle'}
            block={isSmallerScreen}
          >
            {CANCEL_FILTERS_BUTTON}
          </Button>
          <Button
            type="primary"
            onClick={onApplyFilters}
            size={isSmallerScreen ? 'large' : 'middle'}
            block={isSmallerScreen}
          >
            {APPLY_FILTERS_BUTTON}
          </Button>
        </div>
      </footer>
    );
  };

  const renderSearchFiltersDrawer = () => {
    return (
      <Drawer
        rootClassName={styles.searchFiltersDrawer}
        onClose={onSearchFiltersClose}
        closable={false}
        placement="left"
        open
        title={renderSearchFiltersHeader()}
        extra={
          <Button
            className={styles.searchFiltersDrawer_closeButton}
            icon={<CloseOutlined />}
            onClick={onSearchFiltersClose}
          />
        }
        footer={renderSearchFiltersFooter()}
      >
        {renderSearchFiltersBody()}
      </Drawer>
    );
  };

  if (isSmallerScreen) return renderSearchFiltersDrawer();

  return (
    <>
      <DebugModal />
      {renderSearchFiltersHeader()}
      {renderSearchFiltersBody()}
      {renderSearchFiltersFooter()}
    </>
  );
};

export default SearchMetadataFilters;
