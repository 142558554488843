import produce from 'immer';
import {
  FETCH_USER_INFO,
  FULFILLED,
  IUserData,
  OrganizationType,
  PENDING,
  REJECTED,
  RESET_USER_INFO,
  SET_USER_INFO,
  UPDATE_USER_INFO,
  USER_ASSIGN_WORKSPACE_ROLE,
  USER_REMOVE_USER_FROM_WORKSPACE,
} from '@redux/types/types';
import { StatusCodes, UserRoles } from '@src/constants/enum/common';

interface IInitialStateProps {
  user: IUserData;
  loginError: boolean;
  assignUserToWorkspaceStatus: StatusCodes;
  removeUserFromWorkspaceStatus: { [userId: string]: StatusCodes };
}

export const initialState: IInitialStateProps = {
  user: {
    firstName: '',
    lastName: '',
    fullName: '',
    email: '',
    userID: '',
    organization: '',
    role: UserRoles.SEARCH_USER,
    isAdmin: false,
    isEmailVerified: false,
    workspaces: [],
    userflowSignature: '',
    isLimitedUser: true,
    organizationType: OrganizationType.STANDARD,
  },
  loginError: false,
  assignUserToWorkspaceStatus: StatusCodes.IDLE,
  removeUserFromWorkspaceStatus: {},
};

function userReducer(state = initialState, action: any) {
  return produce(state, (draft) => {
    const localDraft = draft;
    switch (action.type) {
      case SET_USER_INFO:
        localDraft.user = action.payload;
        break;

      case UPDATE_USER_INFO: {
        const currentUserState: any = state.user;
        localDraft.user = { ...currentUserState, ...action.payload };
        break;
      }

      case RESET_USER_INFO:
        localDraft.user = initialState.user;
        break;

      case `${FETCH_USER_INFO}/${REJECTED}`:
        localDraft.loginError = true;
        break;

      case `${FETCH_USER_INFO}/${FULFILLED}`:
        localDraft.loginError = false;
        localDraft.user = action.payload;
        break;

      case `${USER_ASSIGN_WORKSPACE_ROLE}/${PENDING}`:
        localDraft.assignUserToWorkspaceStatus = StatusCodes.IN_PROGRESS;
        break;
      case `${USER_ASSIGN_WORKSPACE_ROLE}/${FULFILLED}`:
        localDraft.assignUserToWorkspaceStatus = StatusCodes.SUCCESS;
        break;
      case `${USER_ASSIGN_WORKSPACE_ROLE}/${REJECTED}`:
        localDraft.assignUserToWorkspaceStatus = StatusCodes.ERROR;
        break;

      case `${USER_REMOVE_USER_FROM_WORKSPACE}/${PENDING}`: {
        const { userId } = action.meta.arg;
        localDraft.removeUserFromWorkspaceStatus[userId] = StatusCodes.IN_PROGRESS;
        break;
      }
      case `${USER_REMOVE_USER_FROM_WORKSPACE}/${FULFILLED}`:
        localDraft.removeUserFromWorkspaceStatus[action.payload] = StatusCodes.SUCCESS;
        break;
      case `${USER_REMOVE_USER_FROM_WORKSPACE}/${REJECTED}`: {
        const { userId } = action.meta.arg;
        localDraft.removeUserFromWorkspaceStatus[userId] = StatusCodes.ERROR;
        break;
      }

      default:
        break;
    }
  });
}

export default userReducer;
