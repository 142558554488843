import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ThunderboltFilled } from '@ant-design/icons';
import { Alert, AlertProps } from 'antd';

import styles from './upgradeBanner.module.scss';
import { LimitCategory, UPGRADE_BANNER_MESSAGE } from '../../consts/pricing';
import useFreeLimits from '../../hooks/useFreeLimits';

interface UpgradeBannerProps extends AlertProps {
  label?: string;
  category?: LimitCategory;
}

const UpgradeBanner: React.FC<UpgradeBannerProps> = (props) => {
  const { category, label } = props;
  const navigate = useNavigate();
  const { isLimitExceed, isLimitedUser } = useFreeLimits();

  if (category && (!isLimitedUser || !isLimitExceed(category))) {
    return null;
  }

  if (!category && !label) return null;

  return (
    <Alert
      message={label ?? (category ? UPGRADE_BANNER_MESSAGE[category] : '')}
      banner
      closable
      icon={<ThunderboltFilled />}
      onClick={() => navigate('/upgrade')}
      className={styles.alert}
      {...props}
    />
  );
};

export default UpgradeBanner;
