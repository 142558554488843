import govtechCampusFaviconPNG from '@assets/whiteLabel/GovTechCampus/favicon.png';
import govtechCampusLogoSVG from '@assets/whiteLabel/GovTechCampus/logo.svg';

interface IWhiteLabelAssets {
  logo: string;
  favicon: string;
  title: string;
}

interface WhiteLabelConfig {
  [orgId: string]: IWhiteLabelAssets;
}

const GOVTECH_CAMPUS_ORG_ID = 'cae577d4-04cc-462e-ae8f-a67d5e836b45';

export const WHITE_LABEL_ORGS: WhiteLabelConfig = {
  [GOVTECH_CAMPUS_ORG_ID]: {
    logo: govtechCampusLogoSVG,
    favicon: govtechCampusFaviconPNG,
    title: 'GovTechCampus',
  },
};
