import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { filesSelector } from '@redux/selectors/fileSelectors';
import { workspaceSelector } from '@redux/selectors/organizationSelectors';
import { userSelector } from '@redux/selectors/userSelectors';
import useMeteringUsage from '@pages/metering/hooks/useMeteringUsage';
import { FREE_TIER_LIMITS, LimitCategory } from '../consts/pricing';

const useFreeLimits = () => {
  const { total: totalFiles } = useSelector(filesSelector);
  const { allTimeUsageMetrics, fetchAllTimeUsageMetrics, totalPipelines } = useMeteringUsage();
  const { development_hours: totalHours } = allTimeUsageMetrics;
  const { workspaces } = useSelector(workspaceSelector);
  const { isLimitedUser } = useSelector(userSelector);

  useEffect(() => {
    if (isEmpty(allTimeUsageMetrics)) fetchAllTimeUsageMetrics();
  }, []);

  const isLimitExceed = (limitCategory: LimitCategory) => {
    switch (limitCategory) {
      case LimitCategory.WORKSPACES:
        return workspaces.length >= FREE_TIER_LIMITS.WORKSPACES;
      case LimitCategory.PIPELINE_HOURS:
        return totalHours ? totalHours >= FREE_TIER_LIMITS.PIPELINE_HOURS : false;
      case LimitCategory.FILES:
        return totalFiles >= FREE_TIER_LIMITS.FILES;
      case LimitCategory.PIPELINES:
        return totalPipelines >= FREE_TIER_LIMITS.PIPELINES;
      default:
        return false;
    }
  };

  return { isLimitExceed, isLimitedUser, totalFiles };
};

export default useFreeLimits;
