import { addNotification } from '@redux/actions/notificationActions';
import { NotificationType } from '@redux/types/types';

export const addSuccessNotification = ({ dispatch, label }: { dispatch: any; label: string }) => {
  dispatch(
    addNotification({
      content: label,
      type: NotificationType.Success,
    }),
  );
};

export const addInfoNotification = ({ dispatch, label }: { dispatch: any; label: string }) => {
  dispatch(
    addNotification({
      content: label,
      type: NotificationType.Info,
    }),
  );
};
