/* eslint-disable react/no-danger */
/* eslint-disable react/display-name */
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import UFuzzy, { RankedResult } from '@leeoniya/ufuzzy';
import DOMPurify from 'dompurify';
import { v4 as uuidv4 } from 'uuid';
import { ANSWER_LABEL } from '@constants/common';
import { IHighlightData } from '@redux/types/types';
import TextHighlight from '@components/textHighlight/TextHighlight';
import styles from './highlightContent.module.scss';

const findTextIndexes = (
  content: string,
  searchText: string,
): { preContext: string; higlightedContext: string; postContext: string } | null => {
  const indexOfContext = content.indexOf(searchText);
  if (indexOfContext === -1) return null;

  const preContext = content.slice(0, indexOfContext);
  const higlightedContext = content.slice(indexOfContext, indexOfContext + searchText.length);
  const postContext = content.slice(indexOfContext + searchText.length);
  return { preContext, higlightedContext, postContext };
};

const findStartIndex = (results: RankedResult[]): number => {
  const firstLine = results[0];
  if (firstLine[0]?.length === 1) return firstLine[0][0];

  for (let i = 1; i < results.length; i++) {
    if (results[i][0]?.length === 1) return results[i][0][0] - i;
  }

  return firstLine[0][firstLine[2][0]];
};

const findEndIndex = (results: RankedResult[]): number => {
  const finishLine = results[results.length - 1] || [];
  if (!finishLine || finishLine[0].length === 0) return 0;
  if (finishLine[0]?.length === 1) return finishLine[0][0];

  for (let i = results.length - 2; i >= 0; i--) {
    if (results[i][0]?.length === 1) return results[i][0][0] + i;
  }

  return finishLine[0][finishLine[2][0]];
};

const fuzzySearchContext = (
  content: string,
  searchText: string,
  splitByLine = true,
): { preContext: string; higlightedContext: string; postContext: string } | null => {
  const searchLines = searchText.split('\n').filter(Boolean);
  const paragraphs = content.split('\n').map((text) => text);

  const opts = {
    interIns: 5,
  };
  const uf = new UFuzzy(opts);
  const ufResults = searchLines.map((searchLine: string) =>
    uf.search(paragraphs, searchLine, 0, 1e3),
  );

  const startIndex = findStartIndex(ufResults as RankedResult[]);
  const endIndex = findEndIndex(ufResults as RankedResult[]);

  if (!splitByLine) {
    const foundParagraphs = ufResults;
    const firstResult = foundParagraphs[0];
    const lastResult = foundParagraphs[foundParagraphs.length - 1];

    const startIndexes = firstResult[0];
    const startInfo = firstResult[1];
    if (!startIndexes || !startInfo?.ranges) return null;
    const startUfResultIndex = startIndexes.indexOf(startIndex);
    const startRanges = startInfo.ranges[startUfResultIndex] || [];
    const startOffset = startRanges[0];

    const endIndexes = lastResult[0];
    const endInfo = lastResult[1];
    if (!endIndexes || !endInfo?.ranges) return null;
    const endUfResultIndex = endIndexes.indexOf(endIndex);
    const endRanges = endInfo.ranges[endUfResultIndex];
    const endOffset = endRanges[endRanges.length - 1];

    const preAnswer = `${paragraphs.slice(0, startIndex).join('\n')}
${paragraphs[startIndex].slice(0, startOffset)}`;
    const postAnswer = `${paragraphs[endIndex].slice(endOffset)}
${paragraphs.slice(endIndex + 1).join('\n')}`;

    let answer = '';
    if (foundParagraphs.length === 1)
      answer = `${paragraphs[startIndex].slice(startOffset, endOffset)}`;
    if (foundParagraphs.length === 2)
      answer = `${paragraphs[startIndex].slice(startOffset)}\n${paragraphs[endIndex].slice(
        0,
        endOffset,
      )}`;
    if (foundParagraphs.length > 2)
      answer = `${paragraphs[startIndex].slice(startOffset)}
${paragraphs.slice(startIndex + 1, endIndex).join('\n')}
${paragraphs[endIndex].slice(0, endOffset)}`;
    return { preContext: preAnswer, higlightedContext: answer, postContext: postAnswer };
  }

  const preContext = paragraphs.slice(0, startIndex).join('\n');
  const higlightedContext = paragraphs.slice(startIndex, endIndex + 1).join('\n');
  const postContext = paragraphs.slice(endIndex + 1).join('\n');

  return { preContext, higlightedContext, postContext };
};

interface IHighlightContentProps {
  content: string;
  highlightData: IHighlightData[];
}

interface IHighlightContentMethods {
  scrollToHighlight: () => void;
}

// TODO: Separate into File and Document highlighting
const HighlightContent = forwardRef<IHighlightContentMethods, IHighlightContentProps>(
  ({ content, highlightData }: IHighlightContentProps, ref) => {
    const highlightRefs = useRef<HTMLSpanElement[]>([]);
    const currentHighlightIndex = useRef<number>(0);

    const scrollToHighlight = (scrollIndex = currentHighlightIndex.current) => {
      const highlightEl = highlightRefs.current[scrollIndex];
      highlightEl?.scrollIntoView({ behavior: 'smooth' });
      currentHighlightIndex.current =
        highlightRefs.current.length <= scrollIndex + 1 ? 0 : scrollIndex + 1;
    };

    useImperativeHandle(
      ref,
      () => ({
        scrollToHighlight,
      }),
      [],
    );

    useEffect(() => {
      scrollToHighlight();
    }, [highlightRefs.current]);

    const generateContentWithOffsetsHighlight = () => {
      const highlight = highlightData[0];
      const { answer, label, offsetsInDocument } = highlight;

      if (!offsetsInDocument?.length) return null;
      const [{ start, end }] = offsetsInDocument;

      return (
        <TextHighlight
          offsetStart={start}
          offsetEnd={end}
          text={content}
          highlight={answer}
          label={label || ANSWER_LABEL}
        />
      );
    };

    const generateContentWithHighlights = () => {
      let lastIndex = 0;
      const elements = [];

      highlightData.forEach((highlight, index) => {
        const { context, answer, label } = highlight;

        if (!context) return;

        const foundContext =
          findTextIndexes(content, context) || fuzzySearchContext(content, context);

        if (!foundContext?.higlightedContext) return;

        const uniqueKey = uuidv4();
        const { preContext, higlightedContext } = foundContext;

        const startContextIndex = content.indexOf(preContext, lastIndex) + preContext.length;
        if (startContextIndex > lastIndex) {
          elements.push(
            <span key={`pre-${uniqueKey}`}>{content.slice(lastIndex, startContextIndex)}</span>,
          );
        }

        let segmentToHighlight = higlightedContext;
        if (answer) {
          const foundAnswer =
            findTextIndexes(higlightedContext, answer) ||
            fuzzySearchContext(higlightedContext, answer);

          if (foundAnswer?.higlightedContext) {
            const {
              preContext: answerPreContext,
              higlightedContext: answerText,
              postContext: answerPostContext,
            } = foundAnswer;
            segmentToHighlight = `${answerPreContext}<span class="${
              styles.highlightedAnswer
            }">${answerText}<span>${label || ANSWER_LABEL}</span></span>${answerPostContext}`;
          }
        }

        elements.push(
          <span
            key={`highlight-${uniqueKey}`}
            ref={(el) => {
              if (el) highlightRefs.current[index] = el;
            }}
            className={styles.highlightedContext}
          >
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(segmentToHighlight) }} />
          </span>,
        );

        lastIndex = startContextIndex + higlightedContext.length;
      });

      if (lastIndex < content.length) {
        elements.push(<span key="remaining-content">{content.slice(lastIndex)}</span>);
      }

      return elements;
    };

    if (highlightData.length === 1 && !!highlightData[0].offsetsInDocument?.length) {
      return (
        <div className={styles.content}>
          <pre>{generateContentWithOffsetsHighlight()}</pre>
        </div>
      );
    }

    return (
      <div className={styles.content}>
        <pre>{generateContentWithHighlights()}</pre>
      </div>
    );
  },
);

export default HighlightContent;
